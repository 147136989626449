// 3rd-party modules
import { Link, useLocation } from 'react-router-dom';

// models
import { TopNavigationItem } from '../../models/types/navigation';

// defines
type Props = {
  navItems: TopNavigationItem[],
  onNavItemSelect?: (navItem: TopNavigationItem) => void;
};

export default function FooterNavigation({ navItems, onNavItemSelect }: Props) {
  const location = useLocation();

  const onNavItemClick = (item: TopNavigationItem) => {
    const currentPath = `/${location.pathname.substring(1)}`;

    if (onNavItemSelect && currentPath !== item.link) {
      onNavItemSelect(item)
    }
  };

  return (
    <>
      {navItems.map(navItem =>
        <Link to={navItem.link!} key={navItem.text} onClick={() => onNavItemClick(navItem)} className="link">
          <text>{navItem.text}</text>
        </Link>
      )}
    </>
  );
}
