// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToInstance } from 'class-transformer';

export class AccountLocation {
  @Expose()
  public accountLocationId?: number;
  @Expose()
  public accountId?: number;
  @Expose()
  public billingAddress?: boolean;
  /*
  @Expose()
  public locationName?: string;
  */
  @Expose()
  public addressLine1?: string;
  @Expose()
  public addressLine2?: string;
  @Expose()
  public addressLine3?: string;
  @Expose()
  public city?: string;
  @Expose()
  public stateProvince?: string;
  @Expose()
  public zipPostal?: string;
  @Expose()
  public country?: string;
  @Expose()
  public latitude?: string;
  @Expose()
  public longitude?: string;
  @Expose()
  public notes?: string;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(AccountLocation, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(AccountLocation, camelcaseKeys(data, { deep: true }) as AccountLocation[], { excludeExtraneousValues: true });
  }
}
