// project modules
import { getBaseUrl, sendRequest } from '../helpers/apiHelper';

// models
import { DataSourceRequest } from '../models/dataSourceRequest';

export const getAccountBillings = async (accountId: number, request?: DataSourceRequest, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountInvoice/account/${accountId}/List`, 'post', request, null, abortSignal);
};

export const getBillings = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountInvoice/list`, 'post', request, null, abortSignal);
};
