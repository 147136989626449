// 3rd-party modules
import { RefObject, useEffect } from 'react';

function useIntersectionObserver<T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    handler?: Function | false,
    rootMargin?: string
): void {
    useEffect(() => {
        if (handler) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    handler(entry.intersectionRatio);
                },
                {
                    root: null,
                    threshold: [1],
                    rootMargin
                }
            );

            if (ref.current) {
                observer.observe(ref.current);
            }

            return () => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            };
        }
    }, [handler, rootMargin]);
}

export default useIntersectionObserver;
