// project modules
import { getBaseUrl, sendRequest } from '../helpers/apiHelper';

// models
import { AccountLocation } from '../models/accountLocation';
import { DataSourceRequest } from '../models/dataSourceRequest';
import { accountLocationInsertViewModel, accountLocationUpdateViewModel } from '../models/types/accountLocation';

export const getAccountLocation = async (locationId: number, request?: DataSourceRequest, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountLocation/${locationId}`, 'post', request, null, abortSignal);
};

export const getAccountLocations = async (accountId: number, request?: DataSourceRequest, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountLocation/account/${accountId}/List`, 'post', request, null, abortSignal);
};

export const insertAccountLocation = async (accountLocation: accountLocationInsertViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountLocation`, 'post', accountLocation, null, abortSignal);
};

export const updateAccountLocation = async (accountLocation: accountLocationUpdateViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountLocation`, 'put', accountLocation, null, abortSignal);
};

export const deleteAccountLocation = async (accountLocation: AccountLocation, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountLocation`, 'delete', accountLocation, null, abortSignal);
};

export const setBillingAccountLocation = async (accountLocation: AccountLocation, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountLocation/account/${accountLocation.accountId}/setBillingAccountLocation`, 'post', accountLocation.accountLocationId, null, abortSignal);
};

