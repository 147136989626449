import classNames from 'classnames';
import React, { forwardRef, PropsWithChildren } from 'react';

interface OptionsProps {
  autosize?: boolean;
  bar?: boolean;
  compact?: boolean;
  fixed?: boolean;
  dynamic?: boolean;
  animate?: boolean;
  className?: string;
};

// @ts-ignore
const Options: React.FC<PropsWithChildren<OptionsProps>> =
  forwardRef<HTMLDivElement, PropsWithChildren<OptionsProps>>(
    (
      {
        autosize = false,
        bar = false,
        compact = false,
        className = '',
        fixed = false,
        dynamic = false,
        animate = false,
        children,
        ...rest
      },
      ref
    ) => {

      return (
        <div
          ref={ref}
          className={classNames(
            `${bar ? 'option_bar' : 'options'}`,
            {
              autosize,
              compact,
              fixed,
              dynamic,
              animate
            },
            className
          )}


          {...rest}
        >
          {children}
        </div>
      );
    }
  );

export default Options;
