// 3rd-party modules
import { Tooltip, message } from "antd";
import { useEffect, useState } from "react";

// project modules
import { apiCall } from "../../../../../helpers/apiHelper";
import KiboshDevicePortForwardingModal from "./KiboshDevicePortForwardingModal";
import Button from "../../../../shared/button";
import ConfirmationPopup from "../../../../shared/popup/confirmationPopup";
import Loader from "../../../../shared/loader";

// apis
import * as DashApi from '../../../../../apis/dashApi';

// models
import { Account } from "../../../../../models/account";
import { KiboshDevicePortForwarding } from "../../../../../models/kiboshDevicePortForwarding";
import { AccountKiboshDevice } from "../../../../../models/accountKiboshDevice";
import { FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../../../models/dataSourceRequest";
import DataListView from "../../../../shared/list/dataListView";
import { ListViewType } from "../../../../shared/list/useListHook";
import { Text } from "../../../../shared/text";

type Props = {
  account?: Account;
  accountKiboshDevice?: AccountKiboshDevice;
  refresh?: boolean;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

export default function KiboshDevicePortForwardings({ account, refresh, accountKiboshDevice, onCancel, onSave }: Props) {
  const [currentPortForwarding, setCurrentPortForwarding] = useState<KiboshDevicePortForwarding>(new KiboshDevicePortForwarding());
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [showPortForwardingModal, setShowPortForwardingModal] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const abortController = new AbortController();

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    if (!accountKiboshDevice?.kiboshDeviceReference)
      return [];

    setLoading(true);
    const response = await apiCall(DashApi.getPortForwardings(accountKiboshDevice?.kiboshDeviceReference!, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    return response.success ? KiboshDevicePortForwarding.toArrayOfClass(response.data?.value || []) : [];
  };

  const onPortForwardingSave = (e: any) => {
    setReload(true);
  }

  const onEditClick = (portForwarding: KiboshDevicePortForwarding) => {
    setCurrentPortForwarding({...portForwarding});
    setShowPortForwardingModal(true);
  };

  const onNewClick = () => {
    setCurrentPortForwarding(new KiboshDevicePortForwarding());
    setShowPortForwardingModal(true);
  };

  const onDeleteClick = (portForwarding: KiboshDevicePortForwarding) => {
    setCurrentPortForwarding(portForwarding);
    setIsDeleteConfirmationPopupOpen(true);
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(DashApi.deletePortForwarding(accountKiboshDevice?.kiboshDeviceReference!, currentPortForwarding.id!, abortController.signal));
    setLoading(false);

    if (response.success) {
      message.success(`Port Forwarding deleted successfully.`);
      setIsDeleteConfirmationPopupOpen(false);
      setReload(true);
    }
  };

  const BlockItem: React.FC<{ data: any }> = ({ data }) => (
    <group
      data-direction="column"
      data-contain=""
      data-border=""
      data-radius="10"
      data-background="context"
      data-cursor="pointer"
      className={!!data.inactive ? "inactive" : ""}
    >
      <group data-direction="column" data-contain="" data-gap="5" data-space="20">
        <Text
          data-weight="700"
          data-ellipsis=""
          data-color="main"
        >
          {data.name}
        </Text>
        <Text data-wrap="wrap" data-clamp="3" data-line="1.5">
          From Port: {data.srcDport}
        </Text>
        <Text data-wrap="wrap" data-clamp="3" data-line="1.5">
          To IP: {data.destIp}
        </Text>
        <Text data-wrap="wrap" data-clamp="3" data-line="1.5">
          To Port: {data.destPort}
        </Text>
        <Text data-wrap="wrap" data-clamp="3" data-line="1.5">
          Protocol: {data.proto}
        </Text>
      </group>
      <separator data-horizontal="" data-margin="none"></separator>
      <group data-gap="5" data-space="10" data-align="center" data-justify="end" >
        <Tooltip title="Edit"><div className='button micro' onClick={() => onEditClick(data)}><icon>edit_square</icon></div></Tooltip>
        <Tooltip title="Delete"><div className='button micro' onClick={() => onDeleteClick(data)}><icon>delete</icon></div></Tooltip>
      </group>
    </group>
  );

  return (
    <>
      { loading &&
        <Loader />
      }
      {loading && <Loader />}
      <view
        data-scroll=""
        data-space="40"
        data-gap="30">
        <group data-gap="10" data-direction="column">
          <text data-weight="700" data-text-size="xx-large" data-wrap="wrap" data-color="main-dark">Port Forwardings</text>
          <text data-wrap="wrap" data-length="300" data-line="1.5" data-light="">Here you can manage all your Port Forwarding Settings.</text>
        </group>
        <group data-width="auto" data-position="left" data-gap="10" data-align="center">
          <Tooltip title="Create New" arrow={false}>
            <group data-width="auto">
              <Button
                secondary
                data-length="50"
                data-height="50"
                data-elevation="6"
                rounded
                icon="add"
                onClick={onNewClick}
              />
            </group>
          </Tooltip>
          <separator data-vertical=""></separator>
          <Tooltip title="Refresh" arrow={false}>
            <group data-width="auto">
              <Button
                outline
                data-length="50"
                data-height="50"
                data-elevation="6"
                rounded
                icon="refresh"
                onClick={() => setReload(true)}
              />
            </group>
          </Tooltip>
        </group>
        <DataListView
          dataPagination={false}
          // dataSource={dataSource}
          getPageAsync={getPage}
          keyField="paymentId"
          itemComponent={BlockItem}
          lazyLoadPages={true}
          loading={loading}
          reload={reload}
          renderContainer={false}
          totalRecords={totalRecords}
          view={ListViewType.Block}
        />
      </view>
      {!!showPortForwardingModal &&
        <KiboshDevicePortForwardingModal
          accountKiboshDevice={accountKiboshDevice!}
          closeOnSave={true}
          open={showPortForwardingModal}
          portForwarding={currentPortForwarding}
          onClose={() => setShowPortForwardingModal(false) }
          onSave={(e) => onPortForwardingSave(e) } />
      }
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
