// 3rd-party modules
import { RouteObject, useRoutes } from 'react-router-dom';

// project modules
import AccountPaymentReturnPage from './pages/AccountPaymentReturnPage';
import DefaultLayout from './layouts/DefaultLayout';
import EmptyLayout from './layouts/EmptyLayout';
import IndexPage from './pages/IndexPage';
import LoginPage from './pages/auth/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import ProtectedRoute from './components/auth/ProtectedRoute';
import TemplatePage from './pages/template';
import ProductPage from './pages/ProductPage';
import FuturesPage from './pages/FuturesPage';
import PricingPage from './pages/PricingPage';
import ReviewsPage from './pages/ReviewsPage';
import FAQPage from './pages/FAQPage';
import SignupPage from './pages/auth/SignupPage';
import SupportPage from './pages/SupportPage';
import BlogPage from './pages/BlogPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import EmailConfirmationPage from './pages/auth/EmailConfirmationPage';
import AccountPage from './pages/portal/AccountPage';
import PortalLayout from './layouts/PortalLayout';
import DynamicPage from './pages/DynamicPage';
import EmailNotConfirmedPage from './pages/auth/EmailNotConfirmedPage';
import CreateNewSubscriptionPage from './pages/auth/CreateNewSubscriptionPage';
import HelpDeskPage from './pages/portal/HelpDeskPage';
import ProfilesListPage from './pages/portal/ProfilesListPage';
import DashPage from './pages/portal/DashPage';
import HomePage from './pages/portal/HomePage';

export default function AppRouter() {
  const routes: RouteObject[] = [
  {
    path: 'auth',
    element: <DefaultLayout />,
    children: [
      { path: 'login', element: <LoginPage /> },
      { path: 'forgot-password', element: <ForgotPasswordPage /> },
      { path: 'signup', element: <SignupPage /> },
    ]
  },
  {
    path: '/not-confirmed-email',
    element: <DefaultLayout />,
    children: [
      { path: '', element: <EmailNotConfirmedPage /> },
    ]
  },
  {
    path: '/reset-password',
    element: <DefaultLayout />,
    children: [
      { path: '', element: <ResetPasswordPage /> },
    ]
  },
  {
    path: '/email-confirmation',
    element: <DefaultLayout />,
    children: [
      { path: '', element: <EmailConfirmationPage /> },
    ]
  },
  {
    path: '/blog',
    element: <DefaultLayout />,
    children: [
      { path: '', element: <BlogPage /> },
    ]
  },
  {
    path: '/faq',
    element: <DefaultLayout />,
    children: [
      { path: '', element: <FAQPage /> },
    ]
  },
  {
    path: '/futures',
    element: <DefaultLayout />,
    children: [
      { path: '', element: <FuturesPage /> },
    ]
  },
  {
    path: '/pricing',
    element: <DefaultLayout />,
    children: [
      { path: '', element: <PricingPage /> },
    ]
  },
  {
    path: '/product',
    element: <DefaultLayout />,
    children: [
      { path: '', element: <ProductPage /> },
    ]
  },
  {
    path: '/reviews',
    element: <DefaultLayout />,
    children: [
      { path: '', element: <ReviewsPage /> },
    ]
  },
  {
    path: '/support',
    element: <DefaultLayout />,
    children: [
      { path: '', element: <SupportPage /> },
    ]
  },
  {
    path: '/payment',
    element: <DefaultLayout />,
    children: [
      { path: 'return', element: <AccountPaymentReturnPage /> },
    ]
  },
  {
    path: '/portal',
    element: <PortalLayout />,
    children: [
      { path: '', element: <ProtectedRoute><HomePage /></ProtectedRoute> },
      { path: 'profiles', element: <ProtectedRoute><ProfilesListPage /></ProtectedRoute> },
      { path: 'create-new-subscription', element: <ProtectedRoute><CreateNewSubscriptionPage /></ProtectedRoute> },
      { path: 'support-tickets', element: <ProtectedRoute><HelpDeskPage /></ProtectedRoute> },
      { path: 'account-details', element: <ProtectedRoute><AccountPage /></ProtectedRoute> },
      { path: 'dash', element: <ProtectedRoute><DashPage /></ProtectedRoute> },
    ]
  },
  {
    path: '/_p',
    element: <DefaultLayout><DynamicPage /></DefaultLayout>
  },
  {
    index: true,
    element: <DefaultLayout><IndexPage /></DefaultLayout>
  }
];

  if (process.env.NODE_ENV === 'development') {
    routes.push({
        path: '/template',
        element: <EmptyLayout />,
        children: [
          { path: '', element: <TemplatePage /> }
        ]
    });
  }

  routes.push({
    path: '*',
    element: <NotFoundPage />
  });

  return useRoutes(routes);
}
