// project modules
import AccountLocationForm from './AccountLocationForm';
import Popup from '../../shared/popup/popup';

// models
import { Account } from '../../../models/account';
import { AccountLocation } from '../../../models/accountLocation';


type Props = {
  closeOnSave?: boolean;
  modalTitle?: string;
  open: boolean;
  account: Account;
  accountLocation: AccountLocation;
  shouldAddBilling: boolean;
  onClose?: () => void;
  onSave?: (accountLocation: any) => void;
};

export default function AccountLocationModal({ closeOnSave = false, modalTitle = "", open, account, accountLocation, shouldAddBilling = false, onClose, onSave }: Props) {
  const onCancel = () => {
    if (onClose) onClose();
  };

  const onBillingAddressChange = () => {
    if (onSave) onSave(accountLocation);
  }

  const onFormSave =  (accountLocation: AccountLocation) => {
    if (onSave) onSave(accountLocation);

    if (closeOnSave) {
      open = false;

      onCancel();
    }
  };

  return (
    <Popup
      adaptive
      title={ modalTitle || `Account: ${account.accountName} - ${
        !accountLocation.accountLocationId
          ? "New Location"
          : "Update Location"
      }`}
      onCancel={onCancel}
      onClose={onCancel}
      noCommandbar={true}
      fixSize="auto"
    >
      <AccountLocationForm
        refresh={open}
        accountId={account.accountId!}
        accountLocation={accountLocation}
        shouldAddBilling={shouldAddBilling}
        onClose={onCancel}
        onSave={onFormSave}
        onBillingAddressChange={onBillingAddressChange} />
    </Popup>
  );
}
