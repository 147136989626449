// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToInstance } from 'class-transformer';

export class AccountBilling {
  @Expose()
  public accountInvoiceId?: string;
  @Expose()
  public accountId?: string;
  @Expose()
  public accountName?: string;
  @Expose()
  public kiboshDeviceReference?: string;
  @Expose()
  public paymentId?: string;
  @Expose()
  public amountPaid?: string;
  @Expose()
  public currency?: string;
  @Expose()
  public status?: string;
  @Expose()
  public invoicePdf?: string;
  @Expose()
  public paymentDate?: string;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(AccountBilling, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(AccountBilling, camelcaseKeys(data, { deep: true }) as AccountBilling[], { excludeExtraneousValues: true });
  }
}
