// 3rd-party modules
import { useEffect, useState } from "react";

// project modules
import Button from "../shared/button";
import Loader from "../shared/loader";
import { apiCall } from "../../helpers/apiHelper";

// apis
import * as ProductApi from '../../apis/productApi';

// models
import { Product } from "../../models/product";

type Props = {
  accountId?: Number;
  cancelText?: string;
  okText?: string;
  refresh?: boolean;
  selectedProduct?: Product | null;
  onCancel?: () => void;
  onSave?: (product: Product) => void;
};

const getProducts = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(ProductApi.getProducts({ "expand": ["data.default_price"], "active": true }, abortSignal));

  return response.success ? Product.toArrayOfClass(response.data?.value || []) : [];
};

export default function AccountSubscription({ accountId, cancelText = "Cancel", okText = "Save Changes", refresh, onCancel, onSave }: Props) {
  const abortController = new AbortController();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  useEffect(() => {
    const getProductsAsync = async () => {
      setLoading(true);
      setProducts(await getProducts(abortController.signal))
      setLoading(false);
    }

    getProductsAsync();
  }, []);

  const onCancelClick = () => {
    if (onCancel) {
      onCancel();
    }
  }

  const onCardSelect = (item: Product) => {
    setSelectedProduct(item);
  }

  const onSubmit = async () => {
    // let response: ApiResponse;

    // setLoading(true);

    // formData.userName = formData.email;

    // if (!account?.accountId)
    //   response = await apiCall(AccountApi.insertAccount(formData, abortController.signal));
    // else
    //   response = await apiCall(AccountApi.updateAccount(formData, abortController.signal));

    // if (response.success) {
    //   message.success(`User ${!account?.accountId ? 'added' : 'edited'} successfully.`);

    //   if (account?.accountId && formData.password && formData.confirmPassword) {
    //     response = await apiCall(AccountApi.changePassword({ username: account.username!, password: formData.password }, abortController.signal));

    //     if (response.success)
    //       message.success('Password changed successfully.');
    //     else
    //       message.error('Password changed faild.');
    //   }

    //   if (onSave) onSave(Account.toClass(response.data?.value));

    // } else
    //   message.error(response.error?.value);

    // setLoading(false);
    if (selectedProduct != null) {
      if (onSave) onSave(selectedProduct);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <form 
      data-length="auto" 
      data-type="group" 
      //data-gap="40"
      >
        <space data-height="40"></space>
        <group
          //data-space="20"
          data-direction="column"
          data-align="start"
          data-gap="10"
        >

          <group
            data-firection="column"
            //data-align="center"
            //data-justify="center"
            data-gap="30"
            data-grid-template="300"
            data-type="grid"
          >
            {
              products.map(item =>
                <group
                  key={item.productId}
                  data-elevation="5"
                  data-radius="15"
                  data-align="center"
                  data-justify="center"
                  data-direction="column"
                  data-gap="20"
                  data-space="30"
                  data-background="main-background"
                  //data-length="forcefit"
                  //data-max-length="600"
                  //data-min-length="300"
                  //data-fit="1"
                  data-shrink="0"
                  className="fee_plan_selector"
                  onClick={() => onCardSelect(item)}
                >
                  <group data-gap="10" data-direction="column">
                    <group data-align="center" data-gap="5">
                      <text data-wrap="wrap" data-weight="500" data-text-size="large">Ki<text data-weight="900" data-text-size="large">bosh</text></text>
                      <text data-wrap="wrap" data-uppercase data-accent="" data-weight="900" data-text-size="large">{item.name}</text>
                    </group>
                    <text data-wrap="wrap" data-weight="700" data-text-size="large">${((item.price?.unitAmount || 0) / 100).toFixed(2)}</text>
                    <group data-gap="10" data-weight="700">
                      <group>
                        <text data-ellipsis="" data-wrap="wrap" >
                          {" "}
                          {item.description}{" "}
                        </text>
                      </group>
                    </group>
                  </group>

                  <group>
                    <Button primary rounded data-space-horizontal="20" onClick={() => onCardSelect(item)}>
                      <text>Select</text>
                    </Button>
                  </group>
                </group>
              )
            }
            {/* <group
                  // data-index="1"
                  // data-elevation="2"
                  data-elevation="5"
                  // data-min-height="200"
                  data-radius="15"
                  data-align="center"
                  data-justify="center"
                  data-direction="column"
                  data-gap="20"
                  data-space="50"
                  // data-space-vertical="70"
                  data-background="main-background"
                  data-length="350"
                  data-shrink="0"
                  className="fee_plan_selector"
                >
                  <group data-gap="20">
                  <wrap>
                      <text data-weight="500" data-text-size="large">
                        Ki
                      </text>
                      <text data-weight="900" data-text-size="large">
                        bosh
                      </text>
                      <text data-uppercase data-space='5' data-accent data-weight="900" data-text-size="large">
                        Annual
                      </text>
                    </wrap>

                    <text data-wrap="wrap">
                      Instantly removes all unwanted content from your internet.
                    </text>
                    <text data-wrap="wrap">
                    14 day free trial - log in and cancel anytime yourself.
                    </text>
                    <text data-weight="700" data-text-size="large">
                      $29.99 <sup data-text-size="13" data-weight="500">/year</sup>
                    </text>
                    <group data-gap="20" data-weight="700">
                      <group>
                        <text data-ellipsis="">
                          {" "}
                          Works on your wireless router{" "}
                        </text>
                      </group>
                      <group>
                        <text data-ellipsis=""> Works immediately </text>
                      </group>
                      <group>
                        <text data-ellipsis=""> Nothing to configure </text>
                      </group>
                      <group>
                        <text data-ellipsis=""> Same internet speed </text>
                      </group>
                      <group>
                        <text data-ellipsis=""> Enforces SafeSearch </text>
                      </group>
                    </group>
                  </group>

                  <group>
                    <Button primary rounded data-space-horizontal="20" onClick={() => onCardSelect(0, 3)}>
                      <text>Select</text>
                    </Button>
                  </group>
                </group>
                <group
                  data-elevation="5"
                  //data-min-height="200"
                  data-radius="15"
                  data-align="center"
                  data-justify="center"
                  data-direction="column"
                  data-gap="20"
                  data-space="50"
                  data-background="main-background"
                  data-length="350"
                  data-shrink="0"
                  className="fee_plan_selector"
                >
                  <group data-gap="20">
                    <wrap>
                      <text data-weight="500" data-text-size="large">
                        Ki
                      </text>
                      <text data-weight="900" data-text-size="large">
                        bosh
                      </text>
                      <text data-uppercase data-space='5' data-accent data-weight="900" data-text-size="large">
                        Monthly
                      </text>
                    </wrap>

                    <text data-wrap="wrap">
                      Instantly removes all unwanted content from your internet.
                    </text>
                    <text data-wrap="wrap">
                    14 day free trial - log in and cancel anytime yourself.
                    </text>
                    <text data-weight="900" data-text-size="large">
                      $29.99 <sup data-text-size="13" data-weight="500">/year</sup>
                    </text>
                    <group data-gap="20" data-weight="700">
                      <group>
                        <text data-ellipsis="">
                          {" "}
                          Works on your wireless router{" "}
                        </text>
                      </group>
                      <group>
                        <text data-ellipsis=""> Works immediately </text>
                      </group>
                      <group>
                        <text data-ellipsis=""> Nothing to configure </text>
                      </group>
                      <group>
                        <text data-ellipsis=""> Same internet speed </text>
                      </group>
                      <group>
                        <text data-ellipsis=""> Enforces SafeSearch </text>
                      </group>
                    </group>
                  </group>

                  <group>
                    <Button primary rounded data-space-horizontal="20" onClick={() => onCardSelect(1, 29.99)}>
                      <text>Select</text>
                    </Button>
                  </group>
                </group> */}
          </group>

        </group>
        <space data-height="40"></space>
        <group
          //data-space="10"
          data-align="center"
          data-gap="10"
         // data-wrap="no"
        >
          <Button large material outline data-length="forcefit" onClick={onCancelClick}>
            <text data-space-horizontal="20">{cancelText}</text>
          </Button>
          <Button data-length="forcefit" disabled={selectedProduct == null} large material primary onClick={onSubmit}>
            <text>{okText}</text>
          </Button>
        </group>
        <space data-height="40" data-adaptive="mobile"></space>
      </form>
    </>
  );
}
