
// 3rd-party modules
import { useNavigate } from 'react-router-dom';

// project modules
import { useAppDispatch } from "../../hooks/storeHooks";
import { logoutUser } from '../../stores/userStore';
import CreateNewSubscription from "../../components/new-subscription-steps/CreateNewSubscription";

export default function CreateNewSubscriptionPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onCancel = () => {
    dispatch(logoutUser());
    navigate('/');
  };

  const onSave = () => {

  };

  return (
    <>
      <CreateNewSubscription onCancel={onCancel} onSave={onSave} />
    </>
  );
}
