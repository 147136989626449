// 3rd-party modules
import * as yup from 'yup';
import { message } from 'antd';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

// project modules
import Button from '../../components/shared/button';
import Loader from '../../components/shared/loader';
import { apiCall } from '../../helpers/apiHelper';
import { Password } from '../../components/shared/inputs/input';
import { trimStringProps } from '../../helpers/objectHelper';

// apis
import * as AuthApi from '../../apis/authApi';

// models
import { ApiResponse } from '../../models/response';

type FormValues = {
  email: string;
  token: string;
  password: string;
  confirmPassword: string;
};

const schema = yup.object().shape({
  email: yup.string().label("Email").max(250).email().required(),
  token: yup.string().label("Token").required(),
  password: yup.string().label("Password").min(6).max(50).test('user-id provided', 'Please enter password', (value) => (value !== undefined && value?.length > 0)),
  confirmPassword: yup.string().label("Confirm Password").max(50).oneOf([yup.ref('password'), ''], 'Passwords must match'),
});

export default function ResetPasswordPage() {
  const { control, handleSubmit, setValue, setFocus } = useForm<FormValues | any>({
    resolver: yupResolver(schema)
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const abortController = new AbortController();

  useEffect(() => {
    const email = searchParams.get("email");
    const token = searchParams.get("token");

    if (email && token) {
      setValue("email", email);
      setValue("token", token);
    } else {
      navigate({
        pathname: "/auth/login",
      });
    }

    setFocus("password");
  }, []);

  const onSubmit = async (formData: FormValues) => {
    setLoading(true);
    let response: ApiResponse = await apiCall(AuthApi.resetPassword(trimStringProps(formData), abortController.signal));
    setLoading(false);

    if (response.success) {
      message.success("Your Password updated successfully.");

    } else
      message.error(response.error?.value);
  };

  const onBackToLoginClick = () => {
    navigate({
      pathname: "/auth/login",
    });
  };

  return (
    <>
      {loading && <Loader />}
      <view data-border="none">
        <section data-position="center">
          <wrapper>
            <group data-space-horizontal="10">
              <group data-direction="column" data-gap="10">
                <text
                  data-weight="800"
                  data-text-size="xx-large"
                  data-wrap="wrap"
                >
                  Reset Password
                </text>
                <text data-text-size="large" data-wrap="wrap">
                  Set new password for your KiBosh Account
                </text>
              </group>
              <group
                data-gap="10"
                data-direction="column"
                data-space-vertical="50"
                data-max-length="300">
                <Password
                  control={control}
                  autoComplete=""
                  name="password"
                  label="Password"
                  dataLength="auto"
                  size="large"/>
                <Password
                  control={control}
                  autoComplete=""
                  name="confirmPassword"
                  label="Confirm Password"
                  dataLength="auto"
                  size="large"/>
                <space></space>
                <Button
                  large
                  primary
                  text="Reset Password"
                  onClick={handleSubmit(onSubmit)} />
                <Button
                  fit
                  large
                  outline
                  text="Back To Login" onClick={onBackToLoginClick} />
              </group>
            </group>
          </wrapper>
        </section>
      </view>
    </>
  );
}
