// project modules
import { getBaseUrl, sendRequest } from '../helpers/apiHelper';

// models
import { DataSourceRequest } from '../models/dataSourceRequest';
import { SupportTicket } from '../models/supportTicket';

export const getSupportTickets = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/supportTicket/list`, 'post', request, null, abortSignal);
};

export const insertSupportTicket = async (supportTicket: SupportTicket, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/supportTicket`, 'post', supportTicket, null, abortSignal);
};

export const updateSupportTicket = async (supportTicket: SupportTicket, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/supportTicket`, 'put', supportTicket, null, abortSignal);
};

export const deleteSupportTicket = async (supportTicket: SupportTicket, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/supportTicket`, 'delete', supportTicket, null, abortSignal);
};

export const getSummaryReport = async (abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/supportTicket/summaryReport`, 'get', null, null, abortSignal);
};
