// 3rd-party modules
import { useEffect, useState } from "react";

// project modules
import Button from "../../../../shared/button";
// import DataTable from "../../../../shared/list/dataTable";
// import Loader from "../../../../shared/loader";
import Select from "../../../../shared/inputs/select";
import { apiCall } from "../../../../../helpers/apiHelper";

// apis
import * as AccountKiboshDeviceApi from '../../../../../apis/accountKiboshDeviceApi';
import * as DashApi from '../../../../../apis/dashApi';

// models
import { Account } from "../../../../../models/account";
import { AccountKiboshDevice } from "../../../../../models/accountKiboshDevice";
import { DataSourceRequest } from "../../../../../models/dataSourceRequest";
import { AccountDevice } from "../../../../../models/accountDevice";
import { accountDeviceExcludViewModel } from "../../../../../models/types/accountDevice";
import Checkbox from "../../../../shared/inputs/checkbox";
import AccountDeviceFilterationModal from "./AccountDeviceFilterationModal";
import { CONSTANTS } from "../../../../../helpers/defines";
import moment from "moment-timezone";

type Props = {
  account?: Account;
  deviceReference?: string;
  refresh?: boolean;
  kiboshDeviceReference?: string;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

export default function AccountDeviceFilterations({ account, deviceReference, refresh, kiboshDeviceReference, onCancel, onSave }: Props) {
  const [accountKiboshDevices, setAccountKiboshDevices] = useState<AccountKiboshDevice[]>([]);
  const [accountDevices, setAccountDevices] = useState<AccountDevice[]>([]);
  const [excludingData, setExcludingDate] = useState<accountDeviceExcludViewModel | null>(null);
  const [currentAccountKiboshDeviceReference, setCurrentAccountKiboshDeviceReference] = useState<string>("");
  const [currentAccountDevice, setCurrentAccountDevice] = useState<AccountDevice | null>(null);
  const [showBlockingModal, setShowBlockingModal] = useState(false);
  const [isExcluded, setIsExcluded] = useState<boolean>(false);
  // const [loading, setLoading] = useState(false);
  const [loadingAccountKiboshDevices, setLoadingAccountKiboshDevices] = useState(false);
  const [loadingAccountDevices, setLoadingAccountDevices] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  let abortController = new AbortController();

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  useEffect(() => {
    if (refresh) {
      if (!kiboshDeviceReference) {
        getAccountKiboshDevicesAsync();
      } else if (!deviceReference) {
        getAccountDevicesAsync();
      }
    }
  }, [refresh]);

  useEffect(() => {
    setIsExcluded(!currentAccountDevice?.isExcluded)
  }, [currentAccountDevice]);

  const getAccountDevicesAsync = async (routerRef?: string) => {
    const data = await getAccountDevices(routerRef, abortController.signal);

    if (data.length && currentAccountDevice?.id) {
      const currentDevice = data.find(x => x.id === currentAccountDevice.id);
      if (currentDevice) {
        setCurrentAccountDevice(currentDevice)
      }
    }

    setAccountDevices(data);
  }

  const getAccountDevices = async (routerRef?: string, abortSignal?: AbortSignal) => {
    setLoadingAccountDevices(true);
    const response = await apiCall(DashApi.getClientDevices(kiboshDeviceReference || currentAccountKiboshDeviceReference || routerRef!, abortSignal));
    setLoadingAccountDevices(false);

    if (response.success) {
      return AccountDevice.toArrayOfClass(response.data?.value || []).map((x: any) => {
        return {
          ...x,
          sorter: x.groupId ? Number(x.groupId) : Infinity
        }
      }).sort((a: any, b: any) => a.sorter - b.sorter);

    }
    else {
      return [];
    }
  };

  const getAccountKiboshDevicesAsync = async () => {
    setAccountKiboshDevices(await getAccountKiboshDevices({}, abortController.signal));
  }

  const getAccountKiboshDevices = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
    setLoadingAccountKiboshDevices(true);
    const response = await apiCall(AccountKiboshDeviceApi.getAccountKiboshDevices(account?.accountId!, request, abortSignal));
    setLoadingAccountKiboshDevices(false);

    return response.success ? AccountKiboshDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const onAccountKiboshDeviceChange = (kiboshDeviceReference: any) => {
    setCurrentAccountKiboshDeviceReference(kiboshDeviceReference);
    getAccountDevicesAsync(kiboshDeviceReference);
  }

  const onAccountDeviceChange = (ref: any) => {
    const currentDevice = accountDevices.find(x => x.id === ref);
    setCurrentAccountDevice(currentDevice || null);
  }

  const onExcludingCheckboxChange = async (event: any) => {
    const data: accountDeviceExcludViewModel = {
      excluded: !!event.target?.checked,
      deviceId: currentAccountDevice!.id!,
      kiboshDeviceReference: kiboshDeviceReference!,
      minutesBeforeExpiration: 0
    }

    setExcludingDate(data);
    setShowBlockingModal(true);
  }

  const onAccountDeviceFilterationSave = (accountDevice: AccountDevice) => {
    setIsExcluded(!accountDevice.isExcluded)
    getAccountDevicesAsync();
  }

  return (
    <>
      <view
        data-scroll=""
        data-space="30"
        data-gap="30">
        <group data-gap="10" data-direction="column">
          <text data-weight="700" data-text-size="xx-large" data-wrap="wrap" data-color="main-dark">Raw Internet</text>
          <text data-wrap="wrap" data-length="300" data-line="1.5" data-light="">Here, you can choose a device to disable all filtering and access unfiltered Internet for a specified period.</text>
        </group>

        <group data-width="auto" data-position="left" data-align="center" data-gap="10">
          <group data-width="auto" data-position="left" data-align="center" data-gap="10" data-space="10" data-radius="5" data-elevation="1" data-backdrop="">
            {!kiboshDeviceReference && (
              <>
                <Select
                  autoComplete=""
                  label="Kibosh Device"
                  labelPosition="left"
                  dataLength="320"
                  onChange={onAccountKiboshDeviceChange}
                  loading={loadingAccountKiboshDevices}
                  allowSearch={true}
                  options={
                    accountKiboshDevices?.map((item) => {
                      return {
                        text: `${item.kiboshDeviceName!} (${item.kiboshDeviceReference!})`,
                        value: item.kiboshDeviceReference!,
                      };
                    }) || []
                  }
                  button={
                    <Button
                      material
                      icon="refresh"
                      onClick={getAccountKiboshDevicesAsync}
                    />
                  }
                />
              </>
            )}
            {!deviceReference && (
              <>
                <Select
                  autoComplete=""
                  disabled={
                    loadingAccountDevices ||
                    !(
                      kiboshDeviceReference ||
                      currentAccountKiboshDeviceReference
                    )
                  }
                  label="Device"
                  labelPosition="left"
                  dataLength="320"
                  onChange={onAccountDeviceChange}
                  loading={loadingAccountDevices}
                  allowSearch={true}
                  options={
                    accountDevices?.map((item) => {
                      return {
                        text: `${item.name!} ${item.ip ? '(' + item.ip + ')' : ''}`,
                        value: item.id!,
                      };
                    }) || []
                  }
                  button={
                    <Button
                      material
                      icon="refresh"
                      onClick={getAccountDevicesAsync}
                    />
                  }
                />
              </>
            )}
          </group>
        </group>
        {
          !!currentAccountDevice &&
          <Checkbox
            label={!isExcluded ? "Enable Filtration for device" : `Disabled Filtration for device until ${moment(currentAccountDevice.excludedUntil).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}`}
            minimal
            dataLength="auto"
            onChange={onExcludingCheckboxChange}
            checked={!isExcluded}/>
        }
      </view>
      {!!showBlockingModal && (
        <AccountDeviceFilterationModal
          open={showBlockingModal}
          accountDevice={currentAccountDevice!}
          excludingData={excludingData!}
          closeOnSave={true}
          onClose={() => setShowBlockingModal(false)}
          onSave={(e) => onAccountDeviceFilterationSave(e)}
        />
      )}
    </>
  );
}
