// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, Transform, plainToInstance } from 'class-transformer';

export class AccountSubscription {
  @Expose()
  public accountSubscriptionId?: string;
  @Expose()
  public accountId?: string;
  @Expose()
  public customerId?: string;
  @Expose()
  public kiboshDeviceReference?: string;
  @Expose()
  public status?: string;
  @Expose()
  public currentPeriodStart?: string;
  @Expose()
  public currentPeriodEnd?: string;
  @Expose()
  public interval?: string;
  @Expose()
  public canceled?: boolean;
  @Expose()
  public cancelDate?: string;
  @Expose()
  @Transform(({ value, key, obj }) => value && JSON.parse(value))
  public profile?: string;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(AccountSubscription, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(AccountSubscription, camelcaseKeys(data, { deep: true }) as AccountSubscription[], { excludeExtraneousValues: true });
  }
}
