// 3rd-party modules
import { Tooltip, message } from "antd";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";

// project modules
import Button from "../shared/button";
import Input, { Password } from "../shared/inputs/input";
import Loader from "../shared/loader";
import Select from "../shared/inputs/select";
import yup from "../../plugins/yup";
import { apiCall } from "../../helpers/apiHelper";
import { trimStringProps } from "../../helpers/objectHelper";

// apis
import * as AccountApi from '../../apis/accountApi';
import * as CommonValueApi from '../../apis/commonValueApi';
import * as AccountLocationApi from '../../apis/accountLocationApi';

// models
import AccountLocationModal from "./locations/AccountLocationModal";
import ChangePasswordModel from "./ChangePasswordModel";
import ConfirmationPopup from "../shared/popup/confirmationPopup";
import ListView from "../shared/list/listView";
import Switchable from "../shared/additionalComponents/Switchable";
import { Account } from "../../models/account";
import { AccountLocation } from "../../models/accountLocation";
import { accountRegisterViewModel } from "../../models/types/auth";
import { ApiResponse } from "../../models/response";
import { CommonValue } from "../../models/commonValue";
import { CONSTANTS } from "../../helpers/defines";
import { ListViewType } from "../shared/list/useListHook";
import { SvgHamburger } from "../svg";

type Props = {
  account?: Account;
  refresh?: boolean;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

const getAccountTypes = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(CommonValueApi.getAccountTypes(abortSignal));

  return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
};

export default function AccountForm({ account, refresh, onCancel, onSave }: Props) {
  const schema = yup.object().shape({
    firstName: yup.string().label("First Name").max(50).required(),
    lastName: yup.string().label("Last Name").max(50).required(),
    accountName: yup.string().label("Account Name").max(250).required(),
    email: yup.string().label("Email").max(250).required().email(),
    phoneNumber: yup.string().label("Phone").max(20),
    accountTypeId: yup.string().label("Account Type").required(),
    password: yup.string().label("Password").min(6).max(50).test('user-id provided', 'Please enter password', (value) => (value !== undefined && value?.length > 0) || account?.accountId !== undefined),
    confirmPassword: yup.string().label("Confirm Password").max(50).oneOf([yup.ref('password'), ''], 'Passwords must match'),
  });
  const { control, handleSubmit, setValue, getValues, reset } = useForm<accountRegisterViewModel | any>({
    defaultValues: useMemo(() => account, [account]),
    resolver: yupResolver(schema),
  });
  const [accountLocations, setAccountLocations] = useState<AccountLocation[]>([]);
  const [accountTypes, setAccountTypes] = useState<CommonValue[]>([]);
  // const [accountStatus, setAccountStatus] = useState<string>("active");
  const [currentAccountLocation, setCurrentAccountLocation] = useState<AccountLocation>(new AccountLocation());
  // const [currentAccountStatus, setCurrentAccountStatus] = useState<string>("active");
  const [loading, setLoading] = useState(false);
  // const [loadingSave, setLoadingSave] = useState(false);
  const [isAccountNameEditable, setIsAccountNameEditable] = useState(account?.accountTypeId === 33);
  const [isChangeBillingAdressConfirmationPopupOpen, setIsChangeBillingAdressConfirmationPopupOpen] = useState(false);
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  // const [isAccountStatusChangeConfirmationPopupOpen, setIsAccountStatusChangeConfirmationPopupOpen] = useState(false);
  const [loadingAccountTypeList, setLoadingAccountTypeList] = useState(false);
  const [showAccountLocationModal, setShowAccountLocationModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const abortController = new AbortController();

  // useEffect(() => {
  //   if (account) {
  //     if (account.archived) {
  //       setAccountStatus("canceled");
  //     } else {
  //       if (account.lockoutEnabled) {
  //         setAccountStatus("inactive");
  //       } else {
  //         setAccountStatus("active");
  //       }
  //     }
  //   } else {
  //     setAccountStatus("active");
  //   }
  // }, [account]);

  useEffect(() => {
    if (refresh) {
      const getAccountTypesAsync = async () => {
        setLoadingAccountTypeList(true);
        setAccountTypes(await getAccountTypes(abortController.signal));
        setLoadingAccountTypeList(false);
      }

      getAccountTypesAsync();
    }
  }, [refresh]);

  useEffect(() => {
    reset(account);

    if (account?.accountId) {
      getAccountLocations();
    }
  }, [account]);

  // const onCancelClick = () => {
  //   if (onCancel) {
  //     onCancel();
  //   }
  // }

  const onAccountTypeChange = (data: any) => {
    // eslint-disable-next-line eqeqeq
    setIsAccountNameEditable(data == 33);
    setValue("accountTypeId", data);
  }

  const onChangePasswordClick = () => {
    setShowChangePasswordModal(true);
  };

  const onNameChange = (e: any, fieldName: string) => {
    setValue(fieldName, e.target?.value);

    if (!isAccountNameEditable) {
      const firstName = getValues("firstName");
      const lastName = getValues("lastName");
      let accountName: string[] = [];

      if (firstName)
        accountName.push(firstName)

      if (lastName)
        accountName.push(lastName)

      setValue("accountName", accountName.join(" "))
    }
  }

  const onSubmit: SubmitHandler<accountRegisterViewModel> = async (formData: accountRegisterViewModel) => {
    let response: ApiResponse;

    setLoading(true);

    formData.userName = formData.email;

    if (!account?.accountId)
      response = await apiCall(AccountApi.insertAccount(trimStringProps(formData), abortController.signal));
    else
      response = await apiCall(AccountApi.updateAccount(trimStringProps(formData), abortController.signal));

    if (response.success) {
      message.success(`User ${!account?.accountId ? 'added' : 'edited'} successfully.`);

      if (account?.accountId && formData.password && formData.confirmPassword) {
        response = await apiCall(AccountApi.changePassword(trimStringProps({ username: account.username!, password: formData.password }), abortController.signal));

        if (response.success)
          message.success('Password changed successfully.');
        else
          message.error('Password changed faild.');
      }

      if (onSave) onSave(Account.toClass(response.data?.value));
    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  const getAccountLocations = async () => {
    setLoading(true);
    const response = await apiCall(AccountLocationApi.getAccountLocations(account?.accountId!, {}, abortController.signal));
    setLoading(false);
    setAccountLocations(response.success ? AccountLocation.toArrayOfClass(response.data?.value || []) : []);

    return response.success ? AccountLocation.toArrayOfClass(response.data?.value || []) : [];
  };

  const onAccountLocationSave = (e: AccountLocation) => {
    getAccountLocations();
  }

  // const onAccountStatusChange = (event: any, value: any) => {
  //   setCurrentAccountStatus(value);
  //   setIsAccountStatusChangeConfirmationPopupOpen(true);
  // }

  const onDeleteClick = (accountLocation: AccountLocation) => {
    setCurrentAccountLocation(accountLocation);
    setIsDeleteConfirmationPopupOpen(true);
  };

  const onEditClick = (accountLocation: AccountLocation) => {
    setCurrentAccountLocation({ ...accountLocation });
    setShowAccountLocationModal(true);
  };

  const onNewClick = () => {
    setCurrentAccountLocation(new AccountLocation());
    setShowAccountLocationModal(true);
  };

  const onSetAsBillingAddressClick = (accountLocation: AccountLocation) => {
    setCurrentAccountLocation(accountLocation);
    setIsChangeBillingAdressConfirmationPopupOpen(true);
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(AccountLocationApi.deleteAccountLocation(currentAccountLocation));
    setLoading(false);

    if (response.success) {
      message.success(`Account Location deleted successfully.`);
      setIsDeleteConfirmationPopupOpen(false);
      getAccountLocations();
    }
  };

  // const handleChangeStatusRequest = async () => {
  //   setLoadingSave(true);

  //   const formData = {
  //     ...account,
  //     archived: currentAccountStatus === "canceled",
  //     lockoutEnabled: currentAccountStatus === "inactive"
  //   }

  //   let response: ApiResponse;
  //   if (currentAccountStatus === "canceled") {
  //     response = await apiCall(AccountApi.deleteAccount(account!));
  //   } else {
  //     response = await apiCall(AccountApi.updateAccount(trimStringProps(formData), abortController.signal));
  //   }
  //   setLoadingSave(false);

  //   if (response.success) {
  //     message.success(`Account status changed successfully.`);
  //     setIsAccountStatusChangeConfirmationPopupOpen(false);
  //     // setAccountStatus(currentAccountStatus);
  //     if (onSave) {
  //       onSave(Account.toClass(response.data?.value));
  //     }
  //   }
  // };

  const handleSetAsBillingAddressRequest = async () => {
    setLoading(true);
    const response = await apiCall(AccountLocationApi.setBillingAccountLocation(currentAccountLocation));
    setLoading(false);

    if (response.success) {
      message.success(`Account Billing Location changed successfully.`);
      setIsChangeBillingAdressConfirmationPopupOpen(false);
      getAccountLocations();
    }
  };

  const renderAccountForm = () => (
    <>
      <Switchable
        data-index="2"
        data-size="small"
        icon={<><SvgHamburger /></>}
        data-type="adaptive"
        data-placement="left"
        data-elevation="1"
      //  collapseThreshold={800}
        title={"Details"}
        defaultExpanded={false}
        // togglerProps={{ "data-order": "2" }}
      >
        {/* {loadingSave && <Loader/>} */}
        <view
          data-border=""
          data-fit="1"
          data-direction="column"
          data-scroll=""
        >
          <group
            data-space="30"
            data-direction="column"
            // data-scroll=""
            data-length="autofit"
          >
            <group data-direction="column" data-gap="10">
              <group data-gap="10">
                <group data-fit="1" data-direction="column">
                  <Input
                    control={control}
                    name="firstName"
                    label="First Name"
                    // dataLength="150"
                    size="large"
                    onChange={(e) => onNameChange(e, "firstName")}
                  />
                </group>
                <group data-fit="2" data-direction="column">
                  <Input
                    control={control}
                    name="lastName"
                    label="Last Name"
                    // dataLength="250"
                    size="large"
                    onChange={(e) => onNameChange(e, "lastName")}
                  />
                </group>
              </group>
              <separator data-horizontal=""></separator>
            </group>
            <group data-direction="column" data-gap="10">
              <Select
                label="Account Type"
                control={control}
                // dataLength="320"
                loading={loadingAccountTypeList}
                name="accountTypeId"
                allowSearch={true}
                onChange={onAccountTypeChange}
                options={
                  accountTypes?.map((item) => {
                    return {
                      text: item.valueCaption!,
                      value: item.commonValueId!,
                    };
                  }) || []
                }
              />
              <separator horizontal=""></separator>
            </group>
            <group data-direction="column" data-gap="10">
              <Input
                control={control}
                name="accountName"
                label={isAccountNameEditable ? "Company Name" : "Account Name"}
                // dataLength="320"
                size="large"
                disabled={!isAccountNameEditable}
              />
              <separator data-horizontal=""></separator>
            </group>
            <group data-direction="column" data-gap="10">
              <Input
                control={control}
                name="email"
                label="Email"
                // dataLength="320"
                size="large"
              />
              <Input
                type="mask"
                control={control}
                mask={CONSTANTS.DEFAULT_PHONE_NUMBER_MASK}
                maskReplacement={{ _: /\d/ }}
                name="phoneNumber"
                label="Phone"
                // dataLength="320"
                size="large"
              />
              <separator horizontal=""></separator>
            </group>
            {!account?.accountId && (
              <>
                <group data-direction="column" data-gap="10">
                  <separator horizontal=""></separator>
                  <group data-gap="10">
                    <Password
                      control={control}
                      name="password"
                      label="Password"
                      // dataLength="200"
                      size="large"
                    />
                    <Password
                      control={control}
                      name="confirmPassword"
                      label="Confirm Password"
                      // dataLength="200"
                      size="large"
                    />
                  </group>
                </group>
              </>
            )}
          </group>
          <group
            data-gap="10"
            data-sticky="bottom"
            data-space="30"
            data-backdrop=""
            data-elevation="1"
          >
            <Button
              large
              fit
              secondary
              onClick={handleSubmit(onSubmit)}
            >
              <text>Save Changes</text>
            </Button>
            {/* <Button data-length="forcefit" highlight onClick={onCancelClick}>
              <text>Cancel</text>
            </Button> */}
            {!!account?.accountId && (
              <Button
                large
                data-position="right"
                outline

                onClick={onChangePasswordClick}
              >
                Change Password
              </Button>
            )}
          </group>
        </view>
      </Switchable>
      <group data-name="switch-gap" data-shrink="no"></group>
    </>
  )

  const renderAddressSection = () => (
    <view
      // data-name="switchable"
      data-scroll=""
      data-space="20"
      data-gap="30"
    >
      <group data-gap="10" data-direction="column">
        <text data-weight="700" data-text-size="xx-large" data-wrap="wrap" data-color="main-dark">Addresses</text>
        <text data-wrap="wrap" data-length="300" data-line="1.5" data-light="">Here you can manage all your saved addresses, including adding new addresses, editing existing ones, and deleting outdated ones.</text>
      </group>
      <group data-width="auto" data-position="left">
        <Tooltip title="Create New Address" arrow={false}>
          <group data-width="auto">
            <Button
              secondary
              data-length="50"
              data-height="50"
              data-elevation="6"
              rounded
              icon="add"
              // text="New"
              onClick={onNewClick}
            />
          </group>
        </Tooltip>
      </group>
      <ListView
        dataSource={accountLocations}
        view={ListViewType.Block}
        keyField="accountLocationId"
        data-template="260"
        // scrollerProps={{ 'data-space': '20' }}
        listProps={{ 'data-gap': '10' }}
        itemComponent={BlockItem}
      />
    </view>
  )

  const BlockItem: React.FC<{ data: any }> = ({ data }) => {
    return (
      <group data-direction="column" data-border="" data-radius="10" data-contain="">
        <group
          data-space="30"
          data-gap="5"
        >
          <group>
            <text data-weight="700" data-color="main">{data.billingAddress ? "Billing " : ""}Address</text>
          </group>
          <text data-wrap="wrap">
            {[
              data.addressLine1,
              data.addressLine2,
              data.addressLine3,
              data.city,
              data.stateProvince,
              data.zipPostal,
              data.country
            ]
              .filter((x) => x)
              .join(", ")}
          </text>
        </group>
        <group data-gap="5" data-space="10" data-position="bottom" data-align="center" data-border="" data-wrap="no">
          <Button text="Set as Billing Addresss" disabled={data.billingAddress ?? null} onClick={() => onSetAsBillingAddressClick(data)} />
          <Tooltip title="Edit"><Button micro data-position="right" icon="edit" onClick={() => onEditClick(data)} /></Tooltip>
          <separator vertical=""></separator>
          <Tooltip title="Delete"><Button micro icon="delete" onClick={() => onDeleteClick(data)} /></Tooltip>
        </group>
      </group>
    )
  };

  return (
    <view data-vertical>
      {loading && <Loader />}
      {renderAccountForm()}
      {renderAddressSection()}
      {!!showChangePasswordModal && (
        <ChangePasswordModel
          open={showChangePasswordModal}
          closeOnSave={true}
          user={account}
          onClose={() => setShowChangePasswordModal(false)}
        />
      )}
      {!!showAccountLocationModal && (
        <AccountLocationModal
          open={showAccountLocationModal}
          closeOnSave={true}
          account={account!}
          accountLocation={currentAccountLocation}
          shouldAddBilling={!accountLocations.filter(x => x.billingAddress).length}
          onClose={() => setShowAccountLocationModal(false)}
          onSave={(e) => onAccountLocationSave(e)}
        />
      )}
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
      {isChangeBillingAdressConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Set as Billing Adress"
          positiveCallback={handleSetAsBillingAddressRequest}
          negativeCallback={() => {
            setIsChangeBillingAdressConfirmationPopupOpen(false);
          }}
        />
      )}
      {/* {isAccountStatusChangeConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Change"
          positiveCallback={handleChangeStatusRequest}
          negativeCallback={() => {
            setIsAccountStatusChangeConfirmationPopupOpen(false);
          }}
        />
      )} */}
    </view>
  );
}
