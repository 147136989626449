// 3rd-party modules
import axios, { AxiosError, AxiosResponse } from "axios";

// project modules
import history from '../helpers/routeHelper';
import { apiCall, sendRequest } from '../helpers/apiHelper';
import { ApiResponse } from "../models/response";
import { loginUserByRefreshToken, setAuthToken } from "../stores/userStore";
import { store } from '../stores';

// apis
import * as AuthApi from '../apis/authApi';

// defines
import Config from "../config";
let tryLoginUserByRefreshToken: boolean = false;

export default function setup() {
  axios.interceptors.request.use(
      config => {
        config.headers!['Authorization'] = store.getState().user?.authToken ? `Bearer ${store.getState().user?.authToken}` : '';

        return config;
      },
      error => Promise.reject(error)
  );

  // global error handling
  axios.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => {
      const result = new ApiResponse(response.data);
      const errorMessage = result.errors[0];

      switch (errorMessage) {
        case "Authorization token has expired.": // refresh-token won't work here, make sure backend always throws error on unauthorzied
          history.push(`${Config.loginPageUrl}?returnUrl=${window.location.pathname}`);

          break;
      }

      return response;
    },
    async (error: AxiosError) => {
      if (error) {
        console.log("[Global] response error: " + error);

        if (error.response) {
          const result = new ApiResponse(error.response.data as ApiResponse);
          const errorMessage = result.errors[0];

          switch (errorMessage) {
            case "Authentication failed.":
            case "Authorization token has expired.":
              if (!tryLoginUserByRefreshToken) {
                tryLoginUserByRefreshToken = true;

                const user = store.getState().user;

                if (user?.rememberUser.remember && user?.refreshToken && user.rememberUser?.username) {
                  const loginResponse = await store.dispatch(loginUserByRefreshToken({ username: user.rememberUser?.username!, password: '[TEMP]', refreshToken: user?.refreshToken })).unwrap();

                  if (loginResponse.success) {
                    tryLoginUserByRefreshToken = false;
                    if (window.location.pathname.includes("/portal")) {
                      window.location.reload();
                    } else {
                      history.push('/portal');
                    }
                    return;
                  } else {
                    tryLoginUserByRefreshToken = false;

                    history.push(`${Config.loginPageUrl}?returnUrl=${window.location.pathname}`);
                    return;
                  }
                }

                tryLoginUserByRefreshToken = false;

                history.push(`${Config.loginPageUrl}?returnUrl=${window.location.pathname}`);
                return;
              }

              break;

              /*
              const user = store.getState().user;

              if (user?.rememberUser.remember && user?.refreshToken && user.rememberUser?.username) {
                // send request to generate token by refresh-token
                const loginResponse = await apiCall(AuthApi.loginUserByRefreshToken({ username: user.rememberUser?.username, password: '[TEMP]', refreshToken: user?.refreshToken }));

                if (loginResponse.success && loginResponse.extra?.token?.value) {
                  error.response.config.headers.Authorization = `Bearer ${loginResponse.extra?.token?.value}`;

                  store.dispatch(setAuthToken(loginResponse.extra?.token?.value));
                  // resend the request
                  const requestResponse = await apiCall(sendRequest(error.response.config.url!, error.response.config.method!, error.response.config.data, error.response.config));

                  if (requestResponse.success)
                    return;
                }
              }
              */

              /*
              history.push(`${Config.loginPageUrl}?returnUrl=${window.location.pathname}`);
              return;
              */
            case 'Refresh token expired.':
            case 'Invalid refresh token.':
              history.push(`${Config.loginPageUrl}?returnUrl=${window.location.pathname}`);
              return;
          }

          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      }
    }
  );
}
