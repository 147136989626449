// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToInstance } from 'class-transformer';

export class Country {
  @Expose()
  public countryId?: number;
  @Expose()
  public countryAlpha2Code?: string;
  @Expose()
  public countryAlpha3Code?: string;
  @Expose()
  public countryName?: string;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(Country, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(Country, camelcaseKeys(data, { deep: true }) as Country[], { excludeExtraneousValues: true });
  }
}
