// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToInstance } from 'class-transformer';

export class KiboshDeviceWifiConfig {
  @Expose()
  public id?: string;
  @Expose()
  public radio24GhzEnabled?: boolean;
  @Expose()
  public radio24GhzName?: string;
  @Expose()
  public radio24GhzKey?: string;
  @Expose()
  public radio5GhzEnabled?: boolean;
  @Expose()
  public radio5GhzName?: string;
  @Expose()
  public radio5GhzKey?: string;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(KiboshDeviceWifiConfig, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(KiboshDeviceWifiConfig, camelcaseKeys(data, { deep: true }) as KiboshDeviceWifiConfig[], { excludeExtraneousValues: true });
  }
}
