// 3rd-party modules
import ReCAPTCHA from 'react-google-recaptcha';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";

// project modules
import Button from "../shared/button";
import config from "../../config";
import Input, { Password } from "../shared/inputs/input";
import Loader from "../shared/loader";
import Select from "../shared/inputs/select";
import yup from "../../plugins/yup";
import { apiCall } from "../../helpers/apiHelper";

// apis
import * as CommonValueApi from '../../apis/commonValueApi';

// models
import { Account } from "../../models/account";
import { accountRegisterViewModel } from "../../models/types/auth";
import { CommonValue } from "../../models/commonValue";

// defines
import { CONSTANTS } from "../../helpers/defines";

type Props = {
  account?: Account;
  cancelText?: string;
  okText?: string;
  refresh?: boolean;
  parentLoading?: boolean;
  onCancel?: () => void;
  onSave?: (account?: accountRegisterViewModel) => void;
};

const getAccountTypes = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(CommonValueApi.getAccountTypes(abortSignal));

  return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
};

export default function AccountForm({ account, cancelText = "Cancel", okText = "Save Changes", refresh, parentLoading = false, onCancel, onSave }: Props) {
  const schema = yup.object().shape({
    firstName: yup.string().label("First Name").max(50).required(),
    lastName: yup.string().label("Last Name").max(50).required(),
    accountName: yup.string().label("Account Name").max(250).required(),
    email: yup.string().label("Email").max(250).required().email(),
    phoneNumber: yup.string().label("Phone").max(20),
    accountTypeId: yup.string().label("Account Type").required(),
    password: yup.string().label("Password").min(6).max(50).test('user-id provided', 'Please enter password', (value) => (value !== undefined && value?.length > 0) || account?.accountId !== undefined),
    confirmPassword: yup.string().label("Confirm Password").max(50).oneOf([yup.ref('password'), ''], 'Passwords must match'),
  });
  const { control, handleSubmit, getValues, setValue } = useForm<accountRegisterViewModel | any>({
    defaultValues: useMemo(() => account, [account]),
    resolver: yupResolver(schema),
  });
  const [accountTypes, setAccountTypes] = useState<CommonValue[]>([]);
  const [loading, setLoading] = useState(false);
  const [isAccountNameEditable, setIsAccountNameEditable] = useState(account?.accountTypeId === 33);
  const [loadingAccountTypeList, setLoadingAccountTypeList] = useState(false);
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false)
  const abortController = new AbortController();

  useEffect(() => {
    getAccountTypesAsync();
  }, []);

  useEffect(() => {
    if (refresh) {
      getAccountTypesAsync();
    }
  }, [refresh]);

  const onCancelClick = () => {
    if (onCancel) {
      onCancel();
    }
  }

  const getAccountTypesAsync = async () => {
    setLoadingAccountTypeList(true);
    setAccountTypes(await getAccountTypes(abortController.signal));
    setLoadingAccountTypeList(false);
  }

  const onAccountTypeChange = (data: any) => {
    // eslint-disable-next-line eqeqeq
    setIsAccountNameEditable(data == 33);
    setValue("accountTypeId", data);
  }

  const onNameChange = (e: any, fieldName: string) => {
    setValue(fieldName, e.target?.value);
    if (!isAccountNameEditable) {
      const firstName = getValues("firstName");
      const lastName = getValues("lastName");

      let accountName: string[] = [];
      if (firstName) {
        accountName.push(firstName)
      }
      if (lastName) {
        accountName.push(lastName)
      }

      setValue("accountName", accountName.join(" "))
    }
  }

  const onSubmit: SubmitHandler<accountRegisterViewModel> = async (formData: accountRegisterViewModel) => {
    setLoading(false);
    if (onSave) onSave(formData);
  };

  return (
    <>
      {(loading || parentLoading) && <Loader />}
      <form data-length="auto" data-type="group">
        <group data-direction="column" data-align="start" data-gap="10">
          <group width="auto" data-direction="column" data-gap="10">
            <group data-gap="10">
              <Input
                control={control}
                name="firstName"
                label="First Name"
                dataLength="150"
                size="large"
                onChange={(e) => onNameChange(e, "firstName")}
              />
              <Input
                control={control}
                name="lastName"
                label="Last Name"
                dataLength="250"
                size="large"
                onChange={(e) => onNameChange(e, "lastName")}
              />
            </group>
            <group data-direction="column" data-gap="10">
              <Select
                label="Account Type"
                control={control}
                //dataLength="320"
                loading={loadingAccountTypeList}
                name="accountTypeId"
                allowSearch={true}
                onChange={onAccountTypeChange}
                options={
                  accountTypes?.map((item) => {
                    return {
                      text: item.valueCaption!,
                      value: item.commonValueId!,
                    };
                  }) || []
                }
              />
              <separator horizontal=""></separator>
            </group>
            <group data-width="auto" data-direction="column" data-gap="10">
              <Input
                control={control}
                name="accountName"
                label={isAccountNameEditable ? "Company Name" : "Account Name"}
                dataLength="240"
                size="large"
                disabled={!isAccountNameEditable}
              />
              <separator horizontal=""></separator>
            </group>
          </group>
          <group data-width="auto" data-direction="column" data-gap="10">
            <Input
              control={control}
              name="email"
              label="Email"
              dataLength="320"
              size="large"
            />
            <Input
              type="mask"
              control={control}
              mask={CONSTANTS.DEFAULT_PHONE_NUMBER_MASK}
              maskReplacement={{ _: /\d/ }}
              name="phoneNumber"
              label="Phone"
              dataLength="320"
              size="large"
            />
          </group>
          {/* <group data-width="auto" data-direction="column" data-gap="10">
            <separator horizontal=""></separator>
            <Select
              label="Account Type"
              control={control}
              dataLength="360"
              loading={loadingAccountTypeList}
              name="accountTypeId"
              allowSearch={true}
              options={
                accountTypes?.map((item) => {
                  return {
                    text: item.value!,
                    value: item.commonValueId!,
                  };
                }) || []
              }
            />
          </group> */}
          {!account?.accountId && (
            <>
              <group data-width="auto" data-direction="row" data-gap="10">
                {/* <separator horizontal=""></separator> */}
                <Password
                  control={control}
                  name="password"
                  label="Password"
                  dataLength="180"
                  size="large"
                  // labelPosition="left"
                />
                <Password
                  control={control}
                  name="confirmPassword"
                  label="Confirm Password"
                  dataLength="180"
                  size="large"
                  // labelPosition="left"
                />
              </group>
            </>
          )}
          <group data-space-vertical="10">
            <ReCAPTCHA sitekey={config.recaptchaSiteKey} onChange={() => setIsCaptchaSuccess(true)} />
          </group>
        </group>

        <group data-length="360" data-direction="row" data-gap="10">
          <separator horizontal=""></separator>
          <Button
            data-position="right"
            large
            material
            outline
            onClick={onCancelClick}
          >
            <text>{cancelText}</text>
          </Button>
          <Button fit large material primary disabled={!isCaptchaSuccessful} onClick={handleSubmit(onSubmit)}>
            <text>{okText}</text>
          </Button>
        </group>
      </form>
    </>
  );
}
