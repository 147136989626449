import React from 'react';

const Loader: React.FC = React.forwardRef<
    HTMLDivElement, {}
>((props, ref) => {
    return (
      <div ref={ref} className="preloader">
        <div className="background" data-background="main-background"></div>
        <div className="loader">
          <div></div>
        </div>
      </div>
    );
});

export default Loader;
