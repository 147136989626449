// 3rd-party modules
import parse from 'html-react-parser';
import { useContext, useEffect, useState } from "react";

// project modules
import { PostContext } from "../layouts/DefaultLayout";
import { sanitizeHtml } from "../helpers/objectHelper";

// models
import { Post } from '../models/post';

// defines
import postTemplatesJson from "../assets/post-templates.json";

export default function DynamicPage() {
  const postContext = useContext(PostContext);
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    const posts: Post[] = [];

    for (const post of postContext) {
      const template = postTemplatesJson.templates.find(t => t.code === post.template);

      if (template) {
        template.template = template.template.replace('{{title}}', post.title || '');
        template.template = template.template.replace('{{content}}', sanitizeHtml(post.content || ''));

        post.template = template.template;
      }

      posts.push(post);
    }

    setPosts(posts);
  }, [postContext]);

  return (
    <>
      <group>
        <group data-gap="20">
          {posts.map((post) =>
            post.template ? (
              parse(post.template)
            ) : (
              <section key={post.postId}>
                <wrapper>
                  <group>{post.title}</group>
                </wrapper>
                <wrapper>
                  <group>
                    <div>{parse(sanitizeHtml(post.content || ""))}</div>
                  </group>
                </wrapper>
              </section>
            )
          )}
        </group>
      </group>
    </>
  );
}
