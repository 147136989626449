// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToInstance } from 'class-transformer';

export class AccountBlockedSite {
  @Expose()
  public accountBlockedSiteId?: number;
  @Expose()
  public accountId?: number;
  @Expose()
  public blockedSiteName?: string;
  @Expose()
  public blockedSiteUrl?: string;
  @Expose()
  public id?: number;
  @Expose()
  public match?: string;
  @Expose()
  public matchType?: string;
  @Expose()
  public schedule?: any[];

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(AccountBlockedSite, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(AccountBlockedSite, camelcaseKeys(data, { deep: true }) as AccountBlockedSite[], { excludeExtraneousValues: true });
  }
}
