// 3rd-party modules
import * as yup from "yup";

yup.setLocale({
  mixed: {
    required: '${label} is required',
  },
  number: {
    integer: '${label} must be an integer number'
  },
  string: {
    min: '${label} must be minimum of ${min} characters',
    max: '${label} may not be greater than ${max} characters',
  },
});

export default yup;
