// 3rd-party modules
import moment from "moment-timezone";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";

// project modules
import Button from "../../shared/button";
import DataTable, { TableColumn } from "../../shared/list/dataTable";
import Loader from "../../shared/loader";
import OptionBar from "../../shared/optionBar";
import Radio, { RadioType } from "../../shared/inputs/radio";
import Select from "../../shared/inputs/select";
import { apiCall } from "../../../helpers/apiHelper";
import { useCacheHook } from "../../../hooks/useCacheHook";
// import DashboardWidget from "../../global/dashboardWidget";
// import CustomChart from "../../shared/chart/chart";
import CustomChartModal from "../../shared/chart/chartModal";

// apis
import * as AccountKiboshDeviceApi from '../../../apis/accountKiboshDeviceApi';
import * as DashApi from '../../../apis/dashApi';

// models
import Checkbox from "../../shared/inputs/checkbox";
import { Account } from "../../../models/account";
import { AccountKiboshDevice } from "../../../models/accountKiboshDevice";
import { AdminFilterSet } from "../../../models/adminFilterSet";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../models/dataSourceRequest";
import { AccountDevice, AccountDeviceActivityFrequencyLog, AccountDeviceActivityLog } from "../../../models/accountDevice";
// import { message } from "antd";

// defines
import { CONSTANTS } from "../../../helpers/defines";

type Props = {
  account?: Account;
  deviceReference?: string;
  deviceGroupReference?: string;
  hasUpperLevelControl?: boolean;
  kiboshDeviceReference?: string;
  isDomainBased?: boolean;
  refresh?: boolean;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

const TOP_SITES_COUNT = 10;
const TIME_SLICE_MINUTES = 20;

export default function AccountDeviceActivityLogs({ account, deviceReference, deviceGroupReference, hasUpperLevelControl = false, kiboshDeviceReference, refresh, isDomainBased = false, onCancel, onSave }: Props) {
  const expandedTableColumns: TableColumn<any>[] = [
    {
      title: 'Query Time',
      // dataIndex: 'queryTime',
      // key: 'queryTime',
      filterable: !isMobile,
      // width: 100,
      dataType: 'datetime',
      render: (_, text: any) => <>{moment(text).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}</>
    }
  ];
  const [accountKiboshDevices, setAccountKiboshDevices] = useState<AccountKiboshDevice[]>([]);
  const [accountDevices, setAccountDevices] = useState<AccountDevice[]>([]);
  const [activityDays, setActivityDays] = useState<string[]>([]);
  const [currentAccountKiboshDeviceReference, setCurrentAccountKiboshDeviceReference] = useState<string>("");
  const [currentAccountDeviceReference, setCurrentAccountDeviceReference] = useState<string>("");
  const [currentActivityDay, setCurrentActivityDay] = useState<string>("");
  // const [isActivityLogsEnabled, setIsActivityLogsEnabled] = useState(false);
  const [isCurrentDomainBased, setIsCurrentDomainBased] = useState(isDomainBased);
  const [loading, setLoading] = useState(false);
  const [loadingAccountKiboshDevices, setLoadingAccountKiboshDevices] = useState(false);
  // const [loadingIsActivityLogsEnabled, setLoadingIsActivityLogsEnabled] = useState(false);
  const [loadingAccountDevices, setLoadingAccountDevices] = useState(false);
  const [loadingActivityDays, setLoadingActivityDays] = useState(false);
  const [loadingAdminFilterSet, setLoadingAdminFilterSet] = useState(false);
  const [showChartModal, setShowChartModal] = useState(false);
  const [showTimelineChartModal, setShowTimelineChartModal] = useState(false);

  const [reload, setReload] = useState<boolean>(false);
  const [forceReload, setForceReload] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [radioMode, setRadioMode] = useState<string>("top_sites");
  const [chartDatasource, setChartDatasource] = useState<any[]>([]);
  const [timeChartData, setTimeChartData] = useState<any>(null);

  const [cachedData, setCachedData] = useCacheHook();

  let abortController = new AbortController();

  const columns: TableColumn<any>[] = [
    {
      title: radioMode === 'raw' ? "Domain" : "Site",
      dataIndex: 'domain',
      key: 'domain',
      filterable: !isMobile,
      render: (text: any) => <text data-wrap="overflow-wrap">{text}</text>
    },
    {
      title: 'Query Time',
      dataIndex: 'queryTime',
      key: 'queryTime',
      filterable: !isMobile,
      width: 140,
      dataType: 'datetime',
      render: (text: any) => <>{moment(text).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}</>
    }
  ];

  const groupedColumns: TableColumn<any>[] = [
    {
      title: radioMode === 'raw' ? "Domain" : "Site",
      dataIndex: 'domain',
      key: 'domain',
      filterable: !isMobile,
      render: (text: any) => <text data-wrap="overflow-wrap">{text}</text>
    },
    {
      title: isMobile ? '#' : 'Count',
      dataIndex: 'count',
      key: 'count',
      filterable: !isMobile,
      width: isMobile ? 45 : 100
    },
    {
      title: isMobile ? '%' : 'Percent',
      dataIndex: 'percent',
      key: 'percent',
      filterable: !isMobile,
      width: isMobile ? 45 : 100,
      render: (text: any) => <>{`${text}%`}</>
    }
  ];

  useEffect(() => {
    if (reload) {
      setReload(false)
    };
  }, [reload]);

  useEffect(() => {
    if (forceReload) {
      setForceReload(false);
      setReload(true);
    }
  }, [forceReload]);

  useEffect(() => {
    if (refresh) {
      if (!kiboshDeviceReference) {
        getAccountKiboshDevicesAsync();
      } else if (!deviceReference) {
        getAccountDevicesAsync();
      }
      else {
        // getDeviceActivityEnableStateAsync();
        getDeviceActivityDaysAsync();
      }
    }
  }, [refresh]);

  useEffect(() => {
    if (activityDays.length > 0)
      onLogDaysChange(activityDays[0]);
  }, [activityDays])

  useEffect(() => {
    setReload(true);
  }, [radioMode])

  const getAccountDevicesAsync = async (routerRef?: string) => {
    setAccountDevices(await getAccountDevices(routerRef, abortController.signal));
  }

  const getAccountDevices = async (routerRef?: string, abortSignal?: AbortSignal) => {
    setLoadingAccountDevices(true);
    const response = await apiCall(DashApi.getClientDevices(kiboshDeviceReference || currentAccountKiboshDeviceReference || routerRef!, abortSignal));
    setLoadingAccountDevices(false);

    let data = response.success ? AccountDevice.toArrayOfClass(response.data?.value || []) : [];

    if (deviceGroupReference) {
      data = data.filter(x => x.groupId === deviceGroupReference);
    }

    return deviceGroupReference ? data : data.map((x: any) => {
      return {
        ...x,
        sorter: x.groupId ? Number(x.groupId) : Infinity
      }
    }).sort((a: any, b: any) => a.sorter - b.sorter);
  };

  const getAccountKiboshDevicesAsync = async () => {
    setAccountKiboshDevices(await getAccountKiboshDevices({}, abortController.signal));
  }

  const getAccountKiboshDevices = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
    setLoadingAccountKiboshDevices(true);
    const response = await apiCall(AccountKiboshDeviceApi.getAccountKiboshDevices(account?.accountId!, request, abortSignal));
    setLoadingAccountKiboshDevices(false);

    return response.success ? AccountKiboshDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  // const getDeviceActivityEnableStateAsync = async (deviceRef?: string) => {
  //   const data = await getDeviceActivityLogEnableState(deviceRef, abortController.signal);
  //   setIsActivityLogsEnabled(data);
  //   if (data) getDeviceActivityDaysAsync(deviceRef);
  // }

  // const getDeviceActivityLogEnableState = async (deviceRef?: string, abortSignal?: AbortSignal) => {
  //   setLoadingIsActivityLogsEnabled(true);
  //   const response = await apiCall(DashApi.getDeviceActivityLogEnableState(kiboshDeviceReference || currentAccountKiboshDeviceReference, deviceRef || deviceReference || currentAccountDeviceReference, abortSignal));
  //   setLoadingIsActivityLogsEnabled(false);

  //   return response.success ? response.data?.value : false;
  // };

  const getDeviceActivityDaysAsync = async (deviceRef?: string) => {
    setActivityDays(await getDeviceActivityDays(deviceRef, abortController.signal));
  }

  const getDeviceActivityDays = async (deviceRef?: string, abortSignal?: AbortSignal) => {
    setLoadingActivityDays(true);
    const response = await apiCall(DashApi.getDeviceActivityDays(kiboshDeviceReference || currentAccountKiboshDeviceReference, deviceRef || deviceReference || currentAccountDeviceReference, abortSignal));
    setLoadingActivityDays(false);

    return response.success ? response.data?.value || [] : [];
  };

  const getKiboshDeviceFilterSets = async (abortSignal?: AbortSignal) => {
    if (cachedData['KiboshDeviceFilterSets'] != null && cachedData['KiboshDeviceFilterSets'].length > 0) return cachedData['KiboshDeviceFilterSets'];

    setLoadingAdminFilterSet(true);
    const response = await apiCall(DashApi.getKiboshDeviceFilterSets(kiboshDeviceReference || currentAccountKiboshDeviceReference, abortSignal));
    setCachedData('KiboshDeviceFilterSets', AdminFilterSet.toArrayOfClass(response.data?.value || []).sort((a: AdminFilterSet, b: AdminFilterSet) => (a.displayOrder || 0) - (b.displayOrder || 0)));
    setLoadingAdminFilterSet(false);

    return response.success ? AdminFilterSet.toArrayOfClass(response.data?.value || []) : [];
  };

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    if (!currentActivityDay)
      return [];

    setLoading(true);
    let response = null;
    if (isCurrentDomainBased) {
      response = await apiCall(DashApi.getDeviceActivitiesDomainBased(kiboshDeviceReference || currentAccountKiboshDeviceReference, deviceReference || currentAccountDeviceReference, radioMode, currentActivityDay, abortSignal));
    } else {
      response = await apiCall(DashApi.getDeviceActivities(kiboshDeviceReference || currentAccountKiboshDeviceReference, deviceReference || currentAccountDeviceReference, radioMode, currentActivityDay, abortSignal));
    }
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    let data = response.success ? response.data?.value : [];

    if (radioMode === "top_sites") {
      const adminFilterSets: AdminFilterSet[] = await getKiboshDeviceFilterSets(abortSignal);
      data = data.map((x: any) => {
        return {
          ...x,
          domain: adminFilterSets.find(filter => filter.id === x.site)?.name
        }
      }).map((x: any) => {
        return {
          ...x,
          domain: x.domain?.replace("Safe Mode", "")
        }
      })
    } else if (radioMode === "logs") {
      data = data.map((x: any) => {
        return {
          ...x,
          domain: x.top_domain
        }})
    }

    // if (radioMode === "logs") {
    //   data = data.sort((a: any, b: any) => b.percent - a.percent).slice(0, TOP_SITES_COUNT)
    // }

    if (isCurrentDomainBased
      // || radioMode === "logs"
    ) {
      const returnData = response.success ? AccountDeviceActivityFrequencyLog.toArrayOfClass(data) : [];
      setChartDatasource(returnData.sort((a: any, b: any) => b.percent - a.percent).slice(0, TOP_SITES_COUNT));
      return returnData;
    } else {
      return response.success ? AccountDeviceActivityLog.toArrayOfClass(data) : [];
    }
  };

  const onAccountKiboshDeviceChange = (kiboshDeviceReference: any) => {
    setCurrentAccountKiboshDeviceReference(kiboshDeviceReference);
    getAccountDevicesAsync(kiboshDeviceReference);
  }

  const onAccountDeviceChange = (deviceReference: any) => {
    setCurrentAccountDeviceReference(deviceReference);
    // getDeviceActivityEnableStateAsync(deviceReference);
    getDeviceActivityDaysAsync(deviceReference);
    // if (isActivityLogsEnabled) {
    //   getDeviceActivityDaysAsync(deviceReference);
    // }
  }

  const onLogDaysChange = (logDay: any) => {
    setCurrentActivityDay(logDay);
    setReload(true);
  }

  // const onActivityLogsEnabledCheckboxChange = async (event: any) => {
  //   setLoadingIsActivityLogsEnabled(true);
  //   const response = await apiCall(DashApi.updateDeviceActivityLogEnableState(kiboshDeviceReference || currentAccountKiboshDeviceReference, deviceReference || currentAccountDeviceReference, event.target?.checked, abortController.signal));

  //   if (response.success) {
  //     message.success("Activity Logs enabled state changed successfully.");
  //     getDeviceActivityEnableStateAsync();
  //   } else {
  //     message.error(response.error?.value);
  //     setLoadingIsActivityLogsEnabled(false);
  //   }
  // }

  const onDomainBasedCheckboxChange = async (event: any) => {
    setIsCurrentDomainBased(!isCurrentDomainBased);
    setForceReload(true);
  }

  const onTimeLineChartCloseClick = () => {
    setShowTimelineChartModal(false);
    setTimeChartData(null);
  }

  return (
    <>
      {loading && <Loader />}
      <view>
        <group data-space="15" data-border="" data-gap="5">
          <group data-width="auto" data-gap="15">
            {(!kiboshDeviceReference || !deviceReference) && (
              <>
                {!kiboshDeviceReference && (
                  <>
                    <Select
                      autoComplete=""
                      label="Kibosh Device"
                      labelPosition="left"
                      dataLength="320"
                      onChange={onAccountKiboshDeviceChange}
                      loading={loadingAccountKiboshDevices}
                      allowSearch={true}
                      options={
                        accountKiboshDevices?.map((item) => {
                          return {
                            text: `${item.kiboshDeviceName!} (${item.kiboshDeviceReference!})`,
                            value: item.kiboshDeviceReference!,
                          };
                        }) || []
                      }
                      button={
                        <Button
                          material
                          icon="refresh"
                          onClick={getAccountKiboshDevicesAsync}
                        />
                      }
                    />
                  </>
                )}
                {!deviceReference && (
                  <>
                    <Select
                      autoComplete=""
                      disabled={
                        loadingAccountDevices ||
                        !(
                          kiboshDeviceReference ||
                          currentAccountKiboshDeviceReference
                        )
                      }
                      label="Device"
                      labelPosition="left"
                      dataLength="350"
                      onChange={onAccountDeviceChange}
                      loading={loadingAccountDevices}
                      allowSearch={true}
                      options={
                        accountDevices?.map((item) => {
                          return {
                            text: `${item.name!} ${item.ip ? '(' + item.ip + ')' : ''}`,
                            value: item.id!,
                          };
                        }) || []
                      }
                      button={
                        <Button
                          material
                          icon="refresh"
                          onClick={getAccountDevicesAsync}
                        />
                      }
                    />
                  </>
                )}
              </>
            )}
            {/*!deviceGroupReference &&
              <>
                {loadingIsActivityLogsEnabled && <Loader />}
                <Checkbox
                  checked={isActivityLogsEnabled}
                  disabled={
                    loadingIsActivityLogsEnabled ||
                    !(deviceReference || currentAccountDeviceReference) || hasUpperLevelControl
                  }
                  label="Activity Logs Enabled"
                  minimal
                  dataLength="auto"
                  onChange={onActivityLogsEnabledCheckboxChange}
                />
              </>
            */}
          </group>
          {
          // (isActivityLogsEnabled || !!deviceGroupReference) &&
          (
            <group data-width="auto" data-gap="15" data-align="center">
              {/* <separator vertical="" data-height="20" data-adaptive="desktop"></separator> */}
              <Select
                value={currentActivityDay} //?
                disabled={!deviceReference && !currentAccountDeviceReference}
                autoComplete=""
                label="Display Logs For"
                labelPosition="left"
                dataLength="350"
                onChange={onLogDaysChange}
                loading={loadingActivityDays}
                allowSearch={true}
                options={activityDays || []}
                button={
                  <Button
                    disabled={
                      !deviceReference && !currentAccountDeviceReference
                    }
                    material
                    icon="refresh"
                    onClick={() => getDeviceActivityDaysAsync()}
                  />
                }
              />
            </group>
          )}
        </group>

        {
        // (isActivityLogsEnabled || !!deviceGroupReference) &&
        (
          <>
            <view className="table_cont" data-background="none">
              <group
                data-space="15"
                data-gap="20"
                data-border=""
                data-background="highlight"
              >
                <group>

                  <OptionBar autosize animate>
                    <Radio
                      radioType={RadioType.Button}
                      radioValue="top_sites"
                      label="Top Sites"
                      dataLength="auto"
                      value={radioMode}
                      onClick={(e) => setRadioMode("top_sites")}
                    />
                    <Radio
                      radioType={RadioType.Button}
                      radioValue="logs"
                      label="Logs"
                      dataLength="auto"
                      value={radioMode}
                      onClick={(e) => setRadioMode("logs")}
                    />
                    <Radio
                      radioType={RadioType.Button}
                      radioValue="raw"
                      label="Raw Logs"
                      dataLength="auto"
                      value={radioMode}
                      onClick={(e) => setRadioMode("raw")}
                    />
                  </OptionBar>
                  {
                    !!chartDatasource.length && radioMode === "top_sites" && isCurrentDomainBased &&
                    <Button
                      data-position="right"
                      material
                      icon="leaderboard"
                      text="Chart"
                      primary
                      onClick={() => setShowChartModal(true)}
                    />
                  }
                </group>
                <Checkbox
                  checked={isCurrentDomainBased}
                  label={isCurrentDomainBased ? "Single view domain" : "Group view domain"}
                  minimal
                  dataLength="auto"
                  onChange={onDomainBasedCheckboxChange}
                />
                <Button
                  data-position="right"
                  material
                  icon="refresh"
                  text="Refresh"
                  outline
                  onClick={() => setReload(true)}
                />
              </group>
              {/* {radioMode === "logs" && (
                <group
                  data-type="grid"
                  data-gap="10"
                  data-space="15"
                  data-grid-template="300"
                  data-background="highlight"
                  data-border=""
                >
                  <DashboardWidget
                    title="Top Sites"
                    height=""
                    childSpace="0"
                    // headerButtonsContainer={
                    //   <>
                    //     <Tooltip title="Maximize">
                    //       <div
                    //         className="button micro"
                    //         data-position="right"
                    //         onClick={() => setOpenDownloadChartInModal(true)}
                    //       >
                    //         <icon>fullscreen</icon>
                    //       </div>
                    //     </Tooltip>
                    //   </>
                    // }
                  >
                    <CustomChart
                      title="Total"
                      options={{
                        chart: {
                          height: 200,
                          type: 'pie',
                        },
                        plotOptions: {
                          pie: {
                            customScale: 0.8,
                            size: 200
                          }
                        },
                        grid: {
                          row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                          },
                        },
                        tooltip: {
                          y: {
                            formatter: function (val: any) {
                              return `${Number(val).toFixed(2)} MB`;
                            }
                          }
                        },
                        labels: dataSource.map(x => x.domain),
                        legend: {
                          position: 'bottom'
                        }
                      }}
                      series={dataSource.map(x => x.percent)}
                      type="pie"
                      width="100%"
                      // openInModal={openTotalChartInModal}
                      // onClose={() => setOpenTotalChartInModal(false)}
                    />
                  </DashboardWidget>
                </group>
              )} */}
              {!forceReload &&
                <DataTable
                  tableLayout={"auto"}
                  ellipsis={true}
                  columns={isCurrentDomainBased ? groupedColumns : columns}
                  bordered={true}
                  customPagination={true}
                  reload={reload}
                  dataPagination={true}
                  getPageAsync={getPage}
                  loading={loading || (radioMode !== "raw" && loadingAdminFilterSet)}
                  showTotal={true}
                  size="small"
                  rowKey={record => isCurrentDomainBased ? `${record.domain}` : `${record.queryTime}`}
                  totalRecords={totalRecords}
                  expandable={isCurrentDomainBased ? {
                    expandedRowRender: (record) => (
                      <group>
                        <view className="table_cont" data-background="none" data-min-height='30'>
                          <group data-weight="600">
                            <text data-space="10">Query Time</text>
                            <separator data-horizontal="" data-margin="none"></separator>
                          </group>
                          <DataTable
                            showHeader={false}
                            tableLayout={"auto"}
                            ellipsis={true}
                            columns={expandedTableColumns}
                            bordered={true}
                            dataSource={record.queryTimes}
                            size="small"
                            rootClassName="no-margin-table"
                          />
                        </view>
                      </group>

                    ),
                  } : undefined}
                />
              }
            </view>
          </>
        )}
      </view>
      {
        showChartModal &&
        <CustomChartModal
          adaptive={true}
          options={{
            chart: {
              height: 200,
              type: 'pie',
              events: {
                dataPointSelection: (event: any, chartContext: any, config: any) => {
                  const selectedIndex = config.dataPointIndex;

                  const data = chartDatasource[selectedIndex];

                  let timeSlices = [];

                  const timeArray = data.queryTimes.reverse();

                  let timeSliceItem = null;
                  for (let index = 0; index < timeArray.length; index++) {
                    const element = timeArray[index];

                    if (!timeSliceItem) {
                      timeSliceItem = {
                        start: moment(element).toISOString(),
                        end: ""
                      }
                    }

                    if (timeSliceItem && moment(timeArray[index + 1]).diff(moment(element), "minutes") > TIME_SLICE_MINUTES) {
                      if (moment(element).toISOString() === timeSliceItem.start) {
                        timeSliceItem.end = moment(element).add(TIME_SLICE_MINUTES - 1).toISOString()
                      } else {
                        timeSliceItem.end = moment(element).toISOString()
                      }
                      timeSlices.push(timeSliceItem);
                      timeSliceItem = null;
                    }

                    if (index === timeArray.length - 1 && timeSliceItem && !timeSliceItem.end) {
                      if (moment(element).toISOString() === timeSliceItem.start) {
                        timeSliceItem.end = moment(element).add(TIME_SLICE_MINUTES - 1).toISOString()
                      } else {
                        timeSliceItem.end = moment(element).toISOString()
                      }
                      timeSlices.push(timeSliceItem);
                      timeSliceItem = null;
                    }
                  }

                  setTimeChartData({...data, timeSlices });
                  setShowTimelineChartModal(true);
                  // You can add any additional actions here
                }
              }
            },
            plotOptions: {
              pie: {
                customScale: 0.8,
                size: 200
              }
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            tooltip: {
              y: {
                formatter: function (val: any) {
                  return `${Number(val).toFixed(2)} MB`;
                }
              }
            },
            labels: chartDatasource.map(x => x.domain),
            legend: {
              position: 'bottom'
            }
          }}
          series={chartDatasource.map(x => x.percent)}
          type="pie"
          title="Top Sites"
          open={showChartModal}
          onClose={() => setShowChartModal(false)}/>
      }
      {
        showTimelineChartModal && timeChartData &&
        <CustomChartModal
          adaptive={true}
          options={{
            chart: {
              height: 350,
              type: 'rangeBar'
            },
            plotOptions: {
              bar: {
                horizontal: true,
                isDumbbell: true,
                dumbbellColors: [['#EC7D31', '#36BDCB']]
              }
            },
            colors: ['#EC7D31', '#36BDCB'],
            fill: {
              type: 'gradient',
              gradient: {
                gradientToColors: ['#36BDCB'],
                inverseColors: false,
                stops: [0, 100]
              }
            },
            grid: {
              xaxis: {
                lines: {
                  show: true
                }
              },
              yaxis: {
                lines: {
                  show: false
                }
              }
            },
            xaxis: {
              type: 'datetime',
              tickAmount: 24, // This will divide the axis into 24 hourly ticks.
              labels: {
                format: 'HH:mm' // Formats the label to show hours and minutes
              },
              min: new Date(currentActivityDay + 'T00:00:00').getTime(), // Start time
              max: new Date(currentActivityDay + 'T23:59:59').getTime(), // End time for a day
            }
          }}
          series={[
            {
              data: timeChartData.timeSlices.map((item: any) => {
                return {
                  x: timeChartData.domain,
                  y:
                  [
                    new Date(item.start).getTime(),
                    new Date(item.end).getTime()
                  ]
                }
              })
              //  [
              //   {
              //     x: timeChartData.domain,
              //     // y: timeChartData.queryTimes.reverse().map((x: any) => new Date(x).getTime())
              //     // y:
              //     // [
              //     //   timeChartData.queryTimes.reverse().map((x: any) => new Date(x).getTime())[0],
              //     //   timeChartData.queryTimes.reverse().map((x: any) => new Date(x).getTime())[0]
              //     // ]
              //     y:
              //     [
              //       new Date("2024-11-12T02:34:34-07:00").getTime(),
              //       new Date("2024-11-12T03:34:34-07:00").getTime()
              //     ]
              //   },
              //   {
              //     x: timeChartData.domain,
              //     // y: timeChartData.queryTimes.reverse().map((x: any) => new Date(x).getTime())
              //     // y:
              //     // [
              //     //   timeChartData.queryTimes.reverse().map((x: any) => new Date(x).getTime())[0],
              //     //   timeChartData.queryTimes.reverse().map((x: any) => new Date(x).getTime())[0]
              //     // ]
              //     y:
              //     [
              //       new Date("2024-11-12T07:34:34-07:00").getTime(),
              //       new Date("2024-11-12T08:34:34-07:00").getTime(),
              //     ]
              //   }
              // ]
            }
          ]}
          type="rangeBar"
          title={`Timeline: ${timeChartData.domain}`}
          open={showTimelineChartModal}
          onClose={onTimeLineChartCloseClick}/>
      }
    </>
  );
}
