// 3rd-party modules
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

// models
import { ApiResponse } from '../models/response';

// defines
import Config from '../config';
import { MESSAGES } from './defines';

export const getGlobalOptions = (abortSignal?: AbortSignal): AxiosRequestConfig => {
  return {
    headers: {
      "Content-Type": "application/json"
    },
    timeout: Config.requestTimeout,
    signal: abortSignal,
  };
};

export const getBaseUrl = (): string => {
  return process.env.NODE_ENV === "development"
    ? '/api'
    : Config.apiUrl;
};

export const apiCall = async (fn: Promise<AxiosResponse>): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse = await fn;
    const result = response?.data;

    return new ApiResponse(result);
  } catch (error: any) {
    if (axios.isAxiosError(error))  {
      const err = error as AxiosError;

      if (axios.isCancel({ ...err }))
        return ApiResponse.setError(err, MESSAGES.MANUAL_REQUEST_CANCELLATION, 499); // client closed request
      else if (err.response && err.response.data?.hasOwnProperty('success'))
        return new ApiResponse(err.response.data);
      else // no response
        return ApiResponse.setError(err.message, err.response?.statusText, err.response?.status, err.code);
    } else {
      return ApiResponse.setError(error);
    }
  }
};

export const sendRequest = async (url: string, method: string, data?: any|null, config?: AxiosRequestConfig|null, abortSignal?: AbortSignal) => {
  const options: AxiosRequestConfig = {
    ...getGlobalOptions(abortSignal),
    ...config,
    url,
    method,
    data
  };

  return await axios(options);
};
