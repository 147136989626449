// project modules
import { getBaseUrl, sendRequest } from '../helpers/apiHelper';

// models
import { accountRegisterViewModel, loginViewModel } from "../models/types/auth";

export const confirmEmail = async (email: string, token: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/confirmationEmail/${email}`, 'post', token, null, abortSignal);
};

export const loginUser = async (user: loginViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/Login`, 'post', user, null, abortSignal);
};

export const loginUserByRefreshToken = async (user: loginViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/LoginByRefreshToken`, 'post', user, null, abortSignal);
};

export const register = async (user: accountRegisterViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/register`, 'post', user, null, abortSignal);
};

export const forgotPassword = async (data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/forgotPassword`, 'post', data, null, abortSignal);
};

export const getAccount = async (abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/User`, 'get', null, null, abortSignal);
};

export const resendEmailConfirmation = async (data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/emailConfirmation`, 'post', data, null, abortSignal);
};

export const resetPassword = async (data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/resetPassword`, 'post', data, null, abortSignal);
};
