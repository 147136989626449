// 3rd-party modules
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface CacheState {
  cahcedData: any;
}

const initialState: CacheState = {
  cahcedData: {},
};

export const cacheSlice = createSlice({
  name: "cache",
  initialState,
  reducers: {
    setCache: (state, action: PayloadAction<{ key: string, data: any }>) => {
      state.cahcedData[action.payload.key] = action.payload.data;
    }
  },
});

// action creators
export const { setCache } = cacheSlice.actions;

export default cacheSlice.reducer;
