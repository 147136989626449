import React, {useState} from 'react';
import Popup from '../../shared/popup';
import Button from '../button';

const ConfirmationPopup: React.FC<{
  title?: string;
  message?: string;
  positiveCallback: () => void;
  positiveButtonText?: string;
  negativeCallback?: () => void;
  negativeButtonText?: string;
  showButton?: boolean;
}> = ({
  title = "",
  message = "",
  positiveCallback,
  positiveButtonText = "",
  negativeCallback,
  negativeButtonText = "",
  showButton = true
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      { showButton &&
        <Button large outline onClick={() => setIsOpen(true)}>
          <text> Open Confirmation Popup</text>
        </Button>
      }
      {((showButton && isOpen ) || !showButton ) && (
        <Popup noHeader onClose={() => showButton ? setIsOpen(false) : negativeCallback?.()}>
          <group>
            <group data-space="30" data-direction="column">
              <text
                data-wrap="wrap"
                data-accent=""
                data-text-size="medium"
                data-wrap-line=""
              >
                {title || "Please Confirm"}
              </text>
              <text data-wrap="wrap" data-text-size="medium" data-wrap-line="">
                {message || "Are you sure?"}
              </text>
            </group>
            <group
              data-space="30"
              data-gap="10"
              data-background="highlight"
              data-type="grid"
            >
              <button
                type="button"
                effect="material"
                className="button primary large"
                onClick={() => {
                  positiveCallback?.();
                  setIsOpen(false);
                }}
              >
                <text>{positiveButtonText || "Yes"}</text>
              </button>
              <button
                type="button"
                effect="material"
                className="button outline large"
                onClick={() => {
                  negativeCallback?.();
                  setIsOpen(false);
                }}
              >
                <text>{negativeButtonText || "No"}</text>
              </button>
            </group>
          </group>
        </Popup>
      )}
    </>
  );
};
export default ConfirmationPopup;
