// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToInstance } from 'class-transformer';

export class AccountDevice {
  @Expose()
  public id?: string;
  @Expose()
  public activeConnections?: number | null = null;
  @Expose()
  public blockedAt?: string;
  @Expose()
  public blockedUntil?: string | null = null;
  @Expose()
  public connectionStatus?: string | null = null;
  @Expose()
  public name?: string | null = null;
  @Expose()
  public deviceType?: string | null = null;
  @Expose()
  public excludedUntil?: string | null = null;
  @Expose()
  public isBlocked?: boolean;
  @Expose()
  public isDhcp?: boolean;
  @Expose()
  public isVpn?: boolean;
  // @Expose()
  // public detectedMalware?: any[] = [];
  @Expose()
  public isExcluded?: boolean;
  @Expose()
  public detectedMalware?: AccountDeviceMalwareLog[] = [];
  @Expose()
  public ip?: string | null = null;
  @Expose()
  public isWifi?: boolean;
  @Expose()
  public pingMs?: number | null = null;
  @Expose()
  public staticIp?: string | null = null;
  @Expose()
  public wifiBand?: string | null = null;
  @Expose()
  public wifiSignal?: number | null = null;
  @Expose()
  public numSchedules?: number | null = null;
  @Expose()
  public loggingEnabled?: number | null = null;
  @Expose()
  public groupId?: string | null = null;
  @Expose()
  public numSetFiltersWithSchedule?: number | null = null;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(AccountDevice, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(AccountDevice, camelcaseKeys(data, { deep: true }) as AccountDevice[], { excludeExtraneousValues: true });
  }
}

export class AccountDeviceActivityLog {
  @Expose()
  public domain?: string;
  @Expose()
  public queryTime?: Date;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(AccountDeviceActivityLog, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(AccountDeviceActivityLog, camelcaseKeys(data, { deep: true }) as AccountDeviceActivityLog[], { excludeExtraneousValues: true });
  }
}

export class AccountDeviceActivityFrequencyLog {
  @Expose()
  public domain?: string;
  @Expose()
  public queryTimes?: Date[];
  @Expose()
  public percent?: number;
  @Expose()
  public count?: number;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(AccountDeviceActivityFrequencyLog, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(AccountDeviceActivityFrequencyLog, camelcaseKeys(data, { deep: true }) as AccountDeviceActivityFrequencyLog[], { excludeExtraneousValues: true });
  }
}

export class AccountDeviceBandwidthLog {
  @Expose()
  public clientMac?: string;
  @Expose()
  public intervalDownloadBandwidth?: string;
  @Expose()
  public intervalUploadBandwidth?: string;
  @Expose()
  public intervalEnd?: Date;
  @Expose()
  public name?: string;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(AccountDeviceBandwidthLog, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(AccountDeviceBandwidthLog, camelcaseKeys(data, { deep: true }) as AccountDeviceBandwidthLog[], { excludeExtraneousValues: true });
  }
}

// export class AccountDeviceSchedule {
//   @Expose()
//   public id?: string;
//   @Expose()
//   public fromAmPm?: string;
//   @Expose()
//   public fromDay?: string;
//   @Expose()
//   public fromHour?: string;
//   @Expose()
//   public fromMinute?: string;
//   @Expose()
//   public enabled?: boolean;
//   @Expose()
//   public toAmPm?: string;
//   @Expose()
//   public toDay?: string;
//   @Expose()
//   public toHour?: string;
//   @Expose()
//   public toMinute?: string;
//   @Expose()
//   public repeats?: string;

//   public static toClass(data: object) {
//     //@ts-ignore
//     return plainToInstance(AccountDeviceSchedule, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
//   }

//   public static toArrayOfClass(data: object[]) {
//     //@ts-ignore
//     return plainToInstance(AccountDeviceSchedule, camelcaseKeys(data, { deep: true }) as AccountDeviceSchedule[], { excludeExtraneousValues: true });
//   }
// }

export class AccountDeviceSchedule {
  public id?: string;
  public fromDay?: string;
  public fromTime?: string | null;
  public enabled?: boolean;
  public toDay?: string;
  public toTime?: string | null;
  public repeats?: string;
  public fromHour?: number | null;
  public fromMinute?: number | null;
  public toHour?: number | null;
  public toMinute?: number | null;
  public activeDays?: string;

  constructor(rawData?: any) {
    if (rawData) {
      this.id = rawData.id || null;
      this.enabled = rawData.enabled || null;
      this.repeats = rawData.repeats || null;
      this.fromDay = rawData.from_day || null;
      this.fromTime = rawData.from_hour && rawData.from_minute && rawData.from_am_pm ? `${rawData.from_hour}:${rawData.from_minute} ${rawData.from_am_pm}`: null;
      this.toDay = rawData.to_day || null;
      this.toTime = rawData.to_hour && rawData.to_minute && rawData.to_am_pm ? `${rawData.to_hour}:${rawData.to_minute} ${rawData.to_am_pm}`: null;
      this.fromHour = rawData.from_hour && rawData.from_am_pm ? ( rawData.from_am_pm === "pm" ? (rawData.from_hour + 12) % 24 : rawData.from_hour) : null;
      // eslint-disable-next-line eqeqeq
      this.toHour = rawData.to_hour && rawData.to_am_pm ? ( rawData.to_am_pm === "pm" ? (rawData.to_hour == 12 ? rawData.to_hour : rawData.to_hour + 12) : rawData.to_hour == 12 ? 0 : rawData.to_hour) : null;
      this.fromMinute = rawData.from_minute || null;
      this.toMinute = rawData.to_minute || null;
      this.activeDays = rawData.active_days || null;
    }
  }

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(AccountDeviceSchedule, data, { excludeExtraneousValues: true });
  }

  static toArrayOfClass(data: any[]): AccountDeviceSchedule[] {
    const accountDeviceSchedules: AccountDeviceSchedule[] = [];

    if (data != null) {
      for (const accountDeviceSchedule of data) {
        accountDeviceSchedules.push(new AccountDeviceSchedule(accountDeviceSchedule));
      }
    }

    return accountDeviceSchedules;
  }
}

export class AccountDeviceMalwareLog {
  @Expose()
  public domain?: string;
  @Expose()
  public lastQueried?: Date;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(AccountDeviceMalwareLog, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(AccountDeviceMalwareLog, camelcaseKeys(data, { deep: true }) as AccountDeviceMalwareLog[], { excludeExtraneousValues: true });
  }
}
