// project modules
import { store } from '../stores';

// models
import { User } from '../models/auth';

export const getAuthToken = (): string => {
  return store.getState().user?.authToken || '';
}

export const getUserId = (): number | null => {
  return store.getState().user?.currentUser?.accountId || null;
}

export const checkUserRole = (user: User, role: string) => {
  if (!user)
    return false;

  if (role && role.includes("!"))
      return user.roles && !user.roles.includes(role.replace("!", ""));
  else
      return user.roles && user.roles.includes(role);
};

// check whether user has specified roles array
// note: items in roles array consider as 'or' for example ["Broker", "WEBA"] = broker or web-admin
// to have 'and' instead of 'or' use 2nd layer of arrays for example ["Broker", ["WEBA", "???"]] = broker of web-admin and ???
// to have 'not' use ! for example ["!Broker"] = not broker
export const checkUserAccess = (user: User, roles: string[]) => {
  const accessLog = [];

  if (roles.includes("*")) return true;

  for (const role of roles) {
      if (typeof (role) === "string") {
          accessLog.push(checkUserRole(user, role));
      } else if (Array.isArray(role)) {
          let access = true;

          for (const r of role as string[]) {
              if (!checkUserRole(user, r)) access = false;
          }

          accessLog.push(access);
      }
  }

  return accessLog.includes(true);
};
