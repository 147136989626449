// 3rd-party modules
import Tooltip from "antd/lib/tooltip";
import { useEffect, useState } from "react";

// project modules
import AccountKiboshDeviceMenuModal from "../kibosh-device/AccountKiboshDeviceMenuModal";
import AccountVpnModal from "./AccountVpnModal";
import Button from "../../shared/button";
import ListView from "../../shared/list/listView";
import Loader from "../../shared/loader";
import { apiCall } from "../../../helpers/apiHelper";
import { getStringFirstLetters } from "../../../helpers/objectHelper";
import { ListViewType } from "../../shared/list/useListHook";
import { Text } from "../../shared/text";
import { getBase64ImageUrl } from "../../../helpers";

// apis
// import * as AuthApi from "../../apis/authApi"
import * as AccountSubscriptionApi from '../../../apis/accountSubscriptionApi';
import * as CommonValueApi from '../../../apis/commonValueApi';
import * as DashApi from '../../../apis/dashApi';

// models
import { Account } from "../../../models/account";
import { AccountKiboshVpnDevice } from "../../../models/accountKiboshVpnDevice";
import { AccountKiboshDevice } from "../../../models/accountKiboshDevice";
import { DataSourceRequest } from "../../../models/dataSourceRequest";
import { message } from "antd";
import { CommonValue } from "../../../models/commonValue";
import AccountVpnDevicePickerModal from "./AccountVpnDevicePickerModal";

type Props = {
  account?: Account;
  accountKiboshDevice?: AccountKiboshDevice;
  refresh?: boolean;
  onCancel?: () => void;
  onMembersListChange?: () => void;
  onSave?: (account: Account) => void;
};

export default function AccountVpns({ account, refresh, accountKiboshDevice, onCancel, onSave, onMembersListChange }: Props) {
  const [accountKiboshVpnDevices, setAccountKiboshVpnDevices] = useState<AccountKiboshVpnDevice[]>([]);
  const [currentAccountKiboshVpnDevice, setCurrentAccountKiboshVpnDevice] = useState<AccountKiboshVpnDevice>(new AccountKiboshVpnDevice());
  const [vpnAssignedDevicesIds, setVpnAssignedDevicesIds] = useState<string[]>([]);
  // const [currentAccount, setCurrentAccount] = useState<Account>(account);
  // const [accountLoading, setAccountLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDevicesTypeList, setLoadingDevicesTypeList] = useState(false);
  const [loadingSubscribtion, setLoadingSubscribtion] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [showAccountKiboshVpnDeviceModal, setShowAccountKiboshVpnDeviceModal] = useState(false);
  const [showAccountKiboshVpnDeviceMenuModal, setShowAccountKiboshVpnDeviceMenuModal] = useState(false);
  const [showAccountKiboshVpnDeviceCreationModal, setShowAccountKiboshVpnDeviceCreationModal] = useState(false);
  let abortController = new AbortController();

  useEffect(() => {
    let abortController = new AbortController();

    setReload(true);

    return () => { abortController.abort(); }
  }, []);

  useEffect(() => {
    if (accountKiboshDevice?.accountKiboshDeviceId) {
      setReload(true);
    }
  }, [accountKiboshDevice]);

  useEffect(() => {
    if (reload) {
      getAccountKiboshVpnDevicesAsync();
      setReload(false);
    }
  }, [reload]);

  const getAccountKiboshVpnDevicesAsync = async () => {
    if (accountKiboshDevice?.accountKiboshDeviceId) {
      Promise.all([getAccountKiboshVpnDevices({}, abortController.signal), getKiboshDeviceActiveSubscription(), getDeviceTypes()]).then(
        (results: any) => {
          let vpnDevices = results[0];
          const activeSubscription = results[1];
          const deviceVpnType = results[2]?.filter((x: any) => x.value === "vpn");

          if (deviceVpnType.length) {
            vpnDevices = vpnDevices.map((x: any)=> {
              return {
                ...x,
                kiboshDeviceTypeId: deviceVpnType[0].commonValueId,
                kiboshDeviceType: deviceVpnType[0].valueCaption || deviceVpnType[0].value,
                kiboshDeviceReference: x.id,
                vpnType: x.vpnType,
                // kiboshDeviceName: `VPN ${x.id}`,
                accountKiboshDeviceId: x.id,
                vpnId: x.vpnId || x.id
              }
            })
          }

          setVpnAssignedDevicesIds(vpnDevices.filter((x: any) => x.associatedRouterClientId).map((x: any) => x.associatedRouterClientId));

          let availableVpns = 0;

          if (activeSubscription && activeSubscription?.profile) {
            const profile = JSON.parse(activeSubscription?.profile)
            if (profile) {
              availableVpns = profile.ProductMetadata?.VpnCount || 0
            }
          }

          if (vpnDevices.length < availableVpns) {
            const currentVpnsCount = vpnDevices.length;
            for (let index = 0; index < availableVpns - currentVpnsCount; index++) {
              const newData = new AccountKiboshVpnDevice();
              newData.kiboshDeviceReference = `new-${index}`
              // newData.kiboshDeviceName = `VPN ${index + 1}`
              vpnDevices.push(newData)
            }
          }

          vpnDevices = vpnDevices.map((x: any, index: any)=> {
            return {
              ...x,
              kiboshDeviceName: `VPN ${index + 1}`
            }
          })

          setAccountKiboshVpnDevices(vpnDevices);
        }
      );
    } else {
      setAccountKiboshVpnDevices(await getAccountKiboshVpnDevices({}, abortController.signal))
    }
  }

  const getAccountKiboshVpnDevices = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
    setLoading(true);
    const response = await apiCall(DashApi.getAccountVpns(null, accountKiboshDevice?.kiboshDeviceReference, abortController.signal));
    setLoading(false);

    return response.success ? AccountKiboshVpnDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getDeviceTypes = async (abortSignal?: AbortSignal) => {
    setLoadingDevicesTypeList(true);
    const response = await apiCall(CommonValueApi.getDeviceTypes(abortSignal));
    setLoadingDevicesTypeList(false);

    return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
  }

  const getKiboshDeviceActiveSubscription = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
    setLoadingSubscribtion(true);
    const response = await apiCall(AccountSubscriptionApi.getKiboshDeviceActiveSubscription(accountKiboshDevice!.kiboshDeviceReference!, abortController.signal));
    setLoadingSubscribtion(false);

    return response.success ? response.data?.value || [] : [];
  };

  const onAccountKiboshVpnDeviceSave = async (step: string = "") => {
    // const dataSource = await getAccountKiboshVpnDevicesAsync();
    // switch (step) {
    //   case "creation":
    //     onDevicesClick(dataSource[dataSource.length-1]);
    //     break;
    //   case "device-managment":
    //     setShowAccountKiboshVpnDeviceModal(false)
    //     onEditClick(dataSource[dataSource.length-1]);
    //     break;
    // }
    // if(onMembersListChange) onMembersListChange();
  }

  const onAssignToDeviceClick = async (accountKiboshVpnDevice: AccountKiboshVpnDevice, event?: any) => {
    if (event) {
      event.stopPropagation();
    }
    setCurrentAccountKiboshVpnDevice({ ...accountKiboshVpnDevice });
    setShowAccountKiboshVpnDeviceModal(true)
  }

  const onDetailsClick = (accountKiboshVpnDevice: AccountKiboshVpnDevice, event?: any) => {
    if (event) {
      event.stopPropagation();
    }
    setCurrentAccountKiboshVpnDevice({ ...accountKiboshVpnDevice, kiboshDeviceReference: accountKiboshDevice?.kiboshDeviceReference });
    setShowAccountKiboshVpnDeviceMenuModal(true);
  };

  const onDownloadConfigClick = async (accountKiboshVpnDevice: AccountKiboshVpnDevice, event?: any) => {
    if (event) {
      event.stopPropagation();
    }
    setLoading(true);
    const response = await apiCall(DashApi.getVpnConfig(
      accountKiboshVpnDevice.kiboshDeviceReference!,
      abortController.signal
    ));
    setLoading(false);

    if (response.success) {
      const blob = new Blob([response.data?.value], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      let extention = "";
      switch (accountKiboshVpnDevice.vpnType?.toLowerCase()) {
        case "ipsec":
          extention = ".mobileconfig"
          break;
        case "openvpn":
          extention = ".ovpn"
          break;
        default:
          break;
      }
      a.href = url;
      a.download = `${accountKiboshVpnDevice.kiboshDeviceName}-config${extention}`;

      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      message.error(response.error?.value);
    }
  };

  const BlockItem: React.FC<{ data: any }> = ({ data }) => (
    <group
      data-test=""
      data-justify="space-between"
      data-direction="column"
      data-contain=""
      data-border=""
      data-radius="10"
      data-background="context"
      data-cursor="pointer">
      {/* <group data-space="30" data-align="center" data-direction="column">
        <group data-ratio="1:1" data-shrink="no" data-contain="" data-length="100" data-radius="full" data-background="main" data-color="white">
          <picture>
            {data.icon ? <img src={data.icon} alt="" /> : <text data-text-size="xx-large" data-weight="700">{getStringFirstLetters(data.kiboshDeviceName || data.vpn_id)}</text>}
          </picture>
        </group>
      </group> */}
      <group data-gap="5" data-width="auto" data-align="center" data-justify="start" data-space="5">
        <icon data-color={!data.associatedRouterClientId ? "" : "main"} data-opacity={!data.associatedRouterClientId ? "50" : undefined} data-icon-size="48">vpn_lock</icon>
      </group>
      <group data-direction="column" data-contain="" data-gap="5" data-space="15">
        <Text
          data-weight="700"
          data-ellipsis=""
          data-text-align="center">
          {data.kiboshDeviceName || data.vpnId}
        </Text>
        <Text data-wrap="wrap" data-clamp="3" data-line="1.5" data->
          {data.name || "Available +"}
        </Text>
      </group>
      {/*<separator data-horizontal="" data-margin="none"></separator>*/}
      {/* { data.kiboshDeviceReference?.startsWith("new-") ?
        <group data-gap="5" data-space="10" data-align="center" data-justify="end" data-border="" data-position="bottom">
          <Button micro material icon="add" onClick={(e) => onCreateClick(data, e)} />
        </group>
      :
      } */}
        <group data-gap="5" data-space="10" data-align="center" data-justify="end" >
          <Button micro material icon="open_in_phone" onClick={(e) => onAssignToDeviceClick(data, e)} />
          <Button micro material icon="download" disabled={data.kiboshDeviceReference?.startsWith("new-")} onClick={(e) => onDownloadConfigClick(data, e)} />
          <separator data-vertical=""></separator>
          <Button micro material icon="arrow_outward" disabled={data.kiboshDeviceReference?.startsWith("new-") || !data.associatedRouterClientId} onClick={(e) => onDetailsClick(data, e)} />
            {/* {
              !!data.associatedRouterClientId &&
              <>
                <separator data-vertical=""></separator>
                <Button micro material icon="arrow_outward" onClick={(e) => onDetailsClick(data, e)} />
              </>
            } */}
        </group>
    </group>
  );

  const GridItem: React.FC<{ data: any }> = ({ data }) => (
    <group data-align="center" data-space="10" data-gap="10" data-wrap="no" data-background="highlight" >
      {/* <group data-ratio="1:1" data-shrink="no" data-contain="" data-length="50" data-radius="full" data-background="main" data-color="white">
        <picture>
        {data.icon ? <img src={getBase64ImageUrl(data.icon)} alt="" /> : <text data-text-size="large" data-weight="700">{getStringFirstLetters(data.kiboshDeviceName || data.vpn_id)}</text>}
        </picture>
      </group> */}
      <group data-gap="5" data-width="auto" data-align="center" data-justify="start" data-space="5">
        <icon data-color={!data.associatedRouterClientId ? "" : "main"} data-opacity={!data.associatedRouterClientId ? "50" : undefined} data-icon-size="48">vpn_lock</icon>
      </group>
      <group data-direction="column" data-contain="">
        <Text data-weight="700">{data.kiboshDeviceName || data.vpnId}</Text>
        <Text data-ellipsis="">{data.name || "Available +"}</Text>
      </group>
      {/* { data.kiboshDeviceReference?.startsWith("new-") ?
        <>
          <separator data-vertical=""></separator>
          <group
            data-align="center"
            data-wrap="no"
            data-position="right"
            data-width="auto"
          >
            <Button mini rounded onClick={(e) => onCreateClick(data, e)}>
              <icon>add</icon>
            </Button>
          </group>
        </>
      :
      } */}
        <group
          data-align="center"
          data-wrap="no"
          data-position="right"
          data-width="auto"
        >
          <Button mini rounded  onClick={(e) => onAssignToDeviceClick(data, e)}>
            <icon>open_in_phone</icon>
          </Button>
          <Button mini rounded disabled={data.kiboshDeviceReference?.startsWith("new-")} onClick={(e) => onDownloadConfigClick(data, e)}>
            <icon>download</icon>
          </Button>
          <separator data-vertical=""></separator>
          <Button mini rounded disabled={data.kiboshDeviceReference?.startsWith("new-") || !data.associatedRouterClientId} onClick={(e) => onDetailsClick(data, e)}>
            <icon>more_vert</icon>
          </Button>
          {/* {
            !!data.associatedRouterClientId &&
            <>
              <separator data-vertical=""></separator>
              <Button mini rounded onClick={(e) => onDetailsClick(data, e)}>
                <icon>more_vert</icon>
              </Button>
            </>
          } */}
        </group>
    </group>

  );

  return (
    <>
      {(loading || loadingSubscribtion || loadingDevicesTypeList) && <Loader />}
      <view data-vertical="">
        <view
          data-test=""
          data-scroll=""
          data-space="20"
          data-gap="30">
          <group data-gap="10" data-direction="column">
            <text data-weight="700" data-text-size="xx-large" data-wrap="wrap" data-color="main-dark">VPNs</text>
            <text data-wrap="wrap" data-length="400" data-line="1.5" data-light="">Secure your mobile devices on the go with comprehensive VPN management. Here, you can configure all aspects of your VPN setup, including adding, removing, modifying, and managing access settings.</text>
          </group>
          <group data-width="auto" data-position="left">
            <Tooltip title="Refresh" arrow={false}>
              <group data-width="auto">
                <Button
                  secondary
                  data-length="50"
                  data-height="50"
                  data-elevation="6"
                  rounded
                  icon="refresh"
                  disabled={!accountKiboshDevice?.kiboshDeviceReference}
                  onClick={() => setReload(true)}
                />
              </group>
            </Tooltip>
          </group>
          <ListView
            data-adaptive="desktop"
            dataSource={accountKiboshVpnDevices}
            view={ListViewType.Block}
            keyField="id"
            data-template="190"
            listProps={{ "data-gap": "10" }}
            itemComponent={BlockItem}
            onItemClick={(data) => data.associatedRouterClientId ? onDetailsClick(data) : onAssignToDeviceClick(data)}/>
          <group data-adaptive="mobile">
            <group data-contain="" data-radius="10" data-elevation="2">
              <ListView
                view={ListViewType.List}
                dataSource={accountKiboshVpnDevices}
                keyField="id"
                data-space="0"
                listProps={{ "data-gap": "1" }}
                itemComponent={GridItem}
                onItemClick={(data) => data.associatedRouterClientId ? onDetailsClick(data) : onAssignToDeviceClick(data)}/>
            </group>
          </group>
        </view>
      </view>
      {!!showAccountKiboshVpnDeviceMenuModal && (
        <AccountKiboshDeviceMenuModal
          open={showAccountKiboshVpnDeviceMenuModal}
          closeOnSave={true}
          account={account!}
          accountKiboshDevice={currentAccountKiboshVpnDevice}
          deviceReference={currentAccountKiboshVpnDevice.associatedRouterClientId}
          onClose={() => setShowAccountKiboshVpnDeviceMenuModal(false)}
        />
      )}
      {!!showAccountKiboshVpnDeviceCreationModal && (
        <AccountVpnModal
          open={showAccountKiboshVpnDeviceCreationModal}
          accountKiboshVpnDevice={currentAccountKiboshVpnDevice}
          kiboshDeviceReference={accountKiboshDevice!.kiboshDeviceReference!}
          account={account!}
          closeOnSave={true}
          onClose={() => setShowAccountKiboshVpnDeviceCreationModal(false)}
          onSave={(e) => onAccountKiboshVpnDeviceSave("creation")}
        />
      )}
      {!!showAccountKiboshVpnDeviceModal &&
        <AccountVpnDevicePickerModal
          open={showAccountKiboshVpnDeviceModal}
          account={account!}
          accountKiboshDevice={accountKiboshDevice!}
          accountKiboshVpnDevice={currentAccountKiboshVpnDevice}
          vpnAssignedDevicesIds={vpnAssignedDevicesIds}
          onClose={() => setShowAccountKiboshVpnDeviceModal(false)}
          onSave={() => setReload(true)} />
      }
    </>
  );
}
