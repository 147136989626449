// 3rd-party modules
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';

// project modules
// import Button from '../components/shared/button';
import Loader from '../components/shared/loader';
import ReviewShowMoreModal from '../components/review/ReviewShowMoreModal';
import { apiCall } from '../helpers/apiHelper';
import { CONSTANTS } from '../helpers/defines';
// import { RootState } from '../stores';

// apis
import * as PostApi from '../apis/postApi';

// models
import { Post } from '../models/post';
// import { User } from '../models/auth';
import LeaveReviewModal from '../components/review/LeaveReviewModal';

const getReviews = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(PostApi.getReviews(abortSignal));

  return response.success ? Post.toArrayOfClass(response.data?.value || []) : [];
};

// const getUserReviews = async (abortSignal?: AbortSignal) => {
//   const response = await apiCall(PostApi.getUserReviews(abortSignal));

//   return response.success ? Post.toArrayOfClass(response.data?.value || []) : [];
// };

export default function ReviewsPage() {
  const [currentReview, setCurrentReview] = useState<Post>(new Post());
  const [loading, setLoading] = useState<boolean>(false);
  const [showMoreModal, setShowMoreModal] = useState<boolean>(false);
  const [showLeaveReviewModal, setShowLeaveReviewModal] = useState<boolean>(false);
  const [reviews, setReviews] = useState<Post[]>([]);
  // const [userReviews, setUserReviews] = useState<Post[]>([]);
  // const [userReviewsLoading, setUserReviewsLoading] = useState<boolean>(true);
  let abortController = new AbortController();

  // const user = useSelector(
  //   (state: RootState) => state.user.currentUser
  // ) as User;

  useEffect(() => {
    let abortController = new AbortController();

    // if (!!user.accountId) {
    //   getUserReviewsAsync();
    // }

    getReviewsAsync();

    return () => { abortController.abort(); }
  }, []);

  const getReviewsAsync = async () => {
    setLoading(true);
    setReviews(await getReviews(abortController.signal));
    setLoading(false);
  }

  // const getUserReviewsAsync = async () => {
  //   setUserReviewsLoading(true);
  //   setUserReviews(await getUserReviews(abortController.signal));
  //   setUserReviewsLoading(false);
  // }

  const onShowMoreClick = (review: Post) => {
    setCurrentReview(review);
    setShowMoreModal(true);
  }

  return (
    <>
      <view data-border="none">
        <group
          data-direction="column"
          data-background="main"
          data-dark=""
          data-space-vertical="50"
        >
          <section>
            <wrapper>
              <group data-direction="column" data-gap="10">
                <icon data-icon-size="large">rate_review</icon>
                <text
                  data-weight="800"
                  data-text-size="xx-large"
                  data-wrap="wrap"
                >
                  Customer Reviews
                </text>
                <text data-text-size="large" data-wrap="wrap">
                  Some reviews from our customers.
                </text>
                {/* {
                  !!user.accountId &&
                  <>
                    <space data-height="5"></space>
                    <group>
                      {
                        !userReviewsLoading &&
                        (
                          !!userReviews.length ?
                          <text data-wrap="wrap">
                            Thank you for your submitted review.
                          </text>
                          :
                          <Button white rounded data-space="20" data-space-horizontal="20" onClick={() => setShowLeaveReviewModal(true)}>
                            <text>Leave a Review</text>
                          </Button>
                        )
                      }
                    </group>
                  </>
                } */}
              </group>

            </wrapper>
          </section>
        </group>
        <group data-space-vertical="50">
          <section>
            <wrapper>
              <group data-type="grid" data-grid-template="300" data-gap="30">
                {loading && <Loader/>}
                {
                  reviews.map((review: Post) => (
                    <group data-gap="10" data-direction="column" data-bacground="main" data-space="30" data-radius="5" data-elevation="5">
                      <text data-wrap="wrap" data-weight="700" data-color="main">{review.title}</text>
                      <group data-height="autofit" data-gap="10" data-text-line="1.5">
                        <text data-wrap="wrap">
                          { review.content && review.content?.length > 100 ? `${review.content.slice(0,100)}... ` : review.content}
                        </text>
                        { review.content && review.content?.length > 100 &&
                        <div data-position="right" onClick={() => onShowMoreClick(review)}>
                          <text data-weight="700" data-space="10" data-ellipsis="" data-cursor="pointer">
                            Show More ...
                          </text>
                        </div>}
                      </group>
                      <separator data-horizontal="" data-margin="none"></separator>
                      <text data-wrap="wrap" data-weight="700" data-text-size="12">{moment(review.createdAt).format(CONSTANTS.DEFAULT_DATE_FORMAT2)}</text>
                    </group>
                  ))
                }
              </group>
            </wrapper>
          </section>
        </group>
      </view>
      {showMoreModal &&
        <ReviewShowMoreModal open={showMoreModal} review={currentReview} onClose={() => setShowMoreModal(false)} />
      }
      {showLeaveReviewModal &&
        <LeaveReviewModal open={showLeaveReviewModal} closeOnSave={true} onClose={() => setShowLeaveReviewModal(false)} />
      }
    </>
  );
}
