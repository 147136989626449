// 3rd-party modules
import * as yup from 'yup';
import { message } from 'antd';
import { useForm } from 'react-hook-form';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

// project modules
import Button from '../../components/shared/button';
import Checkbox from '../../components/shared/inputs/checkbox';
import Config from '../../config';
import Input, { Password } from '../../components/shared/inputs/input';
import { RememberUser, loginUser, setRememberUser } from '../../stores/userStore';
import { RootState } from '../../stores';
import { trimStringProps } from '../../helpers/objectHelper';
import { useAppDispatch } from '../../hooks/storeHooks';

// models
import { ApiResponse } from '../../models/response';
import { User } from '../../models/auth';

// styles and images
import Loader from '../../components/shared/loader';

type FormValues = {
  username: string;
  password: string;
};

const schema = yup.object().shape({
  username: yup.string().label("Username").required(),
  password: yup.string().label("Password").required()
});

export default function LoginPage() {
  const { register, formState: { errors }, handleSubmit, getValues, setValue, setFocus } = useForm<FormValues>({
    resolver: yupResolver(schema)
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [keepSignedIn, setKeepSignedIn] = useState(false);
  const [searchParams] = useSearchParams();
  const rememberUser = useSelector((state: RootState) => state.user.rememberUser) as RememberUser;

  const getUserHomePage = (user: User): string => {
    return '/portal';
  };

  useEffect(() => {
    const email = searchParams.get("email");
    // if remember state is true, set restored username and keep-me-signed-in
    if (email || rememberUser?.remember) {
      setValue('username', email || rememberUser.username!);
      setFocus('password');
      if (rememberUser?.remember) {
        setKeepSignedIn(true);
      }
    } else
      setFocus('username');
  }, []);

  const onSubmit =  async (formData: FormValues) => {
    setLoading(true);
    const response: ApiResponse = await dispatch(loginUser(trimStringProps(formData))).unwrap();
    setLoading(false);

    if (response.success) {
      const user = User.toClass(response.data?.value);

      dispatch(setRememberUser({ username: keepSignedIn ? formData.username : undefined, remember: keepSignedIn }));

      if (user.activeSubscriptionsCount) {
        navigate(searchParams.get('returnUrl') || getUserHomePage(user));
      }
      else {
        navigate(Config.newSubPageUrl);
      }
    } else {
      message.error(response.errors.length > 0 ? `${response.errors[0]} ${response.errors[1]}` : response.errors[0]);
      if (response?.error?.code === "notConfirmedEmail") {
        navigate(`/not-confirmed-email?email=${formData.username}`);
      }
    }
  };

  const onSignupClick = async () => {
    navigate({
      pathname: "/auth/signup",
    });
  };

  const onForgotPasswordClick = async () => {
    const returnUrl = "/auth/forgot-password";
    const email = getValues("username");
    if (email) {
      navigate({
        pathname: returnUrl,
        search: `?${createSearchParams({ email })}`,
      });
    } else {
      navigate({
        pathname: returnUrl
      });
    }
  };

  return (
    <>
      {loading && <Loader />}
      <view data-border="none">
        <section data-position="center">
          <wrapper>
            <group data-space-horizontal="10">
              <group data-direction="column" data-gap="10">
                {/* <icon data-icon-size="large">lock</icon> */}
                <text
                  data-weight="800"
                  data-text-size="x-large"
                  data-wrap="wrap"
                >
                  Sign in
                </text>
                <text data-light="" data-wrap="wrap">
                  Use your KiBosh Account
                </text>
              </group>

              <group
                data-gap="10"
                data-direction="column"
                data-space-vertical="50"
                data-max-length="300"
              >
                <div className="field" data-length="auto" data-multi-element="" data-size="large">
                  <div
                    className={`form_fields ${errors.username ? "validationError" : ""
                      }`}
                  >
                    <Input
                      size="large"
                      icon="person"
                      dataLength="320"
                      placeholder="User ID"
                      {...register("username")}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          document.getElementById("passwordInput")?.focus();
                        }
                      }}
                      tabIndex={1}
                    />
                    {errors.username && (
                      <errormessage>
                        <text className="errorMessage">
                          {errors.username?.message?.toString()}
                        </text>
                      </errormessage>
                    )}
                  </div>
                </div>
                <div className="field" data-length="auto" data-multi-element="" data-size="large">
                  <div
                    className={`form_fields ${errors.password ? "validationError" : ""
                      }`}
                  >
                    <Password
                      size="large"
                      icon="key"
                      placeholder="Password"
                      dataLength="320"
                      {...register("password")}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handleSubmit(onSubmit)();
                        }
                      }}
                      tabIndex={2}
                      id="passwordInput"
                    />
                    {errors.password && (
                      <errormessage>
                        <text className="errorMessage">
                          {errors.password?.message?.toString()}
                        </text>
                      </errormessage>
                    )}
                  </div>
                </div>
                <space></space>
                <Button
                  large
                  primary
                  text="Sign In"
                  onClick={handleSubmit(onSubmit)}
                />
                <Button large highlight text="Sign Up" onClick={onSignupClick} />
                <space data-height="10"></space>
                <separator data-horizontal=""></separator>
                <group>
                  <space data-height="10"></space>
                  <Checkbox
                    name="1"
                    label="Keep me signed in"
                    classic
                    checked={keepSignedIn}
                    onChange={(e) => setKeepSignedIn(e.target.checked)}
                  />
                </group>
                <Button large outline text="Forgot Password ?" onClick={onForgotPasswordClick} />
              </group>
            </group>
          </wrapper>
        </section>
      </view>
    </>
  );
}
