// limited prototype
// currently works only on top sticky elements

import React, { useState, useRef } from "react";
import { useCallback } from 'react';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const StickyElement: React.FC<Props> = ({ children, className }) => {
  const [isSticky, setIsSticky] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handler = useCallback((ratio: number) => setIsSticky(ratio < 1), []);

  useIntersectionObserver(ref, handler)
  return (

    <group data-sticky="top" ref={ref} className={`sticky ${isSticky ? className ?? 'stuck' : ''}`}>
      {children}
    </group>

  );
};

export default StickyElement;



