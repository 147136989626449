// 3rd-party modules
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { message } from 'antd';
import { useEffect, useState } from 'react';

// project modules
import Button from '../../components/shared/button';
import Loader from '../../components/shared/loader';
import { apiCall } from '../../helpers/apiHelper';

//models
import { ApiResponse } from '../../models/response';

// apis
import * as AuthApi from '../../apis/authApi';
import { useAppDispatch } from '../../hooks/storeHooks';
import { loginUserByRefreshToken } from '../../stores/userStore';
import { loginViewModel } from '../../models/types/auth';
import config from '../../config';

export default function EmailConfirmationPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const abortController = new AbortController();

  useEffect(() => {
    confirmEmail();
  },[]);

  const confirmEmail = async () => {
    const email = searchParams.get("email");
    let token = "";

    //searchParams.get escape the value
    const currentUrl = window.location.href.split("token=");
    if (currentUrl.length > 1) {
      token = currentUrl[1];
    }

    if (email && token) {
      setLoading(true);
      const response: ApiResponse = await apiCall(AuthApi.confirmEmail(email, token, abortController.signal));

      if (response.success) {
        message.success("Your email confirmed successfully")

        setTimeout(() => {
          loginViaToken();
        }, 1500);

      } else
        message.error(response.error?.value);
      setLoading(false);
    }
  }

  const loginViaToken = async () => {
    const email = searchParams.get("email");
    let token = "";

    //searchParams.get escape the value
    const currentUrl = window.location.href.split("token=");
    if (currentUrl.length > 1) {
      token = currentUrl[1];
    }

    if (email && token) {
      setLoading(true);
      const loginData : loginViewModel = {
        username: email,
        refreshToken: token,
        password: "testPassword"
      }
      const response: ApiResponse = await dispatch(loginUserByRefreshToken(loginData)).unwrap();
      setLoading(false);

      if (response.success) {
        navigate(config.newSubPageUrl);
      } else {
        message.error(response.errors.length > 0 ? `${response.errors[0]} ${response.errors[1]}` : response.errors[0]);
        if (response?.error?.code === "notConfirmedEmail") {
          navigate(`/not-confirmed-email?email=${email}`);
        }
      }
    }
  }

  const onBackToLoginClick = () => {
    const returnUrl = "/auth/login";
    const email = searchParams.get("email");
    if (email) {
      navigate({
        pathname: returnUrl,
        search: `?${createSearchParams({ email })}`,
      });
    } else {
      navigate({
        pathname: returnUrl
      });
    }
  };

  return (
    <>
      {loading && <Loader/>}
      <view data-border="none">
        <section data-position="center">
          <wrapper>
            <group data-space-horizontal="10">
              <group data-direction="column" data-gap="10">
                <text
                  data-weight="800"
                  data-text-size="xx-large"
                  data-wrap="wrap"
                >
                  Email Confirmed
                </text>
                <text data-text-size="large" data-wrap="wrap">
                  Your KiBosh account email has been successfully confirmed! Please wait a moment while we automatically redirect you to the next step of the registration process.
                </text>
              </group>
              <group
                data-gap="10"
                data-direction="column"
                data-space-vertical="50"
                data-max-length="300">
                <Button
                  fit
                  large
                  outline
                  text="Back To Login" onClick={onBackToLoginClick} />
              </group>
            </group>
          </wrapper>
        </section>
      </view>
    </>
  );
}
