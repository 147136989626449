// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToInstance } from 'class-transformer';

export class Post {
  @Expose()
  public postId?: number;
  @Expose()
  public parentId?: number;
  @Expose()
  public type?: string;
  @Expose()
  public routePath?: string;
  @Expose()
  public categoryId?: number;
  @Expose()
  public category?: string;
  @Expose()
  public title?: string;
  @Expose()
  public excerpt?: string;
  @Expose()
  public content?: string;
  @Expose()
  public order?: number;
  @Expose()
  public status?: string;
  @Expose()
  public tags?: string;
  @Expose()
  public featuredImagePath?: string;
  @Expose()
  public languageId?: string;
  @Expose()
  public profile?: string;
  @Expose()
  public template?: string;
  @Expose()
  public createdAt?: string;
  /*
  @Expose()
  public allowComments?: boolean;
  */

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(Post, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(Post, camelcaseKeys(data, { deep: true }) as Post[], { excludeExtraneousValues: true });
  }
}
