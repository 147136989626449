// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToInstance } from 'class-transformer';

export class Comment {
  @Expose()
  public commentId?: number;
  @Expose()
  public parentId?: number;
  @Expose()
  public entityCode?: string;
  @Expose()
  public entityId?: number;
  @Expose()
  public type?: string;
  @Expose()
  public createdAt?: string;
  @Expose()
  public from?: string;
  @Expose()
  public message?: string;
  @Expose()
  public readAt?: Date

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(Comment, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(Comment, camelcaseKeys(data, { deep: true }) as Comment[], { excludeExtraneousValues: true });
  }
}
