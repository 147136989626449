// 3rd-party modules
import moment from 'moment-timezone';
import Popup from '../shared/popup/popup';
import { CONSTANTS } from '../../helpers/defines';

// models
import { Post } from '../../models/post';

type Props = {
  open: boolean;
  review: Post;
  onClose?: () => void;
};

export default function ReviewShowMoreModal({ open, review, onClose }: Props) {
  return (
    <Popup
      title={`Review: ${review.title}`}
      noCommandbar={true}
      adaptive
      fixSize='medium'
      onClose={onClose}
      open={open}
    >
      <group data-gap="10" data-direction="column" data-bacground="main" data-space="30">
        <group data-height="autofit" data-gap="10" data-text-line="1.5">
          <text data-wrap="wrap">
            { review.content }
          </text>
        </group>
        <separator data-horizontal="" data-margin="none"></separator>
        <text data-wrap="wrap" data-weight="700" data-text-size="12">{moment(review.createdAt).format(CONSTANTS.DEFAULT_DATE_FORMAT2)}</text>
      </group>
    </Popup>
  );
}
