import React, { useRef } from "react";
import { useMaterialEffect } from "../../../hooks/useMaterialEffectHook";

const Ripple: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const refObj = useRef(null);
  const { svg, onMouseDown, onMouseLeave, onMouseUp } = useMaterialEffect({
    ref: refObj,
    isMaterial: true,
  });
  return React.cloneElement(React.Children.only(children), {
    onMouseDown,
    onMouseLeave,
    onMouseUp,
    ref: refObj,
    key: children.key,
    children: (Array.isArray(children.props.children)
      ? [...children.props.children, svg]
      : [children.props.children, svg]).map((item: any, index: any) => { if(item) {return {...item, key: `${children.key}-${index}`}} else return item }),
  });
};

export default Ripple;
