// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, Type, plainToInstance } from 'class-transformer';

export class Product {
  @Expose({ name: 'id'})
  public productId?: string;
  @Expose()
  public name?: string;
  @Expose()
  public description?: string;
  @Expose({ name: 'defaultPriceId' })
  public priceId?: string;
  @Expose({ name: 'defaultPrice' })
  @Type(() => Price)
  public price?: Price;
  @Expose()
  public mode?: string;
  @Expose()
  public email?: string;
  @Expose()
  public kiboshDeviceReference?: string;
  @Expose()
  public active?: boolean;

  /*
  @Expose()
  public productId?: number;
  @Expose()
  public productName?: string;
  @Expose()
  public productCode?: string;
  @Expose()
  public productDescription?: string;
  @Expose()
  public productTypeId?: number;
  @Expose()
  public productType?: string;
  @Expose()
  public price?: number;
  @Expose()
  public priceCurrency?: string;
  @Expose()
  public priceOccurrence?: string;
  @Expose()
  public freeTrialPeriod?: number;
  @Expose()
  public freeTrialUnit?: string;
  @Expose()
  public effectiveDate?: Date;
  @Expose()
  public expireDate?: Date;
  @Expose()
  public isOnSale?: boolean;
  @Expose()
  public salePrice?: number;
  @Expose()
  public saleStartDate?: Date;
  @Expose()
  public saleEndDate?: Date;
  */

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(Product, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(Product, camelcaseKeys(data, { deep: true }) as Product[], { excludeExtraneousValues: true });
  }
}

export class Price {
  @Expose({ name: 'id'})
  public priceId?: string;
  @Expose({ name: 'unitAmount' })
  public unitAmount?: number;
  @Expose({ name: 'unitAmountDecimal' })
  public unitAmountDecimal?: number;
  @Expose({ name: 'currency' })
  public currency?: string;
  @Expose({ name: 'type' })
  public type?: string;
  @Expose()
  public active?: boolean;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(Price, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(Price, camelcaseKeys(data, { deep: true }) as Product[], { excludeExtraneousValues: true });
  }
}
