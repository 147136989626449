// 3rd-party modules

// project modules
import KiboshDevicePortForwardings from './port-forwarding/KiboshDevicePortForwardings';
import KiboshDeviceFirmwareUpgrade from './upgrade-firmware/KiboshDeviceFirmwareUpdate';
import KiboshDeviceWifiSettings from './wifi/KiboshDeviceWifiSettings';
import Tab from '../../../shared/tabstrip/tab';
import TabStrip from '../../../shared/tabstrip';

// apis

// models
import { Account } from '../../../../models/account';
import { AccountKiboshDevice } from '../../../../models/accountKiboshDevice';
import AccountDeviceFilterations from './device-filteration/AccountDeviceFilterations';
import { useEffect, useState } from 'react';

type Props = {
  account: Account;
  accountKiboshDevice: AccountKiboshDevice;
  preSelectedTab?: string;
  showTabs?: boolean;
  onClose?: () => void;
  onSave?: (accountKiboshDevice?: any) => void;
};

export default function KiboshDeviceSettings({ account, accountKiboshDevice, preSelectedTab, showTabs = true, onClose, onSave }: Props) {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const abortController = new AbortController();

  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onStepSave = (acc: Account, step: string) => {
    switch (step) {
      default:
        break;
    }

    if (onSave) {
      onSave();
    }
  };

  useEffect(()=> {
    switch (preSelectedTab) {
      case 'WiFi-settings':
        setCurrentTab(0)
        break;
      case 'port-forwarding':
        setCurrentTab(1)
        break;
      case 'firmware-upgrade':
        setCurrentTab(2)
        break;
      case 'filtration':
        setCurrentTab(3)
        break;
    }
  }, [preSelectedTab]);

  return (
    <TabStrip
      skipSecondaryTabs={false}
      classic={false}
      selectedIndex={currentTab}
      showTabs={showTabs}
      id="kibosh-device-settings-tab">
      <Tab title='WiFi Settings' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference}>
        {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference &&
          <KiboshDeviceWifiSettings refresh={!!account.accountId} account={account} accountKiboshDevice={accountKiboshDevice} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'firmwareUpgrade')} />
        }
      </Tab>
      <Tab title='Port Forwarding' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference}>
        {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference &&
          <KiboshDevicePortForwardings refresh={!!account.accountId} account={account} accountKiboshDevice={accountKiboshDevice} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'portForwarding')} />
        }
      </Tab>
      <Tab title='Firmware Upgrade' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference}>
        {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference &&
          <KiboshDeviceFirmwareUpgrade refresh={!!account.accountId} account={account} accountKiboshDevice={accountKiboshDevice} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'firmwareUpgrade')} />
        }
      </Tab>
      <Tab title='Raw Internet' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference}>
        {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference &&
          <AccountDeviceFilterations refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'filteration')} />
        }
      </Tab>
    </TabStrip>
  );
}
