// 3rd-party modules
import { useEffect, useState } from "react";

// project modules
import AccountModal from "./AccountModal";
import Loader from "../shared/loader";
import ListView from "../shared/list/listView";
import { ListViewType } from "../shared/list/useListHook";
import Ripple from "../shared/additionalComponents/Ripple";
import Marquee from "../shared/additionalComponents/Marquee";
import { Text } from "../shared/text";

// models
import { Account } from '../../models/account';

type Props = {
  account: Account;
  refresh: boolean;
  onClose?: () => void;
  onSave?: () => void;
};

export default function AccountMenu({ account, refresh, onClose, onSave }: Props) {
  const [currentAccount, setCurrentAccount] = useState<Account>(account);
  const [preSelectedTab, setPreSelectedTab] = useState<string>("");
  const [showAccountModal, setShowAccountModal] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);

  const listItems: any[] = [
    {
      id: 'details',
      name: 'Account Information',
      icon: "info",
      isActive: true
    },
    {
      id: 'billing',
      name: 'Billing',
      icon: "attach_money",
      isActive: true
    },
    {
      id: 'subscriptions',
      name: 'Subscription',
      icon: "subscriptions",
      isActive: true
    },
    {
      id: 'kibosh-devices',
      name: 'Kibosh Devices',
      icon: "router",
      isActive: true
    },
    {
      id: 'activation',
      name: 'Account Activation',
      icon: "key_vertical",
      isActive: true
    }
  ]

  // const abortController = new AbortController();
  useEffect(()=> {
    if (account) {
      setCurrentAccount(account);
    }
  },[account]);

  useEffect(() => {
  }, [refresh]);

  const onAccountSave = (e?: Account) => {
    if (e) {
      setLoading(true)
      setCurrentAccount(e)
      setLoading(false)
      if(onSave) onSave();
    }
  }

  // const onCancel = () => {
  //   abortController.abort();

  //   if (onClose) onClose();
  // };

  const onSelectedTab = (selectedTab: any) => {
    if (selectedTab) {
      setPreSelectedTab(selectedTab);
      setShowAccountModal(true);
    }
  };

  const ListItem: React.FC<{ data: any }> = ({ data }) => (
    <Ripple>
      <group
        data-align="center"
        data-space="5"
        data-gap="5"
        data-wrap="no"
        // data-radius="5"
        data-contain=""
        data-cursor="pointer"
        data-disabled={data.isActive ? undefined : "grayscale"}>
        <group data-ratio="1:1" data-length="50" data-shrink="no" data-index="1" data-radius="5" data-color="main" >
          <icon data-icon-size="48" data-position="center">{data.icon}</icon>
        </group>
        <group data-direction="column" data-contain="" data-space="5" data-index="1">
          <Text data-weight="700" data-ellipsis="">
            {data.name}
          </Text>
          {!!data.description && <Marquee>
            <Text data-ellipsis="" data-light="">
              {data.description}
            </Text>
          </Marquee>}
        </group>
        {/* <separator data-vertical=""></separator> */}
        <group
          data-align="center"
          data-wrap="no"
          data-position="right"
          data-width="auto"
          data-index="1">
          <group data-space="10">
            <icon data-opacity="30">chevron_right</icon>
          </group>
        </group>
      </group>
    </Ripple>
  );

  return (
    <>
      {loading &&
        <Loader />
      }
      <group data-space="10" data-color="main" data-space-horizontal="20">
        <text data-weight="700">Account</text>
      </group>
      <group 
      data-space="15"
      >
        <group data-contain="" data-radius="10" data-elevation="2">
          <ListView
            data-template="400"
            view={ListViewType.List}
            dataSource={listItems}
            onItemClick={(item: any) => item.isActive ? onSelectedTab(item.id) : null}
            keyField="id"
            listProps={{ "data-gap": "1" }}
            itemComponent={ListItem}
          />
        </group>
      </group>

      {!!showAccountModal && (
        <AccountModal
          open={showAccountModal}
          showTabs={false}
          account={currentAccount!}
          preSelectedTab={preSelectedTab}
          onClose={() => setShowAccountModal(false)}
          onSave={(e) => onAccountSave(e)}
        />
      )}
    </>
  );
}
