// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToInstance } from 'class-transformer';

export class KiboshDevicePortForwarding {
  @Expose()
  public id?: string;
  @Expose()
  public name?: string;
  @Expose()
  public srcDport?: number;
  @Expose()
  public destIp?: string;
  @Expose()
  public destPort?: number;
  @Expose()
  public proto?: string;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(KiboshDevicePortForwarding, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(KiboshDevicePortForwarding, camelcaseKeys(data, { deep: true }) as KiboshDevicePortForwarding[], { excludeExtraneousValues: true });
  }
}
