// 3rd-party modules
import { useState } from "react";

// project modules
import Button from "../shared/button";

type Props = {
  item: any;
};

export default function FAQItem({ item }: Props) {
  const [showDetails, setShowDetails] = useState(false);

  const onItemClick = () => {
    setShowDetails(!showDetails)
  }

  return (
    <group data-radius="10" data-border="" data-contain="">
      <group
        data-border=""
        data-wrap="no"
        data-space="10"
        data-align="center"
        data-interactive=""
        data-cursor="pointer"
      >
        <group data-space="10" onClick={onItemClick}>
          <text data-wrap="wrap" data-weight="700">
            {item.title}
          </text>
        </group>
        <group data-width="auto">
          <Button mini rounded icon={showDetails ? "expand_less" : "expand_more"} onClick={onItemClick}></Button>
        </group>
      </group>
      <group data-name={!showDetails ? "FAQ_response" : null} data-space="20">
        <text data-wrap="wrap" data-line="1.5">
          {item.description}
        </text>
      </group>
    </group>
  );
}
