// 3rd-party modules
import { message } from "antd";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

// project modules
// import AccountPickerModal from "../../accounts/AccountPickerModal";
import AttachmentsPicker from "../../attachments/AttachmentsPicker";
import Input, { InputType } from "../../shared/inputs/input";
import Loader from "../../shared/loader";
import Select from "../../shared/inputs/select";
import Popup from "../../shared/popup/popup";
import yup from "../../../plugins/yup";
import { apiCall } from "../../../helpers/apiHelper";
import { RootState } from "../../../stores";

// apis
import * as CommonValueApi from '../../../apis/commonValueApi';
import * as SupportTicketApi from '../../../apis/supportTicketApi';

// models
import { SupportTicket } from "../../../models/supportTicket";
import { CommonValue } from "../../../models/commonValue";
import { ApiResponse } from "../../../models/response";
import { User } from "../../../models/auth";

// defines
type Props = {
  closeOnSave?: boolean;
  open: boolean;
  supportTicket: SupportTicket;
  modalTitle?: string;
  onClose?: () => void;
  onSave?: (supportTicket: SupportTicket) => void;
};

const getTicketCategories = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(CommonValueApi.getTicketCategories(abortSignal));

  return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
};

const getTicketPriorities = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(CommonValueApi.getTicketPriorities(abortSignal));

  return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
};

const getTicketStatuses = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(CommonValueApi.getTicketStatuses(abortSignal));

  return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
};

export default function SupportTicketModal({ closeOnSave = false, open, modalTitle = "", onClose, onSave, supportTicket }: Props) {
  const schema = yup.object().shape({
    ticketCategoryId: yup.string().label("Category").required(),
    // assigneeUserId: yup.string().label("Assignee").nullable(),
    subject: yup.string().label("Subject").max(1000).required(),
    detail: yup.string().label("Detail").required(),
  });
  const { control, handleSubmit } = useForm<SupportTicket | any>({
    defaultValues: useMemo(() => supportTicket, [supportTicket]),
    resolver: yupResolver(schema),
  });
  const [currentSupportTicket, setCurrentSupportTicket] = useState<SupportTicket>(new SupportTicket());
  const [currentSupportTicketId, setCurrentSupportTicketId] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [loadingTicketCategories, setLoadingTicketCategories] = useState(false);
  const [loadingTicketPriorities, setLoadingTicketPriorities] = useState(false);
  const [loadingTicketStatuses, setLoadingTicketStatuses] = useState(false);
  const [ticketCategories, setTicketCategories] = useState<CommonValue[]>([]);
  const [ticketPriorities, setTicketPriorities] = useState<CommonValue[]>([]);
  const [ticketStatuses, setTicketStatuses] = useState<CommonValue[]>([]);
  const [waitingForAttachmentsUpload, setWaitingForAttachmentsUpload] = useState(false);

  const abortController = new AbortController();
  const user = useSelector((state: RootState) => state.user.currentUser) as User;

  useEffect(() => {
    if (open) {
      const getTicketCategoriesAsync = async () => {
        setLoadingTicketCategories(true);
        setTicketCategories(await getTicketCategories(abortController.signal));
        setLoadingTicketCategories(false);
      }
      const getTicketPrioritiesAsync = async () => {
        setLoadingTicketPriorities(true);
        setTicketPriorities(await getTicketPriorities(abortController.signal));
        setLoadingTicketPriorities(false);
      }
      const getTicketStatusesAsync = async () => {
        setLoadingTicketStatuses(true);
        setTicketStatuses(await getTicketStatuses(abortController.signal));
        setLoadingTicketStatuses(false);
      }

      setCurrentSupportTicket(supportTicket);
      setCurrentSupportTicketId(supportTicket.supportTicketId || 0);
      getTicketCategoriesAsync();
      getTicketPrioritiesAsync();
      getTicketStatusesAsync();
    }
  }, [open]);

  const onAttachmentsUploadEnd = () => {
    if (closeOnSave) {
      open = false;

      onCancel();
    }
    setLoading(false);
  };

  const onCancel = () => {
    abortController.abort();

    if (onClose) onClose();
  };

  const onSubmit: SubmitHandler<SupportTicket> = async (formData: SupportTicket) => {
    let response: ApiResponse;

    setLoading(true);

    if (!supportTicket?.supportTicketId) {
      formData.priority = 'medium';
      formData.status = 'open';

      formData.accountId = user.accountId?.toString();
      formData.accountName = `${user.firstName} ${user.lastName}(${user.email})`;
      formData.contactName = `${user.firstName} ${user.lastName}`;
      formData.contactEmail = user.email;

      response = await apiCall(SupportTicketApi.insertSupportTicket(formData, abortController.signal));
    } else
      response = await apiCall(SupportTicketApi.updateSupportTicket(formData, abortController.signal));

    if (response.success) {
      message.success(`Ticket ${!supportTicket?.supportTicketId ? 'added' : 'edited'} successfully.`);

      // set id for attachments request
      if (!supportTicket?.supportTicketId) {
        setCurrentSupportTicketId(response.data?.value);
      }

      if (onSave) onSave(SupportTicket.toClass(response.data?.value));

      if (!waitingForAttachmentsUpload) {
        if (closeOnSave) {
          open = false;

          onCancel();
        }
      }

    } else
      message.error(response.error?.value);

    if (!waitingForAttachmentsUpload) {
      setLoading(false);
    }
  };

  return (
    <Popup
      adaptive
      title={ modalTitle || !currentSupportTicket.supportTicketId ? "New Support Ticket" : `Support Ticket #: ${currentSupportTicket.supportTicketId} - ${currentSupportTicket.subject}`}
      onCancel={onCancel}
      onClose={onCancel}
      onSave={handleSubmit(onSubmit)}
      fixSize="medium"
    >
      {loading && <Loader />}
      <group data-direction="column" data-gap="10" data-space='15'>
        <separator horizontal=""></separator>

        <group data-gap="10">
          <Select
            label="Category"
            control={control}
            dataLength="auto"
            loading={loadingTicketCategories}
            name="ticketCategoryId"
            allowSearch={true}
            options={
              ticketCategories?.map((item) => {
                return {
                  text: item.valueCaption!,
                  value: item.commonValueId!,
                };
              }) || []
            }
          />
          {/* {supportTicket?.supportTicketId &&
          <>
            <Select
              label="Priority"
              control={control}
              dataLength="150"
              name="priority"
              allowSearch={true}
              loading={loadingTicketPriorities}
              options={
                ticketPriorities?.map((item) => {
                  return {
                    text: item.valueCaption!,
                    value: item.value!.toLocaleLowerCase(),
                  };
                }) || []
              }
            />
            <Select
              label="Status"
              control={control}
              dataLength=""
              name="status"
              allowSearch={true}
              loading={loadingTicketStatuses}
              options={
                ticketStatuses?.map((item) => {
                  return {
                    text: item.valueCaption!,
                    value: item.value!.toLocaleLowerCase(),
                  };
                }) || []
              }
            />
          </>} */}
        </group>
        <separator horizontal=""></separator>
        <Input
          control={control}
          name="subject"
          label="Subject"
          dataLength="auto"
          size="large"
        />
        <separator horizontal=""></separator>
        <Input
          control={control}
          name="detail"
          type={InputType.TextArea}
          dataLength="auto"
          label="Details"
          resize=""
          aria-invalid="false"
          data-height="200"
        />
         <separator horizontal=""></separator>
        <AttachmentsPicker
          entityCode="support-ticket"
          entityId={currentSupportTicketId}
          holdTillSaveMainEntity={!currentSupportTicketId}
          onAttachmentsLengthChange={(length: number) => { if(!supportTicket?.supportTicketId) setWaitingForAttachmentsUpload(!!length)}}
          onUploadEnd={onAttachmentsUploadEnd} />
      </group>
    </Popup>
  );
}
