import classNames from "classnames";
import { forwardRef, PropsWithChildren, ReactElement } from "react";
import { Text } from "../text";
import { InputProps } from "./input";
import { TimePicker, TimePickerProps as AntTimePickerProps } from "antd";
import dayjs from "dayjs";
import { CONSTANTS } from '../../../helpers/defines';

export type TimePickerProps = InputProps & AntTimePickerProps;

export const TimePickerInner = forwardRef<
  ReactElement,
  PropsWithChildren<TimePickerProps>
>(
  (
    {
      children,
      minimal,
      label,
      className,
      value,
      isDirty,
      isTouched,
      invalid,
      error,
      onChange,
      ...inputProps
    },
    ref
  ) => {
    let type: any;
    let input;
    input = (
      <TimePicker
        ref={ref}
        {...inputProps}
        value={value ? dayjs(value, CONSTANTS.DEFAULT_DATETIME_FORMAT) : undefined}
        onChange={(v) => {
          onChange?.(
            (v ? dayjs(v).format(CONSTANTS.DEFAULT_DATETIME_FORMAT).toString() : null) as any
          );
        }}
      />
    );

    let content = (
      <label
        {...type}
        className={classNames(className, {
          error: invalid,
          touched: isTouched,
          dirty: isDirty,
        })}
      >
        {input}
        <Text>{label}</Text>
      </label>
    );

    return content;
  }
);
