// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToInstance } from 'class-transformer';

export class Account {
  @Expose({ name: 'id' })
  public accountId?: number;
  @Expose()
  public accountTypeId?: number;
  @Expose()
  public accountType?: string = "";
  @Expose()
  public accountName?: string = "";
  @Expose()
  public archived?: boolean;
  @Expose()
  public firstName?: string = "";
  @Expose()
  public lastName?: string = "";
  // @Expose()
  @Expose({ name: 'userName' })
  public username?: string = "";
  @Expose()
  public email?: string = "";
  @Expose()
  public phoneNumber?: string = "";
  @Expose()
  public lockoutEnabled?: boolean;
  @Expose()
  public country?: string = "";
  @Expose()
  public kiboshDeviceTypes?: string = "";
  @Expose()
  public activeSubscriptionsCount?: number;
  @Expose()
  public latestSubscriptionInterval?: number;
  @Expose()
  public hasSubscription?: boolean;
  @Expose()
  public roles: string[] = [];

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(Account, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(Account, camelcaseKeys(data, { deep: true }) as Account[], { excludeExtraneousValues: true });
  }
}
