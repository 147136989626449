// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToInstance } from 'class-transformer';

export class AccountKiboshDevice {
  @Expose()
  public accountKiboshDeviceId?: number;
  @Expose()
  public accountId?: number;
  @Expose()
  public kiboshDeviceName?: string;
  @Expose()
  public kiboshDeviceReference?: string;
  @Expose()
  public kiboshDeviceTypeId?: number;
  @Expose()
  public kiboshDeviceType?: string;
  @Expose()
  public notes?: string;
  @Expose()
  public inactive?: boolean;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(AccountKiboshDevice, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(AccountKiboshDevice, camelcaseKeys(data, { deep: true }) as AccountKiboshDevice[], { excludeExtraneousValues: true });
  }
}
