// 3rd-party modules
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";

// project modules
import Button from "../shared/button";
import Config from "../../config";
import Loader from "../shared/loader";
import { apiCall } from "../../helpers/apiHelper";

// apis
import * as PaymentApi from '../../apis/paymentApi';

// models
import { AccountKiboshDevice } from "../../models/accountKiboshDevice";
import { AccountLocation } from "../../models/accountLocation";
import { Product } from '../../models/product';
import { User } from "../../models/auth";

type Props = {
  account?: User;
  cancelText?: string;
  okText?: string;
  refresh?: boolean;
  selectedDevice?: AccountKiboshDevice | null;
  selectedBillingLocation?: AccountLocation | null;
  selectedProduct?: Product | null;
  onCancel?: () => void;
  onSave?: (amount: number) => void;
};

// make sure to call loadStripe outside of a component’s render to avoid recreating the Stripe object on every render.
const stripePromise = loadStripe(Config.stripePublishableKey);

export default function AccountPaymentVersion2({ account, cancelText = "Cancel", okText = "Save Changes", refresh, selectedDevice, selectedBillingLocation, selectedProduct, onCancel, onSave }: Props) {
  const [clientSecret, setClientSecret] = useState("");
  const [selectedCard, setSelectedCard] = useState<number | null>(null);
  const [amount, setAmount] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const abortController = new AbortController();

  useEffect(() => {
    let abortController = new AbortController();

    const getClientSecretAsync = async () => {
      setClientSecret(await createCheckoutSession({ priceId: selectedProduct?.priceId, mode: selectedProduct?.mode || 'subscription', email: account?.email, kiboshDeviceReference: selectedDevice?.kiboshDeviceReference }, abortController.signal));
    }

    getClientSecretAsync();

    return () => { abortController.abort(); }
  }, []);

  const createCheckoutSession = async (product: Product, abortSignal?: AbortSignal) => {
    setLoading(true);
    const response = await apiCall(PaymentApi.createCheckoutSession(product, abortSignal));
    setLoading(false);

    return response.success ? response.data?.value?.clientSecret : "";
  };

  const onCancelClick = () => {
    if (onCancel) {
      onCancel();
    }
  }

  const onCheckoutClick = async () => {
    setLoading(true);
    const response = await apiCall(PaymentApi.createCheckoutSession({ priceId: selectedProduct?.priceId, mode: selectedProduct?.mode || 'subscription', email: account?.email, kiboshDeviceReference: selectedDevice?.kiboshDeviceReference }, abortController.signal));
    setLoading(false);

    if (response.success) {
      const newWindow = window.open(response.data?.value.sessionUrl, '_blank');

      newWindow?.focus();
    }
  };

  const onSubmit = async () => {
    // let response: ApiResponse;

    // setLoading(true);

    // formData.userName = formData.email;

    // if (!account?.accountId)
    //   response = await apiCall(AccountApi.insertAccount(formData, abortController.signal));
    // else
    //   response = await apiCall(AccountApi.updateAccount(formData, abortController.signal));

    // if (response.success) {
    //   message.success(`User ${!account?.accountId ? 'added' : 'edited'} successfully.`);

    //   if (account?.accountId && formData.password && formData.confirmPassword) {
    //     response = await apiCall(AccountApi.changePassword({ username: account.username!, password: formData.password }, abortController.signal));

    //     if (response.success)
    //       message.success('Password changed successfully.');
    //     else
    //       message.error('Password changed faild.');
    //   }

    //   if (onSave) onSave(Account.toClass(response.data?.value));

    // } else
    //   message.error(response.error?.value);

    // setLoading(false);

    if (selectedCard != null && amount) {
      if (onSave) onSave(amount);
    }
  };

  const stripeOptions = {
    clientSecret
  };

  return (
    <>
      {loading && <Loader />}
      {clientSecret &&
      {/* <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{clientSecret}}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider> */}
      }
      <form
        data-length="auto"
        data-type="group"
      //data-gap="40"
      >
        <space data-height="40"></space>
        <group
          data-firection="column"
          data-align="center"
          data-justify="center"
          data-gap="20">
          <group
            data-elevation="5"
            data-radius="15"
            data-align="center"
            data-justify="center"
            data-direction="column"
            data-gap="5"
            data-space="20"
            data-background="main-background"
            data-shrink="0"
          >
            <group 
            data-direction="column" 
            //data-gap="10"
            >
              <group data-align="center" data-gap="5">
                <text data-wrap="wrap" data-weight="500" data-text-size="large">Ki<text data-weight="900" data-text-size="large">bosh</text></text>
                <text data-wrap="wrap" data-uppercase data-accent data-weight="900" data-text-size="large">Device</text>
              </group>

              <text data-weight="700" data-text-size="large" data-wrap="wrap">Name: {selectedDevice?.kiboshDeviceName}</text>
              <text data-weight="700" data-wrap="wrap">Reference: {selectedDevice?.kiboshDeviceReference}</text>
            </group>
            <group 
            data-direction="column" 
            //data-gap="10"
            >
              <group data-align="center" data-gap="5">
                <text data-wrap="wrap" data-weight="500" data-text-size="large">Ki<text data-weight="900" data-text-size="large">bosh</text></text>
                <text data-wrap="wrap" data-uppercase data-accent data-weight="900" data-text-size="large">Billing Address</text>
              </group>
              <text data-weight="700" data-wrap="wrap">Address: {[selectedBillingLocation?.addressLine1, selectedBillingLocation?.addressLine2, selectedBillingLocation?.addressLine3, selectedBillingLocation?.city, selectedBillingLocation?.stateProvince, `${selectedBillingLocation?.country} ${selectedBillingLocation?.zipPostal}`].filter(x => x).join(", ")}</text>
            </group>
            <group 
            data-direction="column" 
            //data-gap="10"
            >
              <group data-align="center" data-gap="5">
              <text data-wrap="wrap" data-weight="500" data-text-size="large">Ki<text data-weight="900" data-text-size="large">bosh</text></text>
                <text  data-wrap="wrap" data-uppercase data-accent data-weight="900" data-text-size="large">Product</text>
              </group>
              <text data-weight="700" data-wrap="wrap">Name: {selectedProduct?.name}</text>
              <text data-weight="700" data-text-size="x-large" data-wrap="wrap">Price: ${((selectedProduct?.price?.unitAmount || 0) / 100).toFixed(2)}</text>
            </group>
          </group>
          {/* <group
               data-elevation="5"
               data-radius="15"
               data-align="center"
               data-justify="center"
               data-direction="column"
               data-gap="20"
               data-space="50"
               data-background="main-background"
               data-length="350"
               data-shrink="0"
               >
                <group data-direction="column" data-min-height='130' data-gap="20">
                  <wrap>
                    <text data-weight="500" data-text-size="large">
                      Ki
                    </text>
                    <text data-weight="900" data-text-size="large">
                      bosh
                    </text>
                    <text data-uppercase data-space='5' data-accent data-weight="900" data-text-size="large">
                      Billing Address
                    </text>
                  </wrap>

                  <text data-weight="700" data-wrap="wrap">
                    Address: {[selectedBillingLocation?.addressLine1,selectedBillingLocation?.addressLine2,selectedBillingLocation?.addressLine3,selectedBillingLocation?.city,selectedBillingLocation?.stateProvince,`${selectedBillingLocation?.country} ${selectedBillingLocation?.zipPostal}`].filter(x => x).join(", ")}
                  </text>
                </group>
              </group>
              <group
                data-elevation="5"
                data-radius="15"
                data-align="center"
                data-justify="center"
                data-direction="column"
                data-gap="20"
                data-space="50"
                data-background="main-background"
                data-length="350"
                data-shrink="0"
              >
                <group  data-direction="column" data-min-height='130' data-gap="20">
                  <wrap>
                    <text data-weight="500" data-text-size="large">
                      Ki
                    </text>
                    <text data-weight="900" data-text-size="large">
                      bosh
                    </text>
                    <text data-uppercase data-space='5' data-accent data-weight="900" data-text-size="large">
                      Product
                    </text>
                  </wrap>
                  <text data-weight="700" data-text-size="large" data-wrap="wrap">
                    Name: {selectedProduct?.name}
                  </text>
                  <text data-weight="700" data-wrap="wrap">
                    Price: ${((selectedProduct?.price?.unitAmount || 0)/100).toFixed(2)}
                  </text>
                </group>
              </group> */}
        </group>
        <space data-height="40"></space>
        <group data-align="center" data-gap="10">
          <Button large material outline data-length="forcefit" onClick={() => onCancelClick()}>
            <text data-space-horizontal="10">Back</text>
          </Button>
          <Button large material primary data-length="forcefit" onClick={() => onCheckoutClick()}>
            <text>Checkout</text>
          </Button>
        </group>
        <space data-height="40" data-adaptive="mobile"></space>
      </form>
    </>
  );
}
