import { Tooltip } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { ColumnType } from "antd/lib/table";
import Button from "../../components/shared/button";
import { PaginationToolbar } from "../../components/shared/list/paginationToolbar";
import Table from "../../components/shared/list/table";
import { ListViewType,useList,} from "../../components/shared/list/useListHook";

type TableRecord = Record<string, any>;
const columns: ColumnType<TableRecord>[] = [
  {
    key: "name",
    title: "Name",
    dataIndex: "name",
    width: 200,
  },
  {
    key: "description",
    title: "Description",
    dataIndex: "description",
    // width: 100,
    // ellipsis: true,
  },
  {
    key: "age",
    title: "Age",
    dataIndex: "age",
    sorter: (a: TableRecord, b: TableRecord) => a.age - b.age,
    width: 60,
  },
  {
    key: "address",
    title: "Address",
    dataIndex: "address",

    filters: [
      {
        text: "London",
        value: "London",
      },
      {
        text: "New York",
        value: "New York",
      },
    ],
    onFilter: (value, record) => record.address.indexOf(value) === 0,
  },
  {
    title: "Action",
    key: "action",
    ellipsis: true,
    width: 36,
    render: () => (
      <group data-wrap="no">
        <Button adaptive icon="home" text="Download" />
      </group>
    ),
  },
  {
    title: "...",
    key: "action1",
    ellipsis: true,
    //  fixed: 'right',
    width: 72,
    render: (value: string, record: Record<string, any>) => (
      <group data-wrap="no">
        <Tooltip title={`Delete ${record.name}`}>
          <Button mini icon="delete" />
        </Tooltip>

        <Button mini>
          <icon data-icon-weight="300">more_vert</icon>
        </Button>
      </group>
    ),
  },
];
const data: TableRecord[] = [];

for (let i = 1; i <= 15; i++) {
  data.push({
    key: i,
    name: `John Brown ${i}`,
    age: Number(`${i}2`),
    address: `${i}720 Goodrich Ct Platteville, Colorado`,
    description: `John Brown, I am ${i}2 years old, living in ${i}720 Goodrich Ct Platteville, Colorado`,
  });
}

const TableDemo = () => {
  const hasData = true;
  const { pagingProps, sort, setSort } = useList({
    viewTypes: [ListViewType.Table],
  });
  const {
    pagination: { current, pageSize },
  } = pagingProps;
  return (

<view data-space="20">



<group data-border="inset" data-radius="10" data-contain="" data-height="fit">
  <view className="table_cont" data-border="no" data-background="none">
        {/*<div>{process.env.REACT_APP_VERSION}</div>*/}
        <group data-direction="column" data-space="15" data-gap="5">
          <text data-weight="700" data-text-size="medium">Active Orders</text>
          <text data-wrap="wrap" data-length="500">This page list the active orders that are in the system. Active orders are shipments that have not been delivered yet.</text>
        </group>
        <Table
          ellipsis={true}
          scroll={{
            y: "100%",
          }}
          bordered={true}
          columns={columns}
          tableLayout={"auto"}
          showHeader={true}
          dataSource={hasData ? data : []}
          pagination={false}
          customPagination={true}
          setSorter={setSort}
          size={"small" as SizeType}
        />

  </view>
</group>


</view>

  );
};

export default TableDemo;
