// 3rd-party modules
import { useEffect, useState } from 'react';
import { Tooltip, message } from 'antd';

// project modules
import Button from '../../shared/button';
import Popup from '../../shared/popup/popup';
import Loader from '../../shared/loader';
import Select from '../../shared/inputs/select';
import { apiCall } from '../../../helpers/apiHelper';

// apis
import * as AccountKiboshDeviceApi from '../../../apis/accountKiboshDeviceApi';
import * as CommonValueApi from '../../../apis/commonValueApi';
import * as DashApi from '../../../apis/dashApi';

// models
import { Account } from '../../../models/account';
import { AccountKiboshDevice } from '../../../models/accountKiboshDevice';
import { AccountKiboshVpnDevice } from '../../../models/accountKiboshVpnDevice';
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from '../../../models/dataSourceRequest';
import { AccountDevice } from '../../../models/accountDevice';
import { ApiResponse } from '../../../models/response';
import { CommonValue } from '../../../models/commonValue';
import { AccountVpnConfigUrl } from '../../../models/accountVpnConfigUrl';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';

type Props = {
  open: boolean;
  account: Account;
  accountKiboshDevice: AccountKiboshDevice;
  accountKiboshVpnDevice: AccountKiboshVpnDevice;
  vpnAssignedDevicesIds?: string[];
  modalTitle?: string;
  onClose?: () => void;
  onSave?: () => void;
};

export default function AccountVpnDevicePickerModal({ open, account, accountKiboshDevice, accountKiboshVpnDevice, modalTitle = "", vpnAssignedDevicesIds = [], onClose, onSave }: Props) {
  const [accountDevices, setAccountDevices] = useState<AccountDevice[]>([]);
  const [currentAccountDeviceId, setCurrentAccountDeviceId] = useState<string>("");
  const [currentAccountKiboshVpnDevice, setCurrentAccountKiboshVpnDevice] = useState<AccountKiboshVpnDevice>(accountKiboshVpnDevice);
  const [vpnAssignedDeviceId, setVpnAssignedDeviceId] = useState<any>(accountKiboshVpnDevice.associatedRouterClientId);
  const [dataSource, setDataSource] = useState<AccountDevice[]>([]);
  const [visibleAccountDevices, setVisibleAccountDevices] = useState<AccountDevice[]>([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [isDeviceAssignedRecently, setIsDeviceAssignedRecently] = useState<boolean>(false);

  const abortController = new AbortController();

  useEffect(() => {
    if (open) {
      if (currentAccountKiboshVpnDevice.kiboshDeviceReference?.startsWith("new-")) {
        onVpnCreation();
      }
      else {
        setReload(true);
      }
    }
  }, [open])

  useEffect(() => {
    if (account.accountId) {
      getAccountDevicesAsync()
    }
  }, [account])

  useEffect(() => {
    if (reload) {
      getPageAsync();
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    setVisibleAccountDevices(accountDevices.filter(x => !vpnAssignedDevicesIds.includes(x.id!)));
  }, [vpnAssignedDevicesIds])

  const getDeviceTypes = async (abortSignal?: AbortSignal) => {
    const response = await apiCall(CommonValueApi.getDeviceTypes(abortSignal));

    return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
  }

  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onVpnCreation = async () => {
    let response: ApiResponse;

    setLoading(true);

    let formData = {...currentAccountKiboshVpnDevice};
    const deviceTypes = await getDeviceTypes(abortController.signal);

    const deviceVpnType = deviceTypes.filter(x => x.value === "vpn");

    if (deviceVpnType.length) {
      formData.kiboshDeviceTypeId = deviceVpnType[0].commonValueId;
    }

    formData.kiboshDeviceReference = accountKiboshDevice.kiboshDeviceReference;

    setCurrentAccountKiboshVpnDevice(formData)

    response = await apiCall(AccountKiboshDeviceApi.insertAccountKiboshVpnDevice(formData, abortController.signal));

    if (response.success) {
      setCurrentAccountKiboshVpnDevice({...currentAccountKiboshVpnDevice, kiboshDeviceReference: response.data?.value?.notes })
      message.success("VPN created successfully.");
      setReload(true);

      if (onSave) onSave();

    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  const onVpnTypeSetClick = async (vpnType: string) => {
    let response: ApiResponse;

    setLoading(true);
    let formData = {...currentAccountKiboshVpnDevice};
    formData.notes = vpnType;

    response = await apiCall(DashApi.insertVpnConfig(formData, accountKiboshDevice.kiboshDeviceReference,abortController.signal));

    if (response.success) {
      message.success("VPN Type setted successfully.");

      setCurrentAccountKiboshVpnDevice({...currentAccountKiboshVpnDevice, vpnType: vpnType })
      setIsDeviceAssignedRecently(true);
      if (onSave) onSave();

    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  const getPageAsync = async () => {
    setDataSource(await getPage())
  }

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    if (!accountKiboshDevice?.kiboshDeviceReference || !currentAccountKiboshVpnDevice?.id)
      return [];

    setLoading(true);
    // const response = await apiCall(DashApi.getClientDevices(accountKiboshVpnDevice?.kiboshDeviceReference!, abortSignal));
    const response = await apiCall(DashApi.getClientDevices(accountKiboshDevice?.kiboshDeviceReference!, abortSignal));

    const data = response.success ? AccountDevice.toArrayOfClass(response.data?.value || []) : [];

    setLoading(false);

    return data.filter( x => x.id === vpnAssignedDeviceId);
  };

  const getAccountDevicesAsync = async () => {
    const data = await getAccountDevices({}, abortController.signal);

    // checking if associated device is not a part of incomming data.
    if (currentAccountKiboshVpnDevice?.associatedRouterClientId && !data.filter(x => x.id === currentAccountKiboshVpnDevice?.associatedRouterClientId)) {
      // adding temp data to array of incoming devices to be able to show associated device name in dropdown
      const tempDevice = new AccountDevice();
      tempDevice.id = currentAccountKiboshVpnDevice?.associatedRouterClientId;
      tempDevice.name = currentAccountKiboshVpnDevice?.name;

      data.push(tempDevice);
    }
    setAccountDevices(data);

    if (data.length) {
      // to show only unassigned devices and this vpn device in dropdown
      const otherVpnsAssignedDevicesIds = vpnAssignedDevicesIds.filter(x => x !== vpnAssignedDeviceId)
      setVisibleAccountDevices(data.filter(x => !otherVpnsAssignedDevicesIds.includes(x.id!)));
    }
    else {
      setVisibleAccountDevices([]);
    }
  }

  const getAccountDevices = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
    setLoading(true);
    const response = await apiCall(DashApi.getClientDevices(accountKiboshDevice?.kiboshDeviceReference!, abortSignal));
    setLoading(false);

    if (response.success) {
      return AccountDevice.toArrayOfClass(response.data?.value || []).map((x: any) => {
        return {
          ...x,
          sorter: x.groupId ? Number(x.groupId) : Infinity
        }
      }).sort((a: any, b: any) => a.sorter - b.sorter);

    }
    else {
      return [];
    }
  };

  const getVpnConfigUrl = async () => {
    setLoading(true);
    const response = await apiCall(DashApi.getVpnConfigUrl(
      currentAccountKiboshVpnDevice.kiboshDeviceReference!,
      abortController.signal
    ));
    setLoading(false);

    return response.success ? AccountVpnConfigUrl.toClass(response.data?.value) : null;
  }

  const onAddNewDeviceToGroup = async () => {
    setLoading(true);
    let response: ApiResponse = await apiCall(DashApi.associateClientDeviceToVpn(accountKiboshDevice?.kiboshDeviceReference!, currentAccountDeviceId, currentAccountKiboshVpnDevice.kiboshDeviceReference!, abortController.signal));

    if (response.success) {
      message.success(`Device added successfully.`);
      setCurrentAccountDeviceId("");
      setVpnAssignedDeviceId(currentAccountDeviceId);
      setReload(true);
      if(onSave) onSave();
    } else
      message.error(response.error?.value);

    setLoading(false);
  }

  const onInstallClick = async (event?: any) => {
    if (event) {
      event.stopPropagation();
    }

    var vpnConfigObj = await getVpnConfigUrl();

    if (vpnConfigObj) {
      const urlToPass = encodeURIComponent(vpnConfigObj.url!); // The URL you want to pass
      const iosScheme = `${vpnConfigObj.appName}://open?url=${urlToPass}`;
      const androidScheme = `intent://open?url=${urlToPass}#Intent;scheme=${vpnConfigObj.appName};package=${vpnConfigObj.appPackage};end;`;

      console.log(vpnConfigObj)
      if(isMobile)
      {
        if (isIOS) {
          // iOS
          window.location.href = iosScheme;
        } else if (isAndroid) {
          // Android
          window.location.href = androidScheme;
        } else {
          message.error('This action requires the app to be installed.');
        }
      } else {
        message.error("You need to install this application on mobile")
      }

    }
  };

  const onDownloadConfigClick = async (event?: any) => {
    if (event) {
      event.stopPropagation();
    }
    setLoading(true);
    const response = await apiCall(DashApi.getVpnConfig(
      currentAccountKiboshVpnDevice.kiboshDeviceReference!,
      abortController.signal
    ));
    setLoading(false);

    if (response.success) {
      const blob = new Blob([response.data?.value], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      let extention = "";
      switch (currentAccountKiboshVpnDevice.vpnType?.toLowerCase()) {
        case "ipsec":
          extention = ".mobileconfig"
          break;
        case "openvpn":
          extention = ".ovpn"
          break;
        default:
          break;
      }
      a.href = url;
      a.download = `${currentAccountKiboshVpnDevice.kiboshDeviceName}-config${extention}`;

      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      message.error(response.error?.value);
    }
  };

  // const onDeleteClick = async () => {
  //   let response: ApiResponse;

  //   setLoading(true);

  //   response = await apiCall(DashApi.deleteVpnConfig(currentAccountKiboshVpnDevice.kiboshDeviceReference!, accountKiboshDevice.kiboshDeviceReference, abortController.signal));

  //   if (response.success) {
  //     message.success("VPN Type setted successfully.");
  //     if (onSave) onSave();

  //   } else
  //     message.error(response.error?.value);

  //   setLoading(false);
  // };

  const onDeleteClick = async () => {
    setLoading(true);
    Promise.all([apiCall(DashApi.dissociateClientDeviceFromVpn(accountKiboshDevice?.kiboshDeviceReference!, vpnAssignedDeviceId, abortController.signal)), apiCall(DashApi.deleteVpnConfig(currentAccountKiboshVpnDevice.kiboshDeviceReference!, accountKiboshDevice.kiboshDeviceReference, abortController.signal))]).then(
      (results: any) => {
        if (results[0].success && results[1].success) {
          message.success("VPN Type setted successfully.");
          if (onSave) onSave();
          onCancel();
        }

        setLoading(false);
      }
    );
  };

  return (
    <Popup
      adaptive
      title={ modalTitle || "VPN Device"}
      onCancel={onCancel}
      onClose={onCancel}
      fixSize="large"
      noCommandbar={true}
    >
      {loading && <Loader />}
      <view
        data-scroll=""
        data-space="30"
        data-gap="30">
        <group data-gap="10" data-direction="column">
          <text data-weight="700" data-text-size="xx-large" data-wrap="wrap" data-color="main-dark">VPN Device</text>
          <text data-wrap="wrap" data-length="300" data-line="1.5" data-light="">Here you can manage all your profile devices.</text>
        </group>
        <group data-width="auto" data-position="left" data-align="center" data-gap="10">
          <group data-width="auto" data-position="left" data-align="center" data-gap="10" data-space="10" data-radius="5" data-elevation="1" data-backdrop="">
            <Select
              autoComplete=""
              label="Device"
              labelPosition="left"
              dataLength="320"
              value={vpnAssignedDeviceId || currentAccountDeviceId}
              onChange={(e: any) => setCurrentAccountDeviceId(e)}
              loading={loading}
              allowSearch={true}
              options={
                visibleAccountDevices?.map((item) => {
                  return {
                    text: `${item.name!} ${item.ip ? '(' + item.ip + ')' : ''}`,
                    value: item.id!,
                  };
                }) || []
              }
            />
            {
              !vpnAssignedDeviceId &&
              <group data-width="auto">
                <Button
                  secondary
                  disabled={!currentAccountDeviceId || !!dataSource.length ? true : undefined}
                  icon="add"
                  text="Assign Device to VPN"
                  onClick={onAddNewDeviceToGroup}
                />
              </group>
            }
          </group>
          {
            !vpnAssignedDeviceId &&
            <separator data-vertical=""></separator>
          }
          <Tooltip title="Refresh" arrow={false}>
            <group data-width="auto">
              <Button
                primary
                large
                icon="refresh"
                onClick={() => setReload(true)}
              />
            </group>
          </Tooltip>
        </group>
        {/* <ListView
          dataSource={dataSource}
          view={ListViewType.Block}
          keyField="id"
          data-template="260"
          listProps={{ 'data-gap': '10' }}
          itemComponent={BlockItem}
        /> */}
        {
        (vpnAssignedDeviceId && !currentAccountKiboshVpnDevice.vpnType) &&
          <group data-align="center" data-gap="10" data-direction="column">
            <text>What type of device?</text>
            <Button large rounded outline text="Apple / IOS" onClick={() => onVpnTypeSetClick("ipsec")}/>
            <Button large rounded outline text="Android" onClick={() => onVpnTypeSetClick("openvpn")}/>
          </group>
        }
        {
          (isDeviceAssignedRecently && vpnAssignedDeviceId && currentAccountKiboshVpnDevice.vpnType) &&
          <group data-align="center" data-gap="10" data-direction="column">
            <Button large rounded outline text={`Download your ${currentAccountKiboshVpnDevice.vpnType === "ipsec" ? "iOS" : "Andriod"} VPN`} onClick={onInstallClick}/>
          </group>
        }
        {
          (!isDeviceAssignedRecently && vpnAssignedDeviceId && currentAccountKiboshVpnDevice.vpnType) &&
          <group data-align="center" data-gap="10" data-direction="column">
            <Button large rounded outline text="Re-install" onClick={onInstallClick}/>
            <Button large rounded outline text="Delete" onClick={onDeleteClick}/>
          </group>
        }
      </view>
    </Popup>
  );
}
