// 3rd-party modules
import history from './helpers/routeHelper';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ErrorBoundary } from 'react-error-boundary';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

// project modules
import App from './App';
import ErrorPage from './pages/Error'
import * as Plugins from './plugins';
import './plugins/ignoreElements';
import { store } from './stores';

import './assets/styles/index.less';
import { isDesktop, isIOS, isMobile } from 'react-device-detect';

isMobile && document.documentElement.classList.add("mobile");
isDesktop && document.documentElement.classList.add("desktop");
isIOS && document.documentElement.classList.add("ios");

window.onerror = (msg, url, line, col, error) => {
  // note that col & error are new to the HTML 5 and may not be supported in every browser
  console.error(msg, url, line, col, error);

  // TODO: Report the error via ajax
};

window.onunhandledrejection = (e: PromiseRejectionEvent) => {
  // it's not supported in all browsers solution would be to call this.setState(() => { throw err; }); in catch of your promise
  console.error(e);
};

const persistor = persistStore(store);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Plugins.init();
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/*// @ts-ignore */}
        <HistoryRouter history={history}>
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <App />
          </ErrorBoundary>
        </HistoryRouter>
      </PersistGate>
    </Provider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();
