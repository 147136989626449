// 3rd-party modules
import { message } from 'antd';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

// project modules
import { apiCall } from '../../helpers/apiHelper';
import Loader from "../shared/loader";
import { Password } from '../shared/inputs/input';
import Popup from '../shared/popup/popup';
import yup from '../../plugins/yup';

// apis
import * as AccountApi from '../../apis/accountApi';

// models
import { ApiResponse } from '../../models/response';
import { registerViewModel } from '../../models/types/auth';

type Props = {
  closeOnSave?: boolean;
  modalTitle?: string;
  open: boolean;
  showUsername?: boolean;
  user: any;
  onClose?: () => void;
  onSave?: (user: any) => void;
};

export default function ChangePasswordModel({ closeOnSave = false, modalTitle = "", open, showUsername = false, user, onClose, onSave }: Props) {
  const schema = yup.object().shape({
    password: yup.string().label("Password").required().min(6).max(50).test('user-id provided', 'Please enter password', (value) => (value !== undefined && value?.length > 0) || user.userId !== undefined),
    confirmPassword: yup.string().label("Confirm Password").required().max(50).oneOf([yup.ref('password'), ''], 'Passwords must match')
  });
  const { handleSubmit, reset, control } = useForm<registerViewModel | any>({
    defaultValues: useMemo(() => user, [user]),
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const abortController = new AbortController();

  useEffect(() => {
    if (open) {
      reset(); // this is to make sure form clears on new while undefined props ignored by react-hook-form
    }
  }, [open]);

  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onSubmit: SubmitHandler<registerViewModel> = async (formData: registerViewModel) => {

    setLoading(true);
    let response: ApiResponse;

    if (user.username && formData.password && formData.confirmPassword) {

      response = await apiCall(AccountApi.changePassword({ username: user.username!, password: formData.password }, abortController.signal));


      if (response.success)
        message.success('Password changed successfully.');
      else
        message.error('Password changed faild.');
    }

    if (onSave) onSave(response!.data?.value);

    if (closeOnSave) {
      open = false;

      onCancel();
    }

    setLoading(false);
  };

  return (
    <Popup
      title={ modalTitle || `Change Password${showUsername ? ` - Username: ${user.username}` : ""}`}
      onCancel={onCancel}
      fixSize="auto"
      onClose={onCancel}
      onSave={handleSubmit(onSubmit)}>
      { loading &&
        <Loader />
      }
          <view>
            <view data-scroll>
                <group data-background="" data-space="15"  data-gap="10" data-direction="column">
                  <Password
                    control={control}
                    name="password"
                    label="Password"
                    dataLength="auto"
                    size="large"
                  />
                  <Password
                    control={control}
                    name="confirmPassword"
                    label="Confirm Password"
                    dataLength="auto"
                    size="large"
                  />
                </group>
            </view>
          </view>
    </Popup>
  );
}
