// 3rd-party modules
import { message } from "antd";
import React, { useEffect, useState } from "react";

// project modules
import AccountInternetSchedules from "../internet-schedules/AccountInternetSchedules";
import Checkbox from "../../shared/inputs/checkbox";
import Loader from "../../shared/loader";
import { apiCall } from "../../../helpers/apiHelper";
import { convertToSnakecase } from "../../../helpers/objectHelper";

// apis
import * as DashApi from '../../../apis/dashApi';

// models
import { Account } from '../../../models/account';
import { accountBlockedSiteViewModel } from "../../../models/types/accountBlockedSite";
import { ApiResponse } from "../../../models/response";
import { AccountBlockedSite } from "../../../models/accountBlockedSite";

type Props = {
  account: Account;
  accountApplication: any;
  deviceReference: string;
  accountKiboshDeviceReference: string;
  showTopBlockSection?: boolean;
  closeOnSave?: boolean;
  minimal?: boolean;
  disabled?: boolean;
  open: boolean;
  onClose?: () => void;
  onSave?: () => void;
};

export default function AccountApplicationDetails({ account, accountApplication, deviceReference, accountKiboshDeviceReference, closeOnSave = false, showTopBlockSection = true, open, minimal = false, disabled = false, onClose, onSave }: Props) {
  const [accountSetBlockedSites, setAccountSetBlockedSites] = useState<AccountBlockedSite[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingBlockedSites, setLoadingBlockedSites] = useState(false);
  let abortController = new AbortController();

  useEffect(() => {
    if (open) {
      getAccountSetBlockedSitesAsync();
    }
  }, [open]);

  const onCancel = () => {
    if (onClose) onClose();
  };

  const onSchedulesSave = () => {
    getAccountSetBlockedSitesAsync();
    onModalSave();
  }

  const onModalSave = () => {
    if (onSave) {
      onSave()
    }
    if (closeOnSave) {
      onCancel();
    }
  }

  const getAccountSetBlockedSitesAsync = async () => {
    setAccountSetBlockedSites(await getAccountSetBlockedSites(abortController.signal));
  }

  const getAccountSetBlockedSites = async (abortSignal?: AbortSignal) => {
    setLoadingBlockedSites(true);
    const response = await apiCall(DashApi.getClientDeviceFilters(accountKiboshDeviceReference!, deviceReference, abortSignal));
    setLoadingBlockedSites(false);

    return response.success ? AccountBlockedSite.toArrayOfClass(response.data?.value || []).filter(x => x.matchType === "set") : [];
  };

  const onApplicationBlockedChange = async (event: any) => {
    if (event.target?.checked) {
      const formData: accountBlockedSiteViewModel = {
        matchType: "set",
        deviceId: deviceReference.toString()!,
        kiboshDeviceReference: accountKiboshDeviceReference!,
        match: accountApplication.id!
      }

      let response: ApiResponse;

      setLoading(true);
      const data = convertToSnakecase(formData, [], ["kiboshDeviceReference", "deviceId"]);

      response = await apiCall(DashApi.insertClientDeviceFilter(formData.kiboshDeviceReference, formData.deviceId, data, abortController.signal));

      if (response.success) {
        message.success(`${accountApplication.name} blocked successfully.`);
        getAccountSetBlockedSitesAsync();
        if (onSave) {
          onSave()
        }

      } else
        message.error(response.error?.value);

      setLoading(false);
    } else {
      setLoading(true);
      const formData = accountSetBlockedSites.find(x => x.match?.toLocaleLowerCase() === accountApplication.id?.toLocaleLowerCase());
      if (!formData) {
        message.error(`Item not found.`);
        setLoading(false);
        return;
      }
      const response = await apiCall(DashApi.deleteClientDeviceFilter(accountKiboshDeviceReference!, deviceReference!, formData.id!));
      setLoading(false);

      if (response.success) {
        message.success(`${accountApplication.name} unblocked successfully.`);
        getAccountSetBlockedSitesAsync();
        if (onSave) {
          onSave()
        }
      }
    }
  }


  return (
    <>
      {
        minimal && (loading || loadingBlockedSites) ?
          <group data-space="30" data-gap="30" data-direction="column">
            <Loader />
          </group> :
        ((loading || loadingBlockedSites) && <Loader />)
      }
      {showTopBlockSection && <group data-space="15" data-border="" data-gap="5">
        <group data-width="auto" data-gap="15">
          <Checkbox
            checked={accountSetBlockedSites.map(x => x.match?.toLocaleLowerCase()).includes(accountApplication.id)}
            label="Blocking"
            minimal
            dataLength="auto"
            onChange={onApplicationBlockedChange}
          />
        </group>
      </group>}
      { !minimal &&
        <group data-space="30" data-gap="30" data-direction="column">
          <text data-weight="700" data-text-size="large" data-wrap="wrap" data-color="main-dark">Schedule</text>
        </group>
      }
      {!(loading || loadingBlockedSites) && (
        <group style={disabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
          <AccountInternetSchedules
            refresh={!!account.accountId}
            account={account}
            kiboshDeviceReference={accountKiboshDeviceReference}
            deviceReference={deviceReference}
            filterSetId={accountApplication.id}
            minimal={minimal}
            filterId={accountSetBlockedSites.find(x => x.match === accountApplication.id)?.id || ""}
            onCancel={onCancel}
            onSave={(acc: any) => onSchedulesSave()} />
        </group>
      )}
    </>
  );
}
