// project modules
import { getBaseUrl, sendRequest } from '../helpers/apiHelper';

// models
import { Account } from '../models/account';
import { changePasswordViewModel, accountRegisterViewModel, roleCrudViewModel, userRolesViewModel, accountUpdateViewModel } from "../models/types/auth";
import { DataSourceRequest } from '../models/dataSourceRequest';

export const changePassword = async (account: changePasswordViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/account/changePassword`, 'post', account, null, abortSignal);
};

export const getAccounts = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/account/list`, 'post', request, null, abortSignal);
};

export const insertAccount = async (account: accountRegisterViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/account/register`, 'post', account, null, abortSignal);
};

export const updateAccount = async (account: accountUpdateViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/account`, 'put', account, null, abortSignal);
};

export const deleteAccount = async (account: Account, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/account`, 'delete', account.email, null, abortSignal);
};

export const createRole = async (role: roleCrudViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/account/role`, 'post', role, null, abortSignal);
};

export const updateRole = async (role: roleCrudViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/account/role`, 'put', role, null, abortSignal);
};

export const deleteRole = async (role: roleCrudViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/account/role`, 'delete', role, null, abortSignal);
};

export const addAccountToRoles = async (username: string, roleNames: string[], abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/account/role/account`, 'post', {
    username,
    roleNames
  }, null, abortSignal);
};

export const removeAccountFromRoles = async (userRoles: userRolesViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/account/role/account`, 'delete', userRoles, null, abortSignal);
};

export const getRoles = async (abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/account/role/list`, 'get', null, null, abortSignal);
};

export const getAccountRoles = async (username: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/account/roles/${username}`, 'get', null, null, abortSignal);
};
