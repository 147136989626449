import Button from "../components/shared/button";

export default function NotFoundPage() {
  return (
    <view data-align="center" data-jusify="center" data-border="none">
      <group
        data-width="auto"
        data-direction="column"
        data-align="center"
        data-jusify="center"
      >
        <icon data-icon-size="medium">home</icon>
        <text>Not Found</text>
        <Button primary large rounded>
          <text>Home</text>
        </Button>
      </group>
    </view>
  );
}
