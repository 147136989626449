// 3rd-party modules
import parse from 'html-react-parser';
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

// project modules
import FAQItem from "../components/faq/FAQItem";
import { apiCall } from "../helpers/apiHelper";
import { RootState } from "../stores";
import { sanitizeHtml } from '../helpers/objectHelper';

// apis
import * as PostApi from '../apis/postApi';

// models
import { Post } from "../models/post";

const getCategoryPosts = async (category: string, language: string, abortSignal?: AbortSignal) => {
  const response = await apiCall(PostApi.getCategoryPosts(category, language, abortSignal));

  return response.success ? Post.toArrayOfClass(response.data?.value || []) : [];
};

export default function FAQPage() {
  const [categoryPosts, setCategoryPosts] = useState<Post[]>([]);
  const language = useSelector((state: RootState) => state.user.language);
  const abortController = new AbortController();

  useEffect(() => {
    const getPagesAsync = async () => {
      setCategoryPosts(await getCategoryPosts('faq', language, abortController.signal));
    }

    getPagesAsync();
  }, [language]);

  const datasource = [
    {
      id: 1,
      title: "What is a Kibosh?",
      description: "Mauris dignissim ante quis mi feugiat bibendum. Aliquam scelerisque mi metus, eget consectetur dui sollicitudin at. Maecenas feugiat ut nulla sed auctor. Quisque eu maximus massa. Donec maximus arcu sed risus feugiat, id semper augue sodales. Aliquam imperdiet ex sed quam volutpat, id ultrices ipsum sollicitudin. Mauris sagittis dui sit amet elit cursus pulvinar."
    },
    {
      id: 2,
      title: "What is a Kibosh?",
      description: ""
    }
  ];

  return (
    <>
      <view data-border="none">
        <group
          data-direction="column"
          data-background="main"
          data-dark=""
          data-space-vertical="50"
        >
          <section>
            <wrapper>
              <group data-direction="column" data-gap="10">
                <icon data-icon-size="large">live_help</icon>
                <text
                  data-weight="800"
                  data-text-size="xx-large"
                  data-wrap="wrap"
                >
                  Frequently Asked Questions
                </text>
                <text data-text-size="large" data-wrap="wrap">
                  Welcome, How can we help you?
                </text>
              </group>
            </wrapper>
          </section>
        </group>
        <group data-space-vertical="50">
          <section>
            <wrapper>
              <group data-gap="10">
                {
                  // datasource.map(item =>
                  //   <FAQItem key={item.id} item={item} />
                  // )
                  categoryPosts.map(post => {
                    const item = {
                      id: post.postId,
                      title: post.title,
                      description: parse(sanitizeHtml(post.content || ""))
                    };

                    return <FAQItem key={item.id} item={item} />
                  })
                }
              </group>
            </wrapper>
          </section>
        </group>
      </view>
    </>
  );
}
