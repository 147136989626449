// 3rd-party modules
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { Tooltip, message } from 'antd';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

// project modules
import Badge from '../../components/shared/badge';
import Button from '../../components/shared/button';
import CommentsModal from '../../components/support/help-desk/comment/CommentsModal'
import ConfirmationPopup from '../../components/shared/popup/confirmationPopup';
import DataListView from '../../components/shared/list/dataListView';
import Loader from '../../components/shared/loader';
import SupportTicketModal from '../../components/support/help-desk/SupportTicketModal';
import yup from '../../plugins/yup';
import { apiCall } from '../../helpers/apiHelper';
import { capitalizeFirstLetter, kababCaseToCamelCase } from '../../helpers/objectHelper';
import { ListViewType } from '../../components/shared/list/useListHook';
import { Text } from "../../components/shared/text";

// apis
import * as SupportTicketApi from '../../apis/supportTicketApi';

// models
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from '../../models/dataSourceRequest';
import { SupportTicket } from '../../models/supportTicket';

// defines
import { COMMON_VALUES, CONSTANTS } from '../../helpers/defines';

export default function HelpDeskPage() {
  const schema = yup.object().shape({
    startDate: yup.string().label("Start Date").nullable(),
    endDate: yup.string().label("End Date").nullable(),
    accountNumber: yup.number().label("Account Number").max(100).nullable(),
    accountEmail: yup.string().label("Account Email").email().nullable(),
    ticketCategoryId: yup.string().label("Category").nullable(),
    status: yup.string().label("Status").nullable(),
  });
  const { setValue } = useForm<any>({
    resolver: yupResolver(schema),
  });
  const [advancedSearchFilters, setAdvancedSearchFilters] = useState<FilterDescriptor[]>([{ fieldName: "status", fieldValue: "active" }]);
  const [currentSupportTicket, setCurrentSupportTicket] = useState<SupportTicket>(new SupportTicket());
  const [loading, setLoading] = useState(false);
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [showArchivedTickets, setShowArchivedTickets] = useState(false);
  const [showSupportTicketModal, setShowSupportTicketModal] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const abortController = new AbortController();

  let previousFilters: any[] = [];

  useEffect(() => {
    // set default filters in advanced search section
    for (let filter of advancedSearchFilters) {
      const fieldName = kababCaseToCamelCase(filter.fieldName!);

      setValue(fieldName, filter.fieldValue);
    }
  }, []);

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  useEffect(() => {
    if (showArchivedTickets) {
      previousFilters = [...advancedSearchFilters];

      setAdvancedSearchFilters([{ fieldName: 'status', fieldValue: COMMON_VALUES.SUPPORT_TICKET_STATUS_CLOSED }]);
    }
    else {
      setAdvancedSearchFilters(previousFilters);
    }
    setReload(true)
  }, [showArchivedTickets]);

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    const request = { conditionList, sortList, pagination } as DataSourceRequest;

    setLoading(true);
    const response = await apiCall(SupportTicketApi.getSupportTickets(request, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    return response.success ? SupportTicket.toArrayOfClass(response.data?.value || []) : [];
  };

  const onCommentsClick = (supportTicket: SupportTicket) => {
    setCurrentSupportTicket({...supportTicket});
    setShowCommentsModal(true);
  };

  const onDeleteClick = (supportTicket: SupportTicket) => {
    setCurrentSupportTicket(supportTicket);
    setIsDeleteConfirmationPopupOpen(true);
  };

  const onEditClick = (supportTicket: SupportTicket) => {
    setCurrentSupportTicket({...supportTicket});
    setShowSupportTicketModal(true);
  };

  const onNewClick = () => {
    setCurrentSupportTicket(new SupportTicket());
    setShowSupportTicketModal(true);
  };

  const onSupportTicketSave = (e: SupportTicket) => {
    setReload(true);
  }

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(SupportTicketApi.deleteSupportTicket(currentSupportTicket, abortController.signal));
    setLoading(false);

    if (response.success) {
      message.success(`Support Ticket deleted successfully.`);
      setIsDeleteConfirmationPopupOpen(false);
      setReload(true);
    }
  };

  const onRefresh = () => {
    setReload(true);

    // getPageAsync(pagination.current || 1, pagination.pageSize || Config.defaultTablePageSize);
  }

  const SupportTicketItem: React.FC<{ data: SupportTicket }> = ({ data }) => (
    <group
      data-direction="column"
      data-contain=""
      data-border=""
      data-radius="10"
      data-background="context"
      data-cursor="pointer"
    >
      <group data-direction="column" data-contain="" data-gap="5" data-space="20">
        <Text
          data-weight="700"
          data-ellipsis=""
          data-color="main"
        >
          {data.subject}
        </Text>
        <Text data-wrap="wrap" data-clamp="3" data-line="1.5">
          Ticket #: {data.supportTicketId}
        </Text>
        <Text data-wrap="wrap" data-clamp="3" data-line="1.5">
          Status: <Badge color="blue-grey" text={capitalizeFirstLetter(data.status || '')} />
        </Text>
        <Text data-wrap="wrap" data-clamp="3" data-line="1.5">
          Last Updated: {(data.updatedAt || data.createdAt) && moment(data.updatedAt || data.createdAt).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}
        </Text>
      </group>
      <separator data-horizontal="" data-margin="none"></separator>
      <group data-gap="5" data-space="10" data-position="bottom" data-align="center" data-border="" data-wrap="no">
        <Tooltip title="Details"><div className='button micro' onClick={() => onCommentsClick(data)}><icon>comment</icon></div></Tooltip>
        <separator vertical="" data-adaptive="desktop"></separator>
        <Tooltip title="Edit"><div className='button micro' onClick={() => onEditClick(data)}><icon>edit_square</icon></div></Tooltip>
        <Tooltip title="Delete"><div className='button micro' onClick={() => onDeleteClick(data)}><icon>delete</icon></div></Tooltip>
      </group>
    </group>
  );

  return (
    <>
      {(loading) && <Loader />}
      <view data-vertical="">
        <view
          //data-test=""
          data-scroll=""
          data-space="20"
          data-gap="30">
          <group data-gap="10" data-direction="column">
            <text data-weight="700" data-text-size="xx-large" data-wrap="wrap" data-color="main-dark">{ showArchivedTickets ? "Archived Tickets" : "Tickets"}</text>
            <text data-wrap="wrap" data-length="400" data-line="1.5" data-light="">
            { showArchivedTickets ?
              "This is where you can see your archived tickets." :
              "This is where you can manage your tickets. Add new tickets or track some tickets."
            }
            </text>
          </group>
          <group data-width="auto" data-position="left" data-gap="10" data-align="center" >
            { !showArchivedTickets &&
              <>
                <Tooltip title="Create New" arrow={false}>
                  <group data-width="auto">
                    <Button
                      secondary
                      data-length="50"
                      data-height="50"
                      data-elevation="6"
                      rounded
                      icon="add"
                      onClick={onNewClick}
                    />
                  </group>
                </Tooltip>
                <separator data-vertical=""></separator>
              </>
            }
            <Tooltip title="Refresh" arrow={false}>
              <group data-width="auto">
                <Button
                  secondary
                  data-length="50"
                  data-height="50"
                  data-elevation="6"
                  rounded
                  icon="refresh"
                  onClick={onRefresh}
                />
              </group>
            </Tooltip>
            <separator data-vertical=""></separator>
            <Tooltip title={ showArchivedTickets ? "Tickets" : "Archived Tickets"} arrow={false}>
              <group data-width="auto">
                <Button
                  data-length="50"
                  data-height="50"
                  data-elevation="6"
                  rounded
                  icon={ showArchivedTickets ? "confirmation_number" : "archive"}
                  onClick={() => setShowArchivedTickets(!showArchivedTickets)}
                />
              </group>
            </Tooltip>
          </group>
          <DataListView
            dataPagination={false}
            filters={advancedSearchFilters}
            getPageAsync={getPage}
            keyField="supportTicketId"
            itemComponent={SupportTicketItem}
            lazyLoadPages={true}
            reload={reload}
            totalRecords={totalRecords}
            view={ListViewType.Block}
          />
        </view>
      </view>
      {!!showSupportTicketModal &&
        <SupportTicketModal open={showSupportTicketModal}
          closeOnSave={true}
          supportTicket={currentSupportTicket}
          onClose={() => setShowSupportTicketModal(false) }
          onSave={(e) => onSupportTicketSave(e) } />
      }
      {!!showCommentsModal &&
        <CommentsModal open={showCommentsModal}
          closeOnSave={true}
          supportTicket={currentSupportTicket}
          onClose={() => setShowCommentsModal(false) }
          onSave={() => setReload(true)} />
      }
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
