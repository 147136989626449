// 3rd-party modules
import { isMobile } from "react-device-detect";
import { Tooltip, message } from "antd";
import { useEffect, useState } from "react";

// project modules
import AccountBlockedSiteModal from "./AccountBlockedSiteModal";
import Checkbox, { CheckboxType } from "../../shared/inputs/checkbox";
import Button from "../../shared/button";
import ConfirmationPopup from "../../shared/popup/confirmationPopup";
import DataTable, { TableColumn } from "../../shared/list/dataTable";
import Loader from "../../shared/loader";
import Options from "../../shared/options";
import Select from "../../shared/inputs/select";
import Switchable from "../../shared/additionalComponents/Switchable";
import TabStrip from "../../shared/tabstrip";
import Tab from "../../shared/tabstrip/tab";
import ListView from "../../shared/list/listView";
import Ripple from "../../shared/additionalComponents/Ripple";
import AccountApplicationDetails from "../applications/AccountApplicationDetails";
import { apiCall } from "../../../helpers/apiHelper";
import { convertToSnakecase } from "../../../helpers/objectHelper";
import { getBase64ImageUrl } from "../../../helpers";
import { ListViewType } from "../../shared/list/useListHook";
import { useCacheHook } from "../../../hooks/useCacheHook";

// apis
import * as AccountKiboshDeviceApi from '../../../apis/accountKiboshDeviceApi';
import * as CommonValueApi from '../../../apis/commonValueApi';
import * as DashApi from '../../../apis/dashApi';

// models
import { Account } from "../../../models/account";
import { AccountBlockedSite } from "../../../models/accountBlockedSite";
import { AccountKiboshDevice } from "../../../models/accountKiboshDevice";
import { AccountDevice } from "../../../models/accountDevice";
import { AdminFilterSet } from "../../../models/adminFilterSet";
import { ApiResponse } from "../../../models/response";
import { accountBlockedSiteViewModel } from "../../../models/types/accountBlockedSite";
import { CommonValue } from "../../../models/commonValue";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../models/dataSourceRequest";

type Props = {
  account?: Account;
  deviceReference?: string;
  refresh?: boolean;
  showFilters?: boolean;
  showSchedules?: boolean;
  showCustomTableAsSwitchable?: boolean;
  kiboshDeviceReference?: string;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

export default function AccountBlockedSites({ account, deviceReference, refresh, showFilters = true, showSchedules = true, showCustomTableAsSwitchable = false, kiboshDeviceReference, onCancel, onSave }: Props) {
  const columns: TableColumn<AccountBlockedSite>[] = [
    {
      title: 'Match',
      dataIndex: 'match',
      key: 'match',
      filterable: !isMobile
    },
    {
      title: '',
      key: 'action',
      width: 60,
      render: (_, record: any) => (
        <group data-wrap='no' data-gap="5">
          <Tooltip title="Edit"><div className='button micro' onClick={() => onEditClick(record)}><icon>edit_square</icon></div></Tooltip>
          <Tooltip title="Delete"><div className='button micro' onClick={() => onDeleteClick(record)}><icon>delete</icon></div></Tooltip>
        </group>
      ),
    }
  ];
  const [accountKiboshDevices, setAccountKiboshDevices] = useState<AccountKiboshDevice[]>([]);
  const [currentAccountKiboshDeviceReference, setCurrentAccountKiboshDeviceReference] = useState<string>("");
  const [accountDevices, setAccountDevices] = useState<AccountDevice[]>([]);
  const [adminFilterSets, setAdminFilterSets] = useState<AdminFilterSet[]>([]);
  const [accountSetBlockedSites, setAccountSetBlockedSites] = useState<AccountBlockedSite[]>([]);
  const [cachedData, setCachedData] = useCacheHook();
  const [currentAccountDeviceReference, setCurrentAccountDeviceReference] = useState<string>("");
  const [currentAccountBlockedSite, setCurrentAccountBlockedSite] = useState<AccountBlockedSite>(new AccountBlockedSite());
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAccountKiboshDevices, setLoadingAccountKiboshDevices] = useState(false);
  const [loadingAccountDevices, setLoadingAccountDevices] = useState(false);
  const [loadingAdminFilterSets, setLoadingAdminFilterSets] = useState(false);
  const [loadingAccountSetBlockedSites, setLoadingAccountSetBlockedSites] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [regetFullData, setRegetFullData] = useState<boolean>(false);
  const [showAccountBlockedSiteModal, setShowAccountBlockedSiteModal] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [expandedItemId, setExpandedItemId] = useState<string>("");
  const [visibleAdminFilterSets, setVisibleAdminFilterSets] = useState<AdminFilterSet[]>([]);
  const [categories, setCategories] = useState<CommonValue[]>([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  let abortController = new AbortController();

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  useEffect(() => {
    if (refresh) setRegetFullData(true);
  }, [refresh]);

  useEffect(() => {
    if (deviceReference) setRegetFullData(true);
  }, [deviceReference]);

  useEffect(() => {
    if (regetFullData) {
      if (!kiboshDeviceReference) {
        getAccountKiboshDevicesAsync();
      } else if (!deviceReference) {
        getAccountDevicesAsync();
      }

      if (showFilters || showSchedules) {
        getKiboshDeviceFilterSetsAsync();
      }
      setRegetFullData(false);
    }
  }, [regetFullData]);

  useEffect(() => {
    if (selectedCategory?.toString().toLocaleLowerCase() === "other") {
      setVisibleAdminFilterSets(
        adminFilterSets.filter( x=> !x.category || x.category?.toLocaleLowerCase() === "other")
      )
    } else {
      setVisibleAdminFilterSets(
        adminFilterSets.filter( x=> x.category?.toLocaleLowerCase() === selectedCategory?.toString().toLocaleLowerCase())
      )
    }
  }, [selectedCategory]);

  const getCategories = async (abortSignal?: AbortSignal) => {
    setLoadingCategories(true);
    const response = await apiCall(CommonValueApi.getApplicationCategories(abortSignal));
    setLoadingCategories(false);

    return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
  };

  const getKiboshDeviceFilterSetsAsync = async (deviceRef?: string) => {
    Promise.all([getAccountSetBlockedSites(deviceRef, abortController.signal), getKiboshDeviceFilterSets(abortController.signal), getCategories(abortController.signal)]).then(
      (results: any) => {
        const blockedSites = results[0];
        const applications = results[1];
        const categories = results[2];

        setAccountSetBlockedSites(blockedSites);

        const returnValue = applications.map((app: any) => {
          return {
            ...app,
            isBlocked: blockedSites.filter((x: any) => !x.schedule).map((x: any) => x.match).includes(app.id),
            isScheduled: blockedSites.filter((x: any) => x.schedule?.length).map((x: any) => x.match).includes(app.id)
          }
        });

        setAdminFilterSets(returnValue);
        setCategories(categories);

        if (categories.length) {
          setSelectedCategory(categories[0].value)
        }
      }
    );
  }

  const getKiboshDeviceFilterSets = async (abortSignal?: AbortSignal) => {
    if (cachedData['KiboshDeviceFilterSets'] != null && cachedData['KiboshDeviceFilterSets'].length > 0) return cachedData['KiboshDeviceFilterSets'];

    setLoadingAdminFilterSets(true);
    const response = await apiCall(DashApi.getKiboshDeviceFilterSets(kiboshDeviceReference ? kiboshDeviceReference : currentAccountKiboshDeviceReference, abortSignal));
    setCachedData('KiboshDeviceFilterSets', AdminFilterSet.toArrayOfClass(response.data?.value || []).sort((a: AdminFilterSet, b: AdminFilterSet) => (a.displayOrder || 0) - (b.displayOrder || 0)));
    setLoadingAdminFilterSets(false);

    return response.success ? AdminFilterSet.toArrayOfClass(response.data?.value || []) : [];
  };

  const getAccountSetBlockedSitesAsync = async () => {
    setAccountSetBlockedSites(await getAccountSetBlockedSites(undefined, abortController.signal));
  }

  const getAccountSetBlockedSites = async (deviceRef?: string, abortSignal?: AbortSignal) => {
    setLoadingAccountSetBlockedSites(true);
    const response = await apiCall(DashApi.getClientDeviceFilters(kiboshDeviceReference ? kiboshDeviceReference : currentAccountKiboshDeviceReference, deviceRef || deviceReference || currentAccountDeviceReference, abortSignal));
    setLoadingAccountSetBlockedSites(false);

    return response.success ? AccountBlockedSite.toArrayOfClass(response.data?.value || []).filter(x => x.matchType === "set") : [];
  };

  const getAccountDevicesAsync = async (routerRef?: string) => {
    const allOption = new AccountDevice();
    allOption.name = "All";
    allOption.id = "All";
    setAccountDevices([allOption, ...await getAccountDevices(routerRef, abortController.signal)]);
  }

  const getAccountDevices = async (routerRef?: string, abortSignal?: AbortSignal) => {
    setLoadingAccountDevices(true);
    const response = await apiCall(DashApi.getClientDevices(kiboshDeviceReference || currentAccountKiboshDeviceReference || routerRef!, abortSignal));
    setLoadingAccountDevices(false);

    if (response.success) {
      return AccountDevice.toArrayOfClass(response.data?.value || []).map((x: any) => {
        return {
          ...x,
          sorter: x.groupId ? Number(x.groupId) : Infinity
        }
      }).sort((a: any, b: any) => a.sorter - b.sorter);

    }
    else {
      return [];
    }
  };

  const getAccountKiboshDevicesAsync = async () => {
    setAccountKiboshDevices(await getAccountKiboshDevices({}, abortController.signal));
  }

  const getAccountKiboshDevices = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
    setLoadingAccountKiboshDevices(true);
    const response = await apiCall(AccountKiboshDeviceApi.getAccountKiboshDevices(account?.accountId!, request, abortSignal));
    setLoadingAccountKiboshDevices(false);

    return response.success ? AccountKiboshDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    if (!currentAccountDeviceReference && !deviceReference)
      return [];

    setLoading(true);
    const response = await apiCall(DashApi.getClientDeviceFilters(kiboshDeviceReference ? kiboshDeviceReference : currentAccountKiboshDeviceReference, deviceReference ? deviceReference : currentAccountDeviceReference, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    return response.success ? AccountBlockedSite.toArrayOfClass(response.data?.value || []).filter(x => x.matchType !== "set") : [];
  };

  const onAccountBlockedSiteSave = (e: Account) => {
    setReload(true);
  }

  const onAccountKiboshDeviceChange = (kiboshDeviceReference: any) => {
    setCurrentAccountKiboshDeviceReference(kiboshDeviceReference);
    getAccountDevicesAsync(kiboshDeviceReference);
  }

  const onAccountDeviceChange = (deviceReference: any) => {
    setCurrentAccountDeviceReference(deviceReference);

    if (showFilters || showSchedules) {
      getKiboshDeviceFilterSetsAsync(deviceReference);
    }

    setReload(true);
  }

  const onEditClick = (accountBlockedSite: AccountBlockedSite) => {
    setCurrentAccountBlockedSite({ ...accountBlockedSite });
    setShowAccountBlockedSiteModal(true);
  };

  const onNewClick = () => {
    setCurrentAccountBlockedSite(new AccountBlockedSite());
    setShowAccountBlockedSiteModal(true);
  };

  const onDeleteClick = (accountBlockedSite: AccountBlockedSite) => {
    setCurrentAccountBlockedSite(accountBlockedSite);
    setIsDeleteConfirmationPopupOpen(true);
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(DashApi.deleteClientDeviceFilter(kiboshDeviceReference || currentAccountKiboshDeviceReference!, deviceReference || currentAccountDeviceReference, currentAccountBlockedSite.id!));
    setLoading(false);

    if (response.success) {
      message.success(`Account Blocked Site deleted successfully.`);
      setIsDeleteConfirmationPopupOpen(false);
      setReload(true);
    }
  };

  const onFilterCheckboxChange = async (item: AdminFilterSet, event: any) => {
    if (event.target?.checked) {
      const formData: accountBlockedSiteViewModel = {
        matchType: "set",
        deviceId: deviceReference || currentAccountDeviceReference,
        kiboshDeviceReference: kiboshDeviceReference || currentAccountKiboshDeviceReference,
        match: item.id!
      }

      let response: ApiResponse;

      setLoadingAccountSetBlockedSites(true);
      const data = convertToSnakecase(formData, [], ["kiboshDeviceReference", "deviceId"]);

      response = await apiCall(DashApi.insertClientDeviceFilter(formData.kiboshDeviceReference, formData.deviceId, data, abortController.signal));

      if (response.success) {
        message.success(`Blocked Site setted successfully.`);
        getAccountSetBlockedSitesAsync();

      } else
        message.error(response.error?.value);

      setLoadingAccountSetBlockedSites(false);
    } else {
      setLoadingAccountSetBlockedSites(true);
      const formData = accountSetBlockedSites.find(x => x.match?.toLocaleLowerCase() === item.id?.toLocaleLowerCase());
      if (!formData) {
        message.error(`Item not found.`);
        setLoadingAccountSetBlockedSites(false);
        return;
      }
      const response = await apiCall(DashApi.deleteClientDeviceFilter(kiboshDeviceReference || currentAccountKiboshDeviceReference!, deviceReference || currentAccountDeviceReference, formData.id!));
      setLoadingAccountSetBlockedSites(false);

      if (response.success) {
        message.success(`Account Blocked Site deleted successfully.`);
        setLoadingAccountSetBlockedSites(true);
        getAccountSetBlockedSitesAsync();
      }
    }
  }

  const onItemClick = (data: any) => {
    setExpandedItemId(expandedItemId === data.id ? "" : data.id);
  }

  const ListItem: React.FC<{ data: any }> = ({ data }) => (
    <>
      <div data-background="white" data-type="group" className={expandedItemId === data.id ? "show" : ""}>
        <Ripple>
          <group data-name="list_header"
          //data-test=""
          //data-radius="5"
          data-contain=""
          onClick={() => onItemClick(data)}
          >
            <group data-align="center" data-gap="5" data-wrap="no" data-cursor="pointer"
            //data-background="white"
            //data-space="5"
            data-space-horizontal="15"

            >
              <group data-ratio="1:1" data-length="40" data-height='40' data-shrink="no" data-index="1" data-radius="5" data-color="main">
              {data.icon && <group data-length="40" data-radius="40" data-contain="" data-height="40">
                  <picture data-object-fit="cover">
                    <img src={data.icon && data.icon.toString().startsWith('data:image') ? data.icon : `data:image/png;base64,${data.icon}`} alt="Filter Set Icon" />
                  </picture>
                </group>}
              </group>
              <group data-direction="column" data-contain="" data-space="5" data-index="1">
                <text data-weight="700" data-ellipsis="">{data.name}</text>
                <group data-wrap="no" data-contain="" data-name="marquee_cont">
                  <group data-marquee="" data-width="auto" data-max-length="auto">
                    <text data-weight="700" data-color="main" data-text-size="10" >
                      {!!accountSetBlockedSites.filter((x: any) => !x.schedule).map(x =>
                        x.match?.toLocaleLowerCase()).includes(data.id) ?
                          (
                            data.id.includes("_safe") ?
                            "Enabled" :
                            "Blocked"
                          )
                        : ""}
                    </text>
                  </group>
                </group>
              </group>


              <group data-align="center" data-wrap="no" data-position="right" data-width="auto" data-index="1">
                { !!accountSetBlockedSites.filter((x: any) => x.schedule?.length).map(x => x.match?.toLocaleLowerCase()).includes(data.id) &&
                <group data-space="10">
                  <icon data-opacity="30">schedule</icon>
                </group>}
                <group data-space="10">
                  <icon data-opacity="30" data-interact-ready="rotate-180">keyboard_arrow_down</icon>
                </group>
              </group>
            </group>
          </group>
        </Ripple>

        <group data-name="list_cont">
          {expandedItemId === data.id &&
            <AccountApplicationDetails
              open={expandedItemId === data.id}
              minimal={true}
              account={account!}
              showTopBlockSection={false}
              deviceReference={deviceReference!}
              accountKiboshDeviceReference={kiboshDeviceReference || currentAccountKiboshDeviceReference!}
              accountApplication={data}
              disabled={!!accountSetBlockedSites.filter((x: any) => !x.schedule).map(x => x.match?.toLocaleLowerCase()).includes(data.id)}
              onSave={() => getAccountSetBlockedSitesAsync()}
            />
          }
        </group>
      </div>
    </>
  );

  const renderApplicationFiltersets = () => (
    <>
      {/* <group data-wrap="no" data-space="10" data-align="center" data-gap="5" >
        <text data-ellipsis="" data-index="1" data-weight="700">Filters</text>
      </group> */}
      {(loadingAdminFilterSets || loadingAccountSetBlockedSites) && <Loader />}
      <group
      data-gap="1"
      data-direction="column"
      >
        {visibleAdminFilterSets.map((item) =>
          <group key={item.id} data-background="white" data-height="50">
            {/* {index > 0 && <separator data-horizontal="" data-margin="none"></separator>} */}
            <label className={accountSetBlockedSites.filter((x: any) => !x.schedule).map(x => x.match?.toLocaleLowerCase()).includes(item.id) ? "checked" : "unchecked"} data-type="group" data-wrap="no" data-space="5" data-space-horizontal="15" data-align="center" data-cursor="pointer" data-interactive="" data-contain="" data-min-height="50">
              <group data-position="left" data-align="center" data-wrap="no" data-contain="" data-gap="10">
                {item.icon &&
                  <group data-length="40" data-radius="40" data-height='40' data-contain="">
                    <picture data-object-fit='cover'>
                      <img src={getBase64ImageUrl(item.icon)} alt="Filter Set Icon" />
                    </picture>
                  </group>
                }
                <group data-length="autofit" data-direction="column" data-contain="" >
                  <text data-weight="700" data-text-size="" data-ellipsis="">{item.name}</text>
                  {
                    !!accountSetBlockedSites.filter((x: any) => !x.schedule).map(x => x.match?.toLocaleLowerCase()).includes(item.id) &&
                    <text data-weight="700" data-color="main" data-text-size="10" >
                      {item.id?.includes("_safe") ?
                      "Enabled" :
                      "Blocked"}
                      </text>
                  }
                </group>
              </group>
              <Checkbox

                data-position="right"
                checked={accountSetBlockedSites.filter((x: any) => !x.schedule).map(x => x.match?.toLocaleLowerCase()).includes(item.id)}
                onChange={(event: any) => onFilterCheckboxChange(item, event)}
                simple
              />
            </label>
            <separator horizontal="" data-margin="none"></separator>
          </group>
        )}</group>
    </>
  )

  const renderApplicationSchedules = () => (
    <>
      {/* <group data-wrap="no" data-space="10" data-align="center" data-gap="5" >
        <text data-ellipsis="" data-index="1" data-weight="700">Schedules</text>
      </group> */}
      {(loadingAdminFilterSets || loadingAccountSetBlockedSites || loadingCategories) && <Loader />}
      <group>
        <ListView
          data-template="400"
          view={ListViewType.List}
          dataSource={visibleAdminFilterSets}
          keyField="id"
          listProps={{ "data-gap": "1" }}
          //listProps={{ "data-space-horizontal":"15" }}
          //data-space="5"
          //listProps={{ "data-gap": "1", "data-space-horizontal":"10" }}
          itemComponent={ListItem}
        />
      </group>
    </>
  )

  const renderCustomFiltersSection = () => (
    <view
      //data-scroll=""
      >
        <group
          data-align="center"
          data-gap="20"
          data-elevation="1"
          data-index="2"
          data-backdrop=""
          data-wrap="no"
          data-sticky="top"
          data-space="10"
        >
          <Button
            material
            icon="add"
            text="New"
            primary
            onClick={onNewClick}
          />
          <Button
            data-position="right"
            material
            icon="refresh"
            text="Refresh"
            outline
            onClick={() => setReload(true)}
          />
        </group>
        <view className="table_cont">
          <DataTable columns={columns}
            bordered={true}
            customPagination={true}
            customPageSize={25}
            reload={reload}
            dataPagination={true}
            getPageAsync={getPage}
            loading={loading}
            rowKey={record => `${record.accountBlockedSiteId}`}
            showTotal={false}
            size="small"
            totalRecords={totalRecords}
            onRow={(record) => ({
              onDoubleClick: () => onEditClick(record),
              style: { cursor: "pointer" },
            })}
          />
        </view>
      </view>
  );

  const renderApplicationsSection = () => (
    <group>
      <group data-border="inset">
        <group
          // data-width='auto'
          data-gap='10'
          data-align='center'>
          <Options className="fit large square" bar={true}>
            {
              categories.map(item => (
                <Checkbox checkboxType={CheckboxType.Button} label={item.valueCaption} checked={item.value === selectedCategory} onClick={()=> setSelectedCategory(item.value!)} />
              ))
            }
          </Options>
        </group>
      </group>
      <TabStrip
        id="applications-switchable-tab"
        selectedIndex={selectedTabIndex}
        onChange={setSelectedTabIndex}>
        {showFilters && <Tab title='Filters'>
          {renderApplicationFiltersets()}
        </Tab>}
        {showSchedules && <Tab title='Schedules'>
          {renderApplicationSchedules()}
        </Tab>}
      </TabStrip>
    </group>
  )

  return (
    <>
      {loading &&
        <Loader />
      }
      <view data-direction="column">
        {(!kiboshDeviceReference || !deviceReference) &&
          <group data-space="15" data-gap="20" data-border="">
            {!kiboshDeviceReference && <>
              <Select
                autoComplete=""
                label="Kibosh Device"
                labelPosition="left"
                dataLength="320"
                onChange={onAccountKiboshDeviceChange}
                loading={loadingAccountKiboshDevices}
                allowSearch={true}
                options={
                  accountKiboshDevices?.map((item) => {
                    return {
                      text: `${item.kiboshDeviceName!} (${item.kiboshDeviceReference!})`,
                      value: item.kiboshDeviceReference!,
                    };
                  }) || []
                }
                button={
                  <Button
                    material
                    icon="refresh"
                    onClick={getAccountKiboshDevicesAsync}
                  />
                }
              />
            </>}
            {!deviceReference && <>
              <Select
                autoComplete=""
                label="Device"
                labelPosition="left"
                dataLength="320"
                onChange={onAccountDeviceChange}
                loading={loadingAccountDevices}
                allowSearch={true}
                options={
                  accountDevices?.map((item) => {
                    return {
                      text: `${item.name!} ${item.ip ? '(' + item.ip + ')' : ''}`,
                      value: item.id!,
                    };
                  }) || []
                }
                button={
                  <Button
                    material
                    icon="refresh"
                    onClick={getAccountDevicesAsync}
                  />
                }
              />
            </>}
          </group>}

        <view data-direction="row">
          {((!showCustomTableAsSwitchable && (showFilters || showSchedules)) || showCustomTableAsSwitchable) &&
            <>
              <Switchable
                icon="tune"
                data-index="3"
                data-type="adaptive"
                data-placement="left"
                //data-size="small"
                defaultExpanded={false}
                title={showCustomTableAsSwitchable ? "Website’s" : "App’s"}>
                { showCustomTableAsSwitchable ? renderCustomFiltersSection() : renderApplicationsSection()}
              </Switchable>
              <group data-name="switch-gap" data-shrink="no"></group>
            </>}
          <view data-name="switchable" data-direction="column" data-align="start" data-scroll="">
          { showCustomTableAsSwitchable ? renderApplicationsSection() : renderCustomFiltersSection()}
          </view>
        </view>
      </view>
      {!!showAccountBlockedSiteModal &&
        <AccountBlockedSiteModal open={showAccountBlockedSiteModal}
          account={account!}
          closeOnSave={true}
          deviceReference={deviceReference || currentAccountDeviceReference}
          kiboshDeviceReference={kiboshDeviceReference || currentAccountKiboshDeviceReference}
          accountBlockedSite={currentAccountBlockedSite}
          onClose={() => setShowAccountBlockedSiteModal(false)}
          onSave={(e) => onAccountBlockedSiteSave(e)} />
      }
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
