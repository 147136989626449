// 3rd-party modules
import moment from "moment-timezone";
import { isMobile } from "react-device-detect";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// project modules
import Button from "../../shared/button";
import DataTable, { TableColumn } from "../../shared/list/dataTable";
import Loader from "../../shared/loader";
import Select from "../../shared/inputs/select";
import { apiCall } from "../../../helpers/apiHelper";

// apis
import * as AccountKiboshDeviceApi from '../../../apis/accountKiboshDeviceApi';
import * as DashApi from '../../../apis/dashApi';

// models
import { Account } from "../../../models/account";
import { AccountKiboshDevice } from "../../../models/accountKiboshDevice";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../models/dataSourceRequest";
import { AccountDevice } from "../../../models/accountDevice";

// defines
import { CONSTANTS } from "../../../helpers/defines";

type Props = {
  account?: Account;
  deviceReference?: string;
  deviceGroupReference?: string;
  kiboshDeviceReference?: string;
  refresh?: boolean;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

export default function AccountDeviceMalwareLogs({ account, deviceReference, deviceGroupReference, kiboshDeviceReference, refresh, onCancel, onSave }: Props) {
  const columns: TableColumn<any>[] = [
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
      filterable: !isMobile,
      render: (text: any) => <text data-wrap="overflow-wrap">{text}</text>
      //  width: 250
    },
    {
      title: 'Last Time',
      dataIndex: 'lastQueried',
      key: 'lastQueried',
      filterable: !isMobile,
      width: 140,
      dataType: 'datetime',
      render: (text: any) => <>{moment(text).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}</>
    },
    {
      title: '',
      key: 'action',
      width: 60,
      render: (_, record: any) => (
        <group data-wrap='no' data-gap="5">
          <Tooltip title="Malware Fix"><div className='button micro' onClick={() => onFixClick(record)}><icon>reset_wrench</icon></div></Tooltip>
        </group>
      ),
    }
  ];

  const [accountKiboshDevices, setAccountKiboshDevices] = useState<AccountKiboshDevice[]>([]);
  const [accountDevices, setAccountDevices] = useState<AccountDevice[]>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [availableDates, setAvailableDates] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [visibleDataSource, setVisibleDataSource] = useState<any[]>([]);
  const [currentAccountKiboshDeviceReference, setCurrentAccountKiboshDeviceReference] = useState<string>("");
  const [currentAccountDeviceReference, setCurrentAccountDeviceReference] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [loadingAccountKiboshDevices, setLoadingAccountKiboshDevices] = useState(false);
  const [loadingAccountDevices, setLoadingAccountDevices] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const navigate = useNavigate();
  let abortController = new AbortController();

  useEffect(() => {
    if (reload) {
      getPageAsync();
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    if (refresh) {
      if (!kiboshDeviceReference) {
        getAccountKiboshDevicesAsync();
      } else if (!deviceReference) {
        getAccountDevicesAsync();
      } else {
        setReload(true);
      }
    }
  }, [refresh]);

  useEffect(() => {
    setReload(true);
  }, [currentAccountDeviceReference]);

  const getAccountDevicesAsync = async (routerRef?: string) => {
    setAccountDevices(await getAccountDevices(routerRef, abortController.signal));
  }

  const getAccountDevices = async (routerRef?: string, abortSignal?: AbortSignal) => {
    setLoadingAccountDevices(true);
    const response = await apiCall(DashApi.getClientDevices(kiboshDeviceReference || currentAccountKiboshDeviceReference || routerRef!, abortSignal));
    setLoadingAccountDevices(false);

    let data = response.success ? AccountDevice.toArrayOfClass(response.data?.value || []) : [];

    if (deviceGroupReference) {
      data = data.filter(x => x.groupId === deviceGroupReference);
    }

    return deviceGroupReference ? data : data.map((x: any) => {
      return {
        ...x,
        sorter: x.groupId ? Number(x.groupId) : Infinity
      }
    }).sort((a: any, b: any) => a.sorter - b.sorter);
  };

  const getAccountKiboshDevicesAsync = async () => {
    setAccountKiboshDevices(await getAccountKiboshDevices({}, abortController.signal));
  }

  const getAccountKiboshDevices = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
    setLoadingAccountKiboshDevices(true);
    const response = await apiCall(AccountKiboshDeviceApi.getAccountKiboshDevices(account?.accountId!, request, abortSignal));
    setLoadingAccountKiboshDevices(false);

    return response.success ? AccountKiboshDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getPageAsync = async () => {
    const data = await getPage();

    const sortedDates = data.map(x => moment(x.lastQueried)).sort((a: any, b: any) => a.diff(b)).map(x => x.format(CONSTANTS.DEFAULT_DATE_FORMAT));
    const uniqueDates = Array.from(new Set(sortedDates));

    setDataSource(data);
    setAvailableDates(uniqueDates);
    if (uniqueDates.length) {
      setSelectedDate(uniqueDates[0]);
      const visibleDate = data.filter(x => moment(x.lastQueried).format(CONSTANTS.DEFAULT_DATE_FORMAT) == uniqueDates[0]);
      setVisibleDataSource(visibleDate);
      setTotalRecords(visibleDate.length);
    }
  }

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    setLoading(true);

    let response = await apiCall(DashApi.getClientDeviceById(kiboshDeviceReference || currentAccountKiboshDeviceReference, deviceReference || currentAccountDeviceReference, abortSignal));;
    let device = response.success ? AccountDevice.toClass(response.data?.value || null) : null;
    // if (device) {
    //   setTotalRecords(device.detectedMalware?.length || 0);
    // }
    setLoading(false);

    return response.success && device ? device.detectedMalware! : [];
  };

  const onAccountKiboshDeviceChange = (kiboshDeviceReference: any) => {
    setCurrentAccountKiboshDeviceReference(kiboshDeviceReference);
    getAccountDevicesAsync(kiboshDeviceReference);
  }

  const onAccountDeviceChange = (deviceReference: any) => {
    setCurrentAccountDeviceReference(deviceReference);
  }

  const onFixClick = (item: any) => {
    navigate("");
  }

  const onLogDaysChange = (logDay: any) => {
    setSelectedDate(logDay);
    const visibleDate = dataSource.filter(x => moment(x.lastQueried).format(CONSTANTS.DEFAULT_DATE_FORMAT) == logDay);
    setVisibleDataSource(visibleDate);
    setTotalRecords(visibleDate.length);
  }

  return (
    <>
      {loading && <Loader />}
      <view>
        {(!kiboshDeviceReference || !deviceReference) && (
          <>
            <group data-space="15" data-border="" data-gap="5">
              <group data-width="auto" data-gap="15">
                {!kiboshDeviceReference && (
                  <>
                    <Select
                      autoComplete=""
                      label="Kibosh Device"
                      labelPosition="left"
                      dataLength="320"
                      onChange={onAccountKiboshDeviceChange}
                      loading={loadingAccountKiboshDevices}
                      allowSearch={true}
                      options={
                        accountKiboshDevices?.map((item) => {
                          return {
                            text: `${item.kiboshDeviceName!} (${item.kiboshDeviceReference!})`,
                            value: item.kiboshDeviceReference!,
                          };
                        }) || []
                      }
                      button={
                        <Button
                          material
                          icon="refresh"
                          onClick={getAccountKiboshDevicesAsync}
                        />
                      }
                    />
                  </>
                )}
                {!deviceReference && (
                  <>
                    <Select
                      autoComplete=""
                      disabled={
                        loadingAccountDevices ||
                        !(
                          kiboshDeviceReference ||
                          currentAccountKiboshDeviceReference
                        )
                      }
                      label="Device"
                      labelPosition="left"
                      dataLength="350"
                      onChange={onAccountDeviceChange}
                      loading={loadingAccountDevices}
                      allowSearch={true}
                      options={
                        accountDevices?.map((item) => {
                          return {
                            text: `${item.name!} ${item.ip ? '(' + item.ip + ')' : ''}`,
                            value: item.id!,
                          };
                        }) || []
                      }
                      button={
                        <Button
                          material
                          icon="refresh"
                          onClick={getAccountDevicesAsync}
                        />
                      }
                    />
                  </>
                )}
              </group>
            </group>
          </>
        )}
        <view className="table_cont" data-background="none">
          <group
            data-space="15"
            data-gap="20"
            data-border=""
            data-background="highlight"
          >
            <Select
              value={selectedDate}
              autoComplete=""
              label="Display Logs For"
              labelPosition="left"
              dataLength="forcefit"
              dataMinLength="350"
              onChange={onLogDaysChange}
              allowSearch={true}
              options={availableDates}
            />
            <Button
              data-position="right"
              material
              icon="refresh"
              text="Refresh"
              outline
              onClick={() => setReload(true)}
            />
          </group>
          <DataTable
            tableLayout={"auto"}
            ellipsis={true}
            columns={columns}
            bordered={true}
            // customPagination={true}
            // reload={reload}
            // dataPagination={true}
            dataSource={visibleDataSource}
            loading={loading}
            showTotal={true}
            size="small"
            rowKey={record => `${record.domain}`}
            totalRecords={totalRecords}
          />
        </view>
      </view>
    </>
  );
}
