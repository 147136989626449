// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, Transform, plainToInstance } from 'class-transformer';

export class CommonValue {
  @Expose()
  public commonValueId?: number;
  @Expose()
  public commonCode?: string;
  @Expose()
  public type?: string;
  @Expose()
  public value?: string;
  @Expose()
  @Transform(({ value, key, obj }) => value || obj.value)
  public valueCaption?: string;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(CommonValue, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(CommonValue, camelcaseKeys(data, { deep: true }) as CommonValue[], { excludeExtraneousValues: true });
  }
}
