// 3rd-party modules
import { ReactElement } from "react";

// defines
type Props = {
  children?: React.ReactNode;
  title?: string;
  value?: string;
  secondaryValue?: string;
  description?: string;
  height?: string;
  headerButtonsContainer?: ReactElement;
  childSpace?: string;
};

export default function DashboardWidget({ children, description, secondaryValue, value, title, headerButtonsContainer, height = "300", childSpace="30" }: Props) {
  return (
    <>
      <group data-radius='20' data-background='main-background' data-border='' data-contain="" data-space-vertical='20' data-test="">
        {title && (
        <group data-gap='10' data-space-horizontal='20'>
          <group data-align='center'>
            <text data-ellipsis="" data-index="1" data-weight="700">{title}</text>
            {
              headerButtonsContainer
            }
          </group>
          <separator horizontal=""></separator>
        </group>
        )}
        <group data-scroll='' data-gap='10' data-height={height} 
        data-space='20'>
          {(value || secondaryValue || description) && (
          <group data-direction='column' data-gap='10'>
            {value && <text data-weight="700" data-text-size="xx-large">{value}</text>}
            {secondaryValue && <text>{secondaryValue}</text>}
            {description && <text data-light="" data-wrap="preline" data-ellipsis=''>{description}</text>}
          </group>
          )}
          {children && <group data-justify="end" data-align='center' data-direction='column' 
          //data-space={childSpace}
          >{children}</group>}
        </group>
      </group>
    </>
  );
}
