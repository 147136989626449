import { Popover, PopoverProps } from "antd";
import { useState } from "react";
import ContextMenuList, { ContextMenuItem } from "./contextMenuList";

type ContextMenuProps = PopoverProps & {
  items: ContextMenuItem[];
};

const ContextMenu: React.FC<ContextMenuProps> = ({
  items,
  ...popoverProps
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popover
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
      }}
      arrow={false}
      content={
        <ContextMenuList onClick={() => setIsOpen(false)} items={items} />
      }
      {...popoverProps}
    />
  );
};

export default ContextMenu;
