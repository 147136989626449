// 3rd-party modules
import { forwardRef, useRef, useState } from 'react';

// project modules
import Button from '../shared/button';
import Input from '../shared/inputs/input';

// models
import Ripple from '../shared/additionalComponents/Ripple';
import { Attachment } from "../../models/attachment";
import { getStringFirstLetters } from '../../helpers/objectHelper';
import { SvgDoodle } from '../svg';

type Props = {
  item: Attachment;
  allowedExtensions?: string;
  allowedSize?: number; // bytes
  placeholder?: string;
  showPreview?: boolean;
  showDoodle?: boolean;
  textForPreview?: string;
  onChange: (doc: Attachment) => void;
  onError?: (err: Error) => void;
};

const AttachmentItem = forwardRef<HTMLInputElement, Props>(function AttachmentItem({
  item,
  allowedExtensions,
  allowedSize,
  placeholder,
  showPreview = false,
  showDoodle = false,
  textForPreview,
  onChange,
  onError
}: Props, parentRef) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [attachment, setAttachment] = useState<Attachment>(item);

  const convertFileToBase64 = (file: any): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const onUploadDocumentChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      if (allowedSize && (file.size > allowedSize)) {
        if (onError) onError(new Error("Max. allowed file size exceeded."))
        else throw new Error("Max. allowed file size exceeded.");
        return;
      }

      const base64: string | ArrayBuffer | null = await convertFileToBase64(file);

      item.originalFileName = file.name;
      item.fileBase64 = base64?.toString(); // .substring(base64?.toString().lastIndexOf(',') + 1); // remove header info.
      item.file = file;

      setAttachment(item);
      onChange(item);
    }
  };

  return (
    <>
      <input ref={fileInputRef} accept={allowedExtensions} onChange={(event: React.ChangeEvent<HTMLInputElement>) => onUploadDocumentChange(event)} type="file" hidden />
      <group>
      {!!showDoodle &&
        <group
          //data-space="30"
          //data-gap="30"
          data-align="center"
          data-direction="column"
          data-wrap='no'
          data-background="main"
          data-position="absolute"
          data-top="0"
          data-height='fit'
        >
          <SvgDoodle />
        </group>}
      {
        !!showPreview ?
        <group  data-align="center" data-direction="column" data-wrap='no' data-gap="5" data-space="30"  data-background={showDoodle ? "" : "main-dark"}>
            <group ref={parentRef} onClick={() => fileInputRef?.current?.click()} data-ratio="1:1" data-shrink="no" data-contain="" data-length="150" data-radius="full" data-color="white" data-cursor="pointer" >
            <picture>
              {
              item.fileBase64 ?
              <img src={item.fileBase64} alt="" /> : <text data-text-size="xx-large" data-weight="700">{textForPreview ? getStringFirstLetters(textForPreview) : "IMG"}</text>}
              </picture>
              <Ripple>
                <group data-position="absolute" data-interactive="" data-height="fit"></group>
              </Ripple>
          </group>
        </group>
        :
        <Input
          ref={parentRef}
          label=""
          type="text"
          dataLength="auto"
          placeholder={placeholder}
          value={attachment.originalFileName}
          button={<Button onClick={() => fileInputRef?.current?.click()} primary text="Browse"></Button>}
        />

      }
      </group>
    </>
  );
});


export default AttachmentItem;
