// 3rd-party modules
import { useEffect, useState } from "react";

// project modules
import AccountMenu from "../../components/accounts/AccountMenu";
import AccountTabs from "../../components/accounts/AccountTabs";
import Loader from "../../components/shared/loader";
import { apiCall } from "../../helpers/apiHelper";
import { Account } from "../../models/account";

// apis
import * as AuthApi from "../../apis/authApi"

export default function AccountPage() {
  const [currentAccount, setCurrentAccount] = useState<Account>(new Account());
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  let abortController = new AbortController();

  useEffect(() => {
    let abortController = new AbortController();

    getAccountAsync();

    return () => { abortController.abort(); }
  }, []);

  const getAccountAsync = async () => {
    const account = await getAccount(abortController.signal);

    if (account) {
      setCurrentAccount(account!);
      setRefresh(true);
    }
  }

  const getAccount = async (abortSignal?: AbortSignal) => {
    setLoading(true);
    const response = await apiCall(AuthApi.getAccount(abortSignal));
    setLoading(false);

    return response.success ? Account.toClass(response.data?.value) : null;
  };

  return (
    <>
      {loading && <Loader/>}
      <view data-adaptive="mobile" data-contain="">
        <AccountMenu
          refresh={refresh}
          account={currentAccount}/>
      </view>
      <view data-adaptive="desktop" data-contain="">
        <AccountTabs
          refresh={refresh}
          account={currentAccount} />
      </view>
    </>
  );
}
