// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToInstance } from 'class-transformer';
import { AccountKiboshDevice } from './accountKiboshDevice';

export class AccountKiboshVpnDevice extends AccountKiboshDevice {
  @Expose()
  public associatedRouterClientId?: string;
  @Expose()
  public associatedRouterKiboshDeviceId?: string;
  @Expose()
  public deviceType?: string;
  @Expose()
  public id?: string;
  @Expose()
  public initialized?: boolean;
  @Expose()
  public isAssociatedWithRouterClient?: boolean;
  @Expose()
  public vpnId?: string;
  @Expose()
  public vpnType?: string;
  @Expose()
  public name?: string;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(AccountKiboshVpnDevice, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(AccountKiboshVpnDevice, camelcaseKeys(data, { deep: true }) as AccountKiboshVpnDevice[], { excludeExtraneousValues: true });
  }
}
