type Props = {
  error: Error
};

export default function Error({ error }: Props) {
  console.error(error);

  return (
    <div>Something went wrong!</div>
  );
}
