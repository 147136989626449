// 3rd-party modules
import { useContext, useEffect } from "react";

// project modules
import { PostContext } from "../layouts/DefaultLayout";

export default function ProductPage() {
  const postContext = useContext(PostContext);

  /*
  useEffect(() => {
    console.log(postContext);
  }, [postContext]);
  */

  return (
    <>
      <view data-align="center" data-jusify="center" data-border="none">
        <group
          data-width="auto"
          data-direction="column"
          data-align="center"
          data-jusify="center"
        >
          <icon data-icon-size="medium">home</icon>
          <text>Product Page</text>
        </group>
      </view>
    </>
  );
}
