// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToClass } from "class-transformer";

export class Attachment {
  @Expose()
  public attachmentId?: number;
  @Expose()
  public entityCode?: string;
  @Expose()
  public entityId?: number;
  @Expose()
  public originalFileName?: string;
  @Expose()
  public fileBase64?: string;
  @Expose()
  public file?: File;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToClass(Attachment, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToClass(Attachment, camelcaseKeys(data, { deep: true }) as Attachment[], { excludeExtraneousValues: true });
  }
}
