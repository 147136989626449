// project modules
import { apiCall } from '../helpers/apiHelper';

// models
import { ApiResponse } from '../models/response';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../models/auth';

// apis
import * as AuthApi from '../apis/authApi';

// models
import { AccountKiboshDevice } from '../models/accountKiboshDevice';
import { loginViewModel } from '../models/types/auth';

// defines
export type RememberUser = {
  username: string | undefined
  remember: boolean
}

// state
export interface UserState {
  currentUser: User
  authToken: string
  refreshToken: string
  rememberUser: RememberUser
  language: string
  kiboshDevice: AccountKiboshDevice
}

const initialState: UserState = {
  currentUser: new User(),
  authToken: '',
  refreshToken: '',
  rememberUser: { username: undefined, remember: false },
  language: 'en',
  kiboshDevice: new AccountKiboshDevice()
};

export const loginUser = createAsyncThunk<ApiResponse, loginViewModel>('user/loginUser', async (loginInfo) => {
  return await apiCall(AuthApi.loginUser(loginInfo));
});

export const loginUserByRefreshToken = createAsyncThunk<ApiResponse, loginViewModel>('user/loginUserByRefreshToken', async (loginInfo) => {
  return await apiCall(AuthApi.loginUserByRefreshToken(loginInfo));
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<string>) => {
      state.authToken = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
    },
    logoutUser: (state) => {
      state.authToken = '';
      state.refreshToken = '';
      state.currentUser = new User();
      state.kiboshDevice = new AccountKiboshDevice();
    },
    checkAccess: (state, action: PayloadAction<string[]>) => {},
    setRememberUser: (state, action: PayloadAction<RememberUser>) => {
      state.rememberUser = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setKiboshDevice: (state, action: PayloadAction<AccountKiboshDevice>) => {
      state.kiboshDevice = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.authToken = payload.extra?.token?.value || '';
      state.refreshToken = payload.extra?.refreshToken?.value || '';
      state.currentUser = User.toClass(payload.data?.value);
    });
    builder.addCase(loginUserByRefreshToken.fulfilled, (state, { payload }) => {
      state.authToken = payload.extra?.token?.value || '';
      state.refreshToken = payload.extra?.refreshToken?.value || '';
      state.currentUser = User.toClass(payload.data?.value);
    });
  }
});

// action creators
export const { logoutUser, setAuthToken, setCurrentUser, setRememberUser, setLanguage, setKiboshDevice } = userSlice.actions;

export default userSlice.reducer;
