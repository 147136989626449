// project modules
import { getBaseUrl, sendRequest } from '../helpers/apiHelper';

export const getPages = async (language: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/post/${language}/page/list`, 'get', null, null, abortSignal);
};

export const getPagePosts = async (parentId: number, language: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/post/${language}/page/${parentId}`, 'get', null, null, abortSignal);
};

export const getCategoryPosts = async (category: string, language: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/post/${language}/category/${category}`, 'get', null, null, abortSignal);
};

export const getReviews = async (abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/post/review/list`, 'get', null, null, abortSignal);
};

export const getUserReviews = async (abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/post/user/review/list`, 'get', null, null, abortSignal);
};

export const insertReview = async (data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/post/review`, 'post', data, null, abortSignal);
};
