// 3rd-party modules
import AppRouter from './AppRouter';
import { ConfigProvider } from 'antd';
import { HelmetProvider } from 'react-helmet-async';

export default function App() {
  return (
    <>
      <HelmetProvider>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#4c9484",
              colorLink: "#4c9484",
              colorLinkActive: "#4c9484",
              colorLinkHover: "#4c9484",
              // colorLink: colorPalette.colorPrimary,
              // colorLinkActive: colorPalette.colorDark,
              // colorLinkHover: colorPalette.colorLight,
              // motionDurationMid: "0s",
              // motionDurationSlow: "0s",
              // motionDurationFast: "0s",
              // motion: false,
              // lineHeight: 1.2,
              // borderRadius: 3,
              // colorPrimary: colorPalette.colorPrimary,
              // sizeStep: 3,
              // sizeUnit: 3,
              fontSize: 13,
              fontFamily: `'Plus Jakarta Sans', sans-serif`,
              // fontSizeLG: 5,
              // paddingXXS: 3,
              // marginXXS: 3,
              // boxShadow:
              //   "0 0 15px 2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05)",
              // boxShadowSecondary:
              //   "0 0 15px 2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05)",
            },
          }}
        >
          <AppRouter />
        </ConfigProvider>
      </HelmetProvider>
    </>
  );
}
