// 3rd-party modules
import Chart from "react-apexcharts";
import CustomChartModal from "./chartModal";

type Props = {
  options: any;
  series: any[];
  type?:
  | "line"
  | "area"
  | "bar"
  | "pie"
  | "donut"
  | "radialBar"
  | "scatter"
  | "bubble"
  | "heatmap"
  | "candlestick"
  | "boxPlot"
  | "radar"
  | "polarArea"
  | "rangeBar"
  | "rangeArea"
  | "treemap";
  width: string;
  openInModal?: boolean;
  title?: string;
  onClose?: () => void;
};

const CustomChart: React.FC<Props> = ({ options, series, type, width, title, openInModal, onClose }: Props) => {
  return (
    <>
      <Chart
        options={options}
        series={series}
        type={type}
        width={width}
      />
      {
        !!openInModal &&
        <CustomChartModal
          options={options}
          series={series}
          type={type}
          title={title}
          open={openInModal}
          onClose={onClose ? onClose : undefined}/>
      }
    </>
  );
};

export default CustomChart;
