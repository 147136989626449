// 3rd-party modules
import React from 'react';

// project modules
import { useAuthorize } from '../../hooks/authHooks';

type Props = {
  children?: React.ReactNode;
  roles?: string[]
};

export default function ProtectedRoute({ children, roles }: Props) {
  useAuthorize(roles);

  return children as React.ReactElement<any>;
}
