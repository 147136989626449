// 3rd-party modules
import { useEffect, useState } from "react";
import { createSearchParams, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

// project modules
import Button from "../components/shared/button";
import { apiCall } from "../helpers/apiHelper";
import { RootState } from "../stores";

// apis
import * as PaymentApi from '../apis/paymentApi';

// models
import { User } from "../models/auth";

// defines
import Config from "../config";

export default function AccountPaymentReturnPage() {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const abortController = new AbortController();

  const user = useSelector((state: RootState) => state.user.currentUser) as User;

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    let abortController = new AbortController();

    const getSessionStatusAsync = async () => {
      if (sessionId) {
        const response = await getSessionStatus(sessionId, abortController.signal);

        setStatus(response.status);
        setCustomerEmail(response.customerEmail);
      }
    }

    getSessionStatusAsync();

    return () => { abortController.abort(); }
  }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    fetch(`/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });
  }, []);

  const getSessionStatus = async (sessionId: string, abortSignal?: AbortSignal) => {
    setLoading(true);
    const response = await apiCall(PaymentApi.getSessionStatus(sessionId, abortSignal));
    setLoading(false);

    return response.success ? response.data?.value : {};
  };

  const onBackToLoginClick = () => {
    const returnUrl = Config.loginPageUrl;
    const email = searchParams.get("email");

    if (email) {
      navigate({
        pathname: returnUrl,
        search: `?${createSearchParams({ email })}`,
      });
    } else {
      navigate({
        pathname: returnUrl
      });
    }
  };

  const onBackToPortalClick = () => {
    const returnUrl = "/portal";

    navigate({
      pathname: returnUrl
    });
  };

  if (status === 'open') {
    return (
      <Navigate to="/checkout" />
    )
  }

  if (status === 'complete') {
    return (
      <section id="success">
        <wrapper>
          <group data-space-horizontal="10">
            <group data-direction="column" data-gap="10">
              <text
                data-weight="800"
                data-text-size="xx-large"
                data-wrap="wrap"
              >
                Successful Payment
              </text>
              <text data-text-size="large" data-wrap="wrap"  data-max-length="300">
                We appreciate your business! A confirmation email will be sent to your email.
              </text>
            </group>
            <group data-direction="column" data-align="start" data-gap="10">
            </group>
            <group
              data-gap="10"
              data-direction="column"
              data-space-vertical="50"
              data-max-length="300">
              {user &&
                <Button
                  fit
                  large
                  primary
                  text="Back To Portal" onClick={onBackToPortalClick} />
              }
              {!user &&
                <Button
                  fit
                  large
                  primary
                  text="Back To Login" onClick={onBackToLoginClick} />
              }
            </group>
          </group>
        </wrapper>
        {/* <p>
          We appreciate your business! A confirmation email will be sent to {customerEmail}.

          If you have any questions, please email <a href="mailto:orders@example.com">orders@example.com</a>.
        </p> */}
      </section>
    )
  }

  return null;
}
