// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToInstance } from 'class-transformer';

export class AccountDeviceGroup {
  @Expose()
  public id?: string;
  @Expose()
  public name?: string;
  @Expose()
  public icon?: string;
  @Expose()
  public isExcluded?: boolean;
  @Expose()
  public isBlocked?: boolean;
  @Expose()
  public excludedUntil?: string;
  @Expose()
  public blockedAt?: string;
  @Expose()
  public blockedUntil?: string;
  @Expose()
  public loggingEnabled?: boolean;
  @Expose()
  public clientIds?: string[];
  @Expose()
  public numSchedules?: number;
  @Expose()
  public numSetFiltersWithSchedule?: number;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(AccountDeviceGroup, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(AccountDeviceGroup, camelcaseKeys(data, { deep: true }) as AccountDeviceGroup[], { excludeExtraneousValues: true });
  }
}
