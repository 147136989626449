export class ApiResponse {
  public success: boolean = false;
  public statusCode?: number;
  public data?: ResponseData;
  public message?: string;
  public error?: ResponseError;
  public extra?: ResponseExtraData;

  public get clientFailed() {
    return this.statusCode ? (this.statusCode >= 400 && this.statusCode < 500) : false;
  }

  public get serverFailed() {
    return this.statusCode ? this.statusCode > 500 : false;
  }

  public get cancelled() {
    return this.statusCode ? this.statusCode === 499 : false;
  }

  public get errors() {
    let errors = [];

    if (this.message)
      errors.push(this.message);

    if (this.error) {
      switch (this.error?.type) {
        case 'Array':
          if (Array.isArray(this.error?.value)) {
            for (const error of this.error?.value) {
              errors.push(error['errorMessage' as keyof typeof error]);
            }
          }

          break;
        case 'Object':
          const error = this.error?.value;

          errors.push(error['errorMessage' as keyof typeof error]);

          break;
        case 'string':
        default:
          errors.push(this.error?.value);

          break;
      }
    }

    return errors;
  }

  constructor(response: Partial<ApiResponse>) {
    Object.assign(this, response);
  }

  public static setSuccess(data?: any, message?: string, statusCode?: number): ApiResponse {
    return new ApiResponse({
      success: true,
      statusCode: statusCode || 200,
      data: {
        type: Array.isArray(data) ? 'array' : typeof data,
        value: data
      },
      message,
      error: undefined
    });
  }

  public static setError(error?: any, message?: string, statusCode?: number, errorCode?: string): ApiResponse {
    return new ApiResponse({
      success: false,
      statusCode: statusCode || 400,
      data: undefined,
      message,
      error: {
        code: errorCode,
        type: Array.isArray(error) ? 'array' : typeof error,
        value: error
      }
    });
  }
}

class ResponseData {
  public type?: string;
  public value: any;
}

class ResponseError {
  public code?: string;
  public type?: string;
  public value: any;
}

class ResponseExtraData {
  public token?: ResponseToken;
  public refreshToken?: ResponseToken;
  public totalCount?: number;
  public pagination?: ResponsePagination;
}

class ResponseToken {
  public value?: string;
  public expireDate?: Date;
}

class ResponsePagination
{
  public pageNumber?: number;
  public pageSize?: number;
}
