// project modules
import { getBaseUrl, sendRequest } from '../helpers/apiHelper';

// models
import { PaymentIntentCreateRequest } from '../models/payment';
import { Product } from '../models/product';

export const createPaymentIntent = async (request: PaymentIntentCreateRequest, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/payment/createIntent`, 'post', request, null, abortSignal);
};

export const createCheckoutSession = async (product: Product, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/payment/createCheckoutSession`, 'post', product, null, abortSignal);
};

export const getSessionStatus = async (sessionId: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/payment/sessionStatus?session_id=${sessionId}`, 'get', null, null, abortSignal);
};
