import React from "react";
import Button from "../../components/shared/button";
import ListView from "../../components/shared/list/listView";
import { TopToolbar } from "../../components/shared/list/topToolbar";
import {
  ListViewType,
  useList,
} from "../../components/shared/list/useListHook";
import { Text } from "../../components/shared/text";

import sampleImage1 from "./images/samples/sample_1.jpg";
import sampleImage2 from "./images/samples/sample_2.jpg";
import sampleImage3 from "./images/samples/sample_3.jpg";
import sampleImage4 from "./images/samples/sample_4.jpg";
import sampleImage5 from "./images/samples/sample_5.jpg";
import sampleImage6 from "./images/samples/sample_6.jpg";
import sampleImage7 from "./images/samples/sample_7.jpg";
import sampleImage8 from "./images/samples/sample_8.jpg";
import sampleImage9 from "./images/samples/sample_9.jpg";
import sampleImage10 from "./images/samples/sample_10.jpg";
import sampleImage11 from "./images/samples/sample_11.jpg";
import sampleImage12 from "./images/samples/sample_12.jpg";

import icon_1 from "./images/samples/icon1.png";
import icon_2 from "./images/samples/icon2.png";
import icon_3 from "./images/samples/icon3.png";

import Marquee from "../../components/shared/additionalComponents/Marquee";
import Ripple from "../../components/shared/additionalComponents/Ripple";

const getRandomImage = () => {
  const sampleImages = [
    sampleImage1,
    sampleImage2,
    sampleImage3,
    sampleImage4,
    sampleImage5,
    sampleImage6,
    sampleImage7,
    sampleImage8,
    sampleImage9,
    sampleImage10,
    sampleImage11,
    sampleImage12,

  ];
  const randomIndex = Math.floor(Math.random() * sampleImages.length);
  return sampleImages[randomIndex];
};

const getRandomName = () => {
  const RandomName = [
    "Bruce Banner",
    "Tony Stark",
    "Natasha Romanoff",
    "Thor Odinson",
    "Steve Rogers",
    "Yondu Udonta",
  ];
  const randomIndex = Math.floor(Math.random() * RandomName.length);
  return RandomName[randomIndex];
};

type DemoItem = {
  key: number;
  name: string;
  age: number;
  address: string;
  description: string;
  image: string;
};
const dataSource: DemoItem[] = [];

for (let i = 1; i <= 15; i++) {
  dataSource.push({
    key: i,
    name: getRandomName(),
    age: Number(`${i}2`),
    address: `${i}720 Goodrich Ct Platteville, Colorado`,
    description: `Mauris justo auctor in sollicitudin convallis  gravida non tortor etiam et vehicula elit`,
    image: getRandomImage(),
  });
}

const BlockItem: React.FC<{ data: DemoItem }> = ({ data }) => (
  <group
    data-direction="column"
    data-contain=""
    data-border=""
    data-radius="10"
    data-background="context"
    // data-react="scale"
    data-cursor="pointer"
  >
    <group data-ratio="5:4" data-shrink="no" data-contain="">
      {/* <text data-position="absolute" data-index="2" data-space-horizontal="20" data-color="white" data-text-size="64" data-weight="800" data-bottom="0">{data.key}</text> */}
      <picture >
        <img src={data.image} alt="" />
      </picture>
    </group>
    <group data-direction="column" data-contain="" data-gap="5" data-space="15">
      <Text
        data-text-size="medium"
        data-weight="700"
        data-color="main"
        data-ellipsis=""
      >
        {data.name}
      </Text>
      <Text data-wrap="wrap" data-clamp="3" data-line="1.5">
        {data.description}
      </Text>
    </group>
    <separator data-horizontal="" data-margin="none"></separator>
    <group data-gap="5" data-space="10" data-align="center" data-justify="end" >
      <Button material text="Open" />

      <separator data-vertical=""></separator>
      <Button micro material icon="arrow_outward" />
    </group>
  </group>
);

const ListItem: React.FC<{ data: DemoItem }> = ({ data }) => (
  <Ripple>
    <group
      data-align="center"
      data-space="5"
      data-gap="5"
      data-wrap="no"
      data-radius="10"
      data-contain=""
      data-cursor="pointer"
    >
      <group data-ratio="1:1" data-length="50" data-shrink="no" data-index="1">
        <picture data-radius="5">
          <img src={data.image} alt="" />
        </picture>
      </group>
      <group data-direction="column" data-contain="" data-space="5" data-index="1">
        <Text data-weight="700" data-ellipsis="">
          {data.name}
        </Text>
        <Marquee>
          <Text data-ellipsis="" data-light="">
            {data.description}
          </Text>
        </Marquee>
      </group>
      <separator data-vertical=""></separator>
      <group
        data-align="center"
        data-wrap="no"
        data-position="right"
        data-width="auto"
        data-index="1"
      >
        <Button mini rounded>
          <icon>more_vert</icon>
        </Button>
      </group>
    </group>
  </Ripple>
);

const GridItem: React.FC<{ data: DemoItem }> = ({ data }) => (
  <group data-align="center" data-space="10" data-gap="10" data-wrap="no">
    <group data-ratio="1:1" data-length="50" data-shrink="no">
      <picture>
        <img data-radius="5" src={data.image} alt="" />
      </picture>
    </group>
    <group data-direction="column" data-contain="">
      <Text data-weight="700">{data.name}</Text>
      <Text data-ellipsis="">{data.description}</Text>
    </group>
    <separator data-vertical=""></separator>
    <group
      data-align="center"
      data-wrap="no"
      data-position="right"
      data-width="auto"
    >
      <Button mini rounded>
        <icon>more_vert</icon>
      </Button>
    </group>
  </group>

);

const ListViewDemo: React.FC = () => {
  const { toolbarProps } = useList({
    viewTypes: [ListViewType.Block, ListViewType.List, ListViewType.Grid],
    defaultViewType: ListViewType.Block,
  });
  return (
    <view data-vertical="" data-adaptive="">
      <view data-size="small" data-space="20" data-scroll="" data-background="highlight">
        <div className="list_view_container" view="grid" data-flat="">
          <group class="list_view_scroller">
            <group data-space-vertical="20" data-weight="600" data-color="main">
              <text data-ellipsis="" data-text-size="medium">Mauris justo</text>
            </group>
            <div className="list_view" data-background="white" data-contain="" data-radius="10"  data-shadow="">
              <div className="item">
                <group data-align="center" data-space="5" data-gap="10" data-wrap="no" data-cursor="pointer" data-interactive="">
                  <group data-ratio="1:1" data-length="40" data-shrink="no" data-index="1"><picture><icon data-color="main">grid_view</icon></picture></group>
                  <group data-direction="column" data-contain=""><text data-ellipsis="">Mauris justo </text></group>
                  <group data-align="center" data-wrap="no" data-position="right" data-justify="center" data-length="40"><icon data-light="">chevron_right</icon></group>
                </group>
              </div>
              <div className="item">
                <group data-align="center" data-space="5" data-gap="10" data-wrap="no" data-cursor="pointer" data-interactive="">
                  <group data-ratio="1:1" data-length="40" data-shrink="no" data-index="1"><picture><icon data-color="main">grid_view</icon></picture></group>
                  <group data-direction="column" data-contain=""><text data-ellipsis="">Mauris justo </text></group>
                </group>
              </div>
              <div className="item">
                <group data-align="center" data-space="5" data-gap="10" data-wrap="no" data-cursor="pointer" data-interactive="" data-material="">
                  <group data-ratio="1:1" data-length="40" data-shrink="no" data-index="1"><picture><icon data-color="main">grid_view</icon></picture></group>
                  <group data-direction="column" data-contain=""><text data-ellipsis="">Mauris justo </text></group>
                </group>
              </div>
            </div>

            <group data-space-vertical="20" data-weight="600" data-color="main">
              <text data-ellipsis="" data-text-size="medium">Mauris justo</text>
            </group>
            <div className="list_view" data-background="white" data-contain="" data-radius="10"  data-shadow="">
              <div className="item">
                <group data-align="center" data-space="5" data-gap="10" data-wrap="no" data-cursor="pointer" data-interactive="">
                  <group data-ratio="1:1" data-length="40" data-shrink="no" data-index="1"><picture><icon data-color="main">grid_view</icon></picture></group>
                  <group data-direction="column" data-contain=""><text data-ellipsis="">Mauris justo </text></group>
                  <group data-align="center" data-wrap="no" data-position="right" data-justify="center" data-length="40"><icon data-light="">chevron_right</icon></group>
                </group>
              </div>
              <div className="item">
                <group data-align="center" data-space="5" data-gap="10" data-wrap="no" data-cursor="pointer" data-interactive="">
                  <group data-ratio="1:1" data-length="40" data-shrink="no" data-index="1"><picture><icon data-color="main">grid_view</icon></picture></group>
                  <group data-direction="column" data-contain=""><text data-ellipsis="">Mauris justo </text></group>
                </group>
              </div>
            </div>
          </group>
        </div>
      </view>
      <view
        data-vertical="true"
        //  data-space="20"
        data-background="highlight"
      //  data-align="start"

      >
        <group
          data-border=""
          //  data-radius="10"
          data-contain=""
          data-background="main-background"
          data-wrap="no"
          data-direction="column"
          data-max-height="fit"

        >
          <TopToolbar {...toolbarProps} />
          {toolbarProps.viewType === ListViewType.Block && (
            <ListView
              dataSource={dataSource}
              view={ListViewType.Block}
              keyField="key"
              data-template="190"
              scrollerProps={{ "data-space": "20" }}
              listProps={{ "data-gap": "10" }}
              itemComponent={BlockItem}
              data-scroll=""
            />
          )}
          {toolbarProps.viewType === ListViewType.Grid && (
            <ListView
              view={ListViewType.Grid}
              dataSource={dataSource}
              keyField="key"
              data-space="10"
              itemComponent={GridItem}
              data-scroll=""
            />
          )}
          {toolbarProps.viewType === ListViewType.List && (
            <ListView
              data-template="200"
              view={ListViewType.List}
              dataSource={dataSource}
              keyField="key"
              data-space="5"
              listProps={{ "data-space": "10", "data-gap": "10" }}
              itemComponent={ListItem}
              data-scroll=""
            />
          )}
          <group
            data-border="overprint"
            data-align="center"
            data-gap="10"
            data-space="10"
            data-index="2"
          >
            <icon>arrow_outward</icon>
            <text>Footer Goes Here</text>
          </group>
        </group>
      </view>
      <view data-adaptive="" data-space="10" >

        <div className="list_view_container" view="list">
          <group className="list_view_scroller">
            <div className="list_view" data-space="10" data-gap="10">

              <div className="item">
                <div data-type="group" className="">
                  <Ripple>
                    <group data-name="list_header" data-radius="5" data-contain="">
                      <group data-align="center" data-space="5" data-gap="5" data-wrap="no" data-cursor="pointer">
                        <group data-ratio="1:1" data-length="30" data-shrink="no" data-index="1" data-radius="5" data-color="main">
                          <group data-length="30" data-radius="30" data-contain="" data-height="30">
                            <picture data-object-fit="cover">
                              <img src={icon_1} alt="Filter Set Icon" />
                            </picture>
                          </group>
                        </group>
                        <group data-direction="column" data-contain="" data-space="5" data-index="1"><text data-weight="700" data-ellipsis="">Youtube Safe Mode</text><group data-wrap="no" data-contain="" data-name="marquee_cont"><group data-marquee="" data-width="auto" data-max-length="auto"><text data-ellipsis="" data-light="">Blocking: OFF</text></group></group></group>
                        <separator data-vertical=""></separator>
                        <group data-align="center" data-wrap="no" data-position="right" data-width="auto" data-index="1"><group data-space="10"><icon data-opacity="30" data-interact-ready="rotate-180">keyboard_arrow_down</icon></group></group>
                      </group>
                    </group>
                  </Ripple>
                  <group data-name="list_cont"></group>
                </div>
              </div>


              <div className="item">
                <div data-type="group" className="show">
                  <Ripple>
                    <group data-name="list_header" data-radius="5" data-contain="">
                      <group data-align="center" data-space="5" data-gap="5" data-wrap="no" data-cursor="pointer">
                        <group data-ratio="1:1" data-length="30" data-shrink="no" data-index="1" data-radius="5" data-color="main">
                          <group data-length="30" data-radius="30" data-contain="" data-height="30">
                            <picture data-object-fit="cover">
                              <img src={icon_2} alt="Filter Set Icon" />
                            </picture>
                          </group>
                        </group>
                        <group data-direction="column" data-contain="" data-space="5" data-index="1"><text data-weight="700" data-ellipsis="">Youtube Safe Mode</text><group data-wrap="no" data-contain="" data-name="marquee_cont"><group data-marquee="" data-width="auto" data-max-length="auto"><text data-ellipsis="" data-light="">Blocking: OFF</text></group></group></group>

                        <separator data-vertical=""></separator>
                        <group data-align="center" data-wrap="no" data-position="right" data-width="auto" data-index="1"><group data-space="10"><icon data-opacity="30" data-interact-ready="rotate-180">keyboard_arrow_down</icon></group></group>
                      </group>
                    </group>
                  </Ripple>
                  <group data-name="list_cont">
                    <group data-space="10">
                      <group data-space="15" data-gap="5"><group data-width="auto" data-gap="15"><label checkbox=""><input type="checkbox" placeholder=" " /><box><check></check></box><text>Blocking</text></label></group></group>
                      <group data-space="15"><text data-weight="700" data-text-size="" data-wrap="wrap" data-color="main-dark">Schedule</text></group>
                      <group>
                        <view className="table_cont" data-background="none" data-radius="10">
                          <group data-space="15" data-gap="20" data-border="" data-background="highlight">
                            <div className="button primary" effect="material"><text className="">New</text><icon>add</icon></div>
                            <div className="button outline" data-position="right" effect="material"><text>Refresh</text><icon>refresh</icon></div>
                          </group>
                        </view>
                      </group>
                    </group>
                  </group>
                </div>
              </div>


              <div className="item">
                <div data-type="group">

                  <Ripple>
                    <group data-name="list_header" data-radius="5" data-contain="">
                      <group data-align="center" data-space="5" data-gap="5" data-wrap="no" data-cursor="pointer">
                        <group data-ratio="1:1" data-length="30" data-shrink="no" data-index="1" data-radius="5" data-color="main">
                          <group data-length="30" data-radius="30" data-contain="" data-height="30">
                            <picture data-object-fit="cover">
                              <img src={icon_3} alt="Filter Set Icon" />
                            </picture>
                          </group>
                        </group>
                        <group data-direction="column" data-contain="" data-space="5" data-index="1"><text data-weight="700" data-ellipsis="">Youtube Safe Mode</text><group data-wrap="no" data-contain="" data-name="marquee_cont"><group data-marquee="" data-width="auto" data-max-length="auto"><text data-ellipsis="" data-light="">Blocking: OFF</text></group></group></group>

                        <separator data-vertical=""></separator>
                        <group data-align="center" data-wrap="no" data-position="right" data-width="auto" data-index="1"><group data-space="10"><icon data-opacity="30" data-interact-ready="rotate-180">keyboard_arrow_down</icon></group></group>
                      </group>
                    </group>
                  </Ripple>
                  <group data-name="list_cont">

                  </group>
                </div>
              </div>




            </div>
          </group>
        </div>

      </view>
    </view>
  );
};
export default ListViewDemo;
