// 3rd-party modules
// import ReactGA from 'react-ga4';

// project modules
import Config from '../config';
import interceptorsSetup from './axios';

export const init = () => {
  // prevent showing console stuff on production
  // eslint-disable-next-line eqeqeq
  if (Config.noConsole) {
    /* eslint-disable */
    const console: any = {};

    console.log = () => {};
    console.info = () => {};
    console.warn = () => {};
    console.error = () => {};

    window.console = console;
    /* eslint-enable */
  }

  /*
  if (Config.gaMeasurementId) // no measurement-id = disable tracking
    ReactGA.initialize(Config.gaMeasurementId);
  */

  interceptorsSetup();
};
