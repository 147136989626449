// 3rd-party modules
import { message } from 'antd';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";

// project modules
import { apiCall } from "../../../helpers/apiHelper";
import Loader from "../../shared/loader";
import Select from '../../shared/inputs/select';
import yup from "../../../plugins/yup";

// apis
import * as AccountKiboshDeviceApi from '../../../apis/accountKiboshDeviceApi';
import * as CommonValueApi from '../../../apis/commonValueApi';

// models
import { Account } from '../../../models/account';
import { ApiResponse } from "../../../models/response";
import Popup from '../../shared/popup/popup';
import { AccountKiboshVpnDevice } from '../../../models/accountKiboshVpnDevice';
import { CommonValue } from '../../../models/commonValue';

type Props = {
  closeOnSave?: boolean;
  modalTitle?: string;
  open: boolean;
  account: Account;
  accountKiboshVpnDevice?: AccountKiboshVpnDevice;
  kiboshDeviceReference: string,
  onClose?: () => void;
  onSave?: (accountKiboshVpnDevice: any) => void;
};

export default function AccountVpnModal({ closeOnSave = false, modalTitle = "", open, account, accountKiboshVpnDevice, kiboshDeviceReference, onClose, onSave }: Props) {
  const schema = yup.object().shape({
    vpnType: yup.string().label("VPN Type").required()
  });
  const { control, handleSubmit } = useForm<AccountKiboshVpnDevice | any>({
    defaultValues: useMemo(() => accountKiboshVpnDevice, [accountKiboshVpnDevice]),
    resolver: yupResolver(schema),
  });
  const [deviceTypes, setDeviceTypes] = useState<CommonValue[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingDevicesTypeList, setLoadingDevicesTypeList] = useState(false);
  const abortController = new AbortController();

  useEffect(()=> {
    getDeviceTypesAsync();
  },[])

  const getDeviceTypesAsync = async () => {
    const data = await getDeviceTypes(abortController.signal);

    setDeviceTypes(data);
  }

  const getDeviceTypes = async (abortSignal?: AbortSignal) => {
    setLoadingDevicesTypeList(true);
    const response = await apiCall(CommonValueApi.getDeviceTypes(abortSignal));
    setLoadingDevicesTypeList(false);

    return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
  }

  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onSubmit: SubmitHandler<AccountKiboshVpnDevice> = async (formData: AccountKiboshVpnDevice) => {
    let response: ApiResponse;

    setLoading(true);

    const deviceVpnType = deviceTypes.filter(x => x.value === "vpn");

    if (deviceVpnType.length) {
      formData.kiboshDeviceTypeId = deviceVpnType[0].commonValueId;
    }

    formData.notes = formData.vpnType;
    formData.kiboshDeviceReference = kiboshDeviceReference;

    response = await apiCall(AccountKiboshDeviceApi.insertAccountKiboshVpnDevice(formData, abortController.signal));

    if (response.success) {
      message.success(`VPN created successfully.`);

      if (onSave) onSave(AccountKiboshVpnDevice.toClass(response.data?.value));

      if (closeOnSave) {
        open = false;

        onCancel();
      }

    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  return (
    <Popup
      adaptive
      title={ modalTitle || `Account: ${account.accountName} - Create New VPN`}
      onCancel={onCancel}
      onClose={onCancel}
      onSave={handleSubmit(onSubmit)}
      fixSize="small"
    >
      {(loading || loadingDevicesTypeList) && <Loader />}
      <group data-space="15" data-gap="10" data-direction="column">
        <Select
          control={control}
          label="VPN Type"
          labelPosition="left"
          dataLength="320"
          name="vpnType"
          allowSearch={true}
          options={
            [
              {
                text: "IPSec (iOS)",
                value: "ipsec",
              },
              {
                text: "OpenVPN (Android)",
                value: "openvpn",
              }
            ]
          }
          />
      </group>
    </Popup>
  );
}
