export const CONSTANTS = {
  DEFAULT_TIME_FORMAT: "HH:mm",
  DEFAULT_DATETIME_FORMAT: "DD/MM/YYYY HH:mm",
  DEFAULT_DATE_FORMAT: "DD/MM/YYYY",
  DEFAULT_DATE_FORMAT2: "MMM D, YYYY",
  DEFAULT_PHONE_NUMBER_MASK: "____________________"
};

export const MESSAGES = {
  MANUAL_REQUEST_CANCELLATION: "Manual request cancellation."
};

export const COMMON_VALUES = {
  SUPPORT_TICKET_STATUS_CLOSED: "closed"
}
