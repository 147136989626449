// 3rd-party modules
import { message } from 'antd';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";

// project modules
import Loader from '../../../../shared/loader';
import Popup from '../../../../shared/popup/popup';
import Select from '../../../../shared/inputs/select';
import yup from '../../../../../plugins/yup';
import { apiCall } from '../../../../../helpers/apiHelper';
import { convertToSnakecase } from '../../../../../helpers/objectHelper';

// apis
import * as DashApi from '../../../../../apis/dashApi';

// models
import { AccountDevice } from '../../../../../models/accountDevice';
import { accountDeviceExcludViewModel } from '../../../../../models/types/accountDevice';
import { ApiResponse } from '../../../../../models/response';

type Props = {
  accountDevice: AccountDevice;
  excludingData: accountDeviceExcludViewModel;
  closeOnSave?: boolean;
  modalTitle?: string;
  open: boolean;
  onClose?: () => void;
  onSave?: (accountDevice: AccountDevice) => void;
};

// const getDeviceTypes = async (abortSignal?: AbortSignal) => {
//   const response = await apiCall(CommonValueApi.getDeviceTypes(abortSignal));

//   return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
// };

export default function AccountDeviceFilterationModal({ accountDevice, excludingData, closeOnSave = false, modalTitle = "", open, onClose, onSave }: Props) {

  const schema = yup.object().shape({
    duration: yup.number().label("Duration").nullable()
  });
  const { control, handleSubmit } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const abortController = new AbortController();

  useEffect(() => {
    if (open) {

    }
  }, [open]);

  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onSubmit: SubmitHandler<any> = async (formData: any) => {
    setLoading(true);

    const tempData: accountDeviceExcludViewModel = {
      ...excludingData,
      minutesBeforeExpiration: formData.duration
    }
    const data = convertToSnakecase(tempData, ["minutesBeforeExpiration"], []);

    let response: ApiResponse = await apiCall(DashApi.excludeClientDevice(accountDevice.id!, data, abortController.signal));

    if (response.success) {
      message.success(`Device filteration status changed successfully.`);

      if (onSave) onSave({...accountDevice, isBlocked: !accountDevice.isBlocked});
      if (closeOnSave) {
        onCancel();
      }
    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  return (
    <Popup
      title={ modalTitle || `Enable/Disable filteration for ${accountDevice?.name}`}
      onCancel={onCancel}
      onClose={onCancel}
      onSave={handleSubmit(onSubmit)}
      fixSize="small"
    >
      {loading && <Loader />}
      <group data-space="15" data-gap="10" data-direction="column">
      {
        !!excludingData.excluded ?
        <>
          <text data-wrap="wrap">Please select a time duration in minutes for this filteration.</text>
          <Select
            control={control}
            label="Duration"
            labelPosition="left"
            dataLength="auto"
            name="duration"
            allowSearch={true}
            options={
              [
                {
                  text: "15min",
                  value: "15"
                },
                {
                  text: "30min",
                  value: "30"
                },
                {
                  text: "45min",
                  value: "45"
                },
                {
                  text: "1hr",
                  value: "60"
                },
                {
                  text: "24hr",
                  value: "1440"
                },
              ]
            }
            />
        </> :
        <>
          <text data-wrap="wrap">Are you sure?</text>
        </>
      }
      </group>
    </Popup>
  );
}
