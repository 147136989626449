// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToInstance } from 'class-transformer';

export class AccountKiboshDeviceStatus {
  @Expose()
  public latestFirmwareVersion?: string;
  @Expose()
  public kiboshDevice?: AccountKiboshDeviceStatusDetail;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(AccountKiboshDeviceStatus, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(AccountKiboshDeviceStatus, camelcaseKeys(data, { deep: true }) as AccountKiboshDeviceStatus[], { excludeExtraneousValues: true });
  }
}

export class AccountKiboshDeviceStatusDetail {
  @Expose()
  public wanPingMs?: number;
  @Expose()
  public upgradeStatus?: string;
  @Expose()
  public remoteDnsStatus?: string;
  @Expose()
  public maxClientDevices?: number;
  @Expose()
  public localDnsStatus?: string;
  @Expose()
  public lastDeviceCheckin?: string;
  @Expose()
  public initialized?: boolean;
  @Expose()
  public id?: string;
  @Expose()
  public deviceType?: string;
  @Expose()
  public currentWanIp?: string;
  @Expose()
  public currentFirmwareVersion?: string;
  @Expose()
  public connectionStatus?: string;
  @Expose()
  public board?: string;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(AccountKiboshDeviceStatusDetail, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(AccountKiboshDeviceStatusDetail, camelcaseKeys(data, { deep: true }) as AccountKiboshDeviceStatusDetail[], { excludeExtraneousValues: true });
  }
}
