// 3rd-party modules
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// project modules
import AccountDeviceGroups from "../../components/accounts/device-groups/AccountDeviceGroups";
import Loader from "../../components/shared/loader";
import { AccountKiboshDevice } from "../../models/accountKiboshDevice";
import { apiCall } from "../../helpers/apiHelper";
import { RootState } from "../../stores";

// apis
import * as AuthApi from "../../apis/authApi"

// models
import { Account } from "../../models/account";

export default function ProfilesListPage() {
  const kiboshDevice = useSelector((state: RootState) => state.user.kiboshDevice) || new AccountKiboshDevice();
  const [currentAccount, setCurrentAccount] = useState<Account>(new Account());
  const [accountLoading, setAccountLoading] = useState(false);
  let abortController = new AbortController();

  useEffect(() => {
    let abortController = new AbortController();

    getAccountAsync();
    return () => { abortController.abort(); }
  }, []);

  const getAccountAsync = async () => {
    const account = await getAccount(abortController.signal);

    if (account) {
      setCurrentAccount(account!);
    }
  }

  const getAccount = async (abortSignal?: AbortSignal) => {
    setAccountLoading(true);
    const response = await apiCall(AuthApi.getAccount(abortSignal));
    setAccountLoading(false);

    return response.success ? Account.toClass(response.data?.value) : null;
  };

  return (
    <>
      {accountLoading && <Loader/>}
      <AccountDeviceGroups accountKiboshDevice={kiboshDevice} account={currentAccount} />
    </>
  );
}
