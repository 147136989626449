import {
  DatePicker as AntDatepicker,
  DatePickerProps as AntDatePickerProps,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import {
  forwardRef,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import { CONSTANTS } from '../../../helpers/defines';
import Input, { InputProps, InputType } from "./input";

export type DatePickerProps = InputProps &
  Omit<AntDatePickerProps, keyof InputProps> & {
    min?: string;
    max?: string;
    showTime?: any;
    format?: string;
    outFormat?: string;
    delayBlur?: boolean;
    useDayJS?: boolean;
  };

export const DatePickerInner = forwardRef<
  ReactElement,
  PropsWithChildren<DatePickerProps>
>(
  (
    {
      value,
      onChange,
      delayBlur = false,
      onBlur,
      onFocus,
      min,
      max,
      showTime,
      allowClear,
      useDayJS,
      outFormat = CONSTANTS.DEFAULT_DATETIME_FORMAT,
      format = CONSTANTS.DEFAULT_DATETIME_FORMAT,
      ...rest
    },
    ref
  ) => {
    const [blurTimeout, setBlurTimeout] = useState(setTimeout(() => { }));
    let dateFormat = format;
    if (showTime) {
      dateFormat = `${dateFormat} ${showTime?.format || "HH:mm"}`;
    }
    const onCustomChange = (v: Dayjs, strValue: string) => {
      onChange?.(
        strValue
          ? ((useDayJS    //NOSONAR
            ? v.format(outFormat)
            : dayjs(strValue, dateFormat).format(outFormat)) as any)
          : null
      );
    };

    const disableDate = useCallback(
      (current: Dayjs) => {
        return (min && dayjs(min) >= current) || (max && dayjs(max) <= current);
      },
      [min, max]
    );

    // preventing lose focus
    const blur = delayBlur
      ? (e: any) => {
        e?.persist();
        onBlur && setBlurTimeout(setTimeout(() => onBlur(e), 300));
      }
      : onBlur;

    const normalizedValue = useMemo(() => {
      if (!value) {
        return;
      }
      return dayjs(value as any, [outFormat, dateFormat])
        .format(dateFormat)
        .toString();
    }, [dateFormat, outFormat, value]);

    const focus = useCallback(
      (e: any) => {
        delayBlur && clearTimeout(blurTimeout);
        onFocus?.(e);
      },
      [delayBlur, onFocus, blurTimeout]
    );

    return (
      <AntDatepicker
        ref={ref}
        disabledDate={disableDate}
        onChange={onCustomChange}
        onBlur={blur}
        onFocus={focus}
        format={dateFormat}
        showTime={showTime}
        value={
          normalizedValue
            ? useDayJS          //NOSONAR
              ? normalizedValue
              : dayjs(normalizedValue, dateFormat)
            : null
        }
        allowClear={allowClear}
        {...(rest as any)}
      />
    );
  }
);

export const DatePicker = forwardRef<
  ReactElement,
  PropsWithChildren<DatePickerProps>
>((props, ref) => {
  return <Input ref={ref} {...props} type={InputType.DatePicker} />;
});
