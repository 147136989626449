import React, { useEffect, useState } from 'react';

interface TimerProps {
  remaining: number;
  duration: number; // Duration of the timer in seconds
}

const TimerComponent: React.FC<TimerProps> = ({ duration, remaining }) => {
  const [remainingTime, setRemainingTime] = useState(remaining);
  const [dashArray, setDashArray] = useState('0 100'); // 0% filled initially

  // Start the timer
  useEffect(() => {
    setRemainingTime(remaining)
  }, [remaining]);

  // Calculate the dasharray based on remaining time
  useEffect(() => {
    const circumference = 2 * Math.PI * 45; // Assuming radius of 45 for the circle
    const progress = (remainingTime / duration) * circumference;
    const dasharrayValue = `${progress} ${circumference}`;

    setDashArray(dasharrayValue);
  }, [remainingTime, duration]);

  // Start the timer
  useEffect(() => {
    if (remainingTime <= 0) return;

    const interval = setInterval(() => {
      setRemainingTime((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [remainingTime]);

  // Format time remaining to MM:SS format
  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    let returnValue = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    const hours = Math.floor(minutes / 60);
    if (hours) {
      const minsInHour = minutes % 60;
      returnValue = `${hours}:${minsInHour < 10 ? '0' : ''}${minsInHour}hr`
    }
    return returnValue;
  };

  return (
    <group className="timer-container" >
      <svg data-position="absolute" className="timer-svg" width="100%" height="100%" viewBox="0 0 100 100">
        <circle
          className="timer-circle-bg"
          cx="50"
          cy="50"
          r="45"
          fill="none"
          stroke="#currentcolor"
          strokeWidth="10"
        />
        <circle
          className="timer-circle"
          cx="50"
          cy="50"
          r="45"
          fill="none"
          stroke="currentcolor"
          strokeWidth="10"
          strokeLinecap="round"
          strokeDasharray={dashArray}
transform="rotate(-90 50 50)"

        />

      </svg>


      <text data-position="center"  className="timer-text"  data-color="currentcolor">
          {formatTime(remainingTime)}
        </text>

    </group>
  );
};

export default TimerComponent;
