import Button from "../shared/button";

interface CommandPanelToolbarProps {
  isDisabledMode?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
  additionalElement?: JSX.Element;
  submitText?: string;
}

export const CommandPanelToolbar: React.FC<CommandPanelToolbarProps> = ({
  isDisabledMode,
  onSave,
  onCancel,
  additionalElement,
  submitText
}) => {
  return (
    <group data-space="15" data-gap="10">

        <Button data-length="forcefit" primary disabled={isDisabledMode} onClick={onSave}>
          <text>{ submitText || "Save Changes"}</text>
        </Button>
        <Button data-length="forcefit" highlight onClick={onCancel}>
          <text>Cancel</text>
        </Button>
        {additionalElement || null}



    </group>
  );
};
