// project modules
import { getBaseUrl, sendRequest } from '../helpers/apiHelper';

// models
import { DataSourceRequest } from '../models/dataSourceRequest';

export const getComments = async (entityCode: string, entityId: number, request?: DataSourceRequest, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/comment/EntityType/${entityCode}/Entity/${entityId}/list`, 'post', request, null, abortSignal);
};

export const insertComment = async (data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/comment`, 'post', data, null, abortSignal);
};

export const markReadComments = async (entityCode: string, entityId: number) => {
  return await sendRequest(`${getBaseUrl()}/comment/EntityType/${entityCode}/Entity/${entityId}/markRead`, 'put', null);
};
