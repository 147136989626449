// 3rd-party modules
import { message } from "antd";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

// project modules
import Loader from "../shared/loader";
import Popup from "../shared/popup/popup";
import yup from "../../plugins/yup";
import { apiCall } from "../../helpers/apiHelper";
import Input, { InputType } from "../shared/inputs/input";

// apis
import * as PostApi from '../../apis/postApi';

// models
import { ApiResponse } from "../../models/response";

// defines
type Props = {
  closeOnSave?: boolean;
  open: boolean;
  onClose?: () => void;
  onSave?: () => void;
};

export default function LeaveReviewModal({ closeOnSave = false, open, onClose, onSave }: Props) {
  const schema = yup.object().shape({
    content: yup.string().label("Content").required().max(300),
  });
  const { control, handleSubmit } = useForm<any>({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);

  const abortController = new AbortController();

  useEffect(() => {
    if (open) {
    }
  }, [open]);

  const onCancel = () => {
    abortController.abort();

    if (onClose) onClose();
  };

  const onSubmit: SubmitHandler<any> = async (formData: any) => {
    setLoading(true);

    let response: ApiResponse = await apiCall(PostApi.insertReview(formData.content, abortController.signal));

    if (response.success) {
      message.success("Thank you for submitting your review.");

      if (onSave) onSave();

      if (closeOnSave && onClose) {
        onClose();
      }

    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  return (
    <Popup
      adaptive
      title="Review"
      onCancel={onCancel}
      onClose={onCancel}
      onSave={handleSubmit(onSubmit)}
      fixSize="medium"
      submitText="Leave a Review"
    >
      {loading && <Loader />}
      <group data-direction="column" data-gap="10" data-space='15'>
        <separator horizontal=""></separator>
        <Input
          control={control}
          name="content"
          type={InputType.TextArea}
          dataLength="auto"
          label="Content"
          resize=""
          aria-invalid="false"
          data-height="200"
        />
      </group>
    </Popup>
  );
}
