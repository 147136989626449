// 3rd-party modules
import { message } from "antd";
import { useEffect, useState } from "react";

// project modules
import { apiCall } from "../../../../../helpers/apiHelper";
import Button from "../../../../shared/button";
import ConfirmationPopup from "../../../../shared/popup/confirmationPopup";
import Loader from "../../../../shared/loader";

// apis
import * as DashApi from '../../../../../apis/dashApi';

// models
import { Account } from "../../../../../models/account";
import { AccountKiboshDevice } from "../../../../../models/accountKiboshDevice";
import Popup from "../../../../shared/popup/popup";

type Props = {
  account?: Account;
  accountKiboshDevice?: AccountKiboshDevice;
  refresh?: boolean;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

export default function KiboshDeviceFirmwareUpgrade({ account, refresh, accountKiboshDevice, onCancel, onSave }: Props) {
  const [isUpgradeConfirmationPopupOpen, setIsUpgradeConfirmationPopupOpen] = useState(false);
  const [isUpgradeInProgress, setIsUpgradeInProgress] = useState<boolean>(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [needsClearSettings, setNeedsClearSettings] = useState<boolean>(false);
  const [currentVersion, setCurrentVersion] = useState<string>("");
  const [latestVersion, setLatestVersion] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const abortController = new AbortController();

  useEffect(() => {
    getAccountKiboshDeviceStatusAsync();
  }, []);

  useEffect(() => {
    if (isUpgradeInProgress) {
      const timeInterval = setInterval(() => getAccountKiboshDeviceStatusAsync(false), 10000);
      return () => clearInterval(timeInterval);
    }
  }, [isUpgradeInProgress]);

  const getAccountKiboshDeviceStatusAsync = async (showLoader: boolean = true) => {
    const data = await getAccountKiboshDeviceStatus(showLoader);
    setCurrentVersion(data?.kiboshDevice?.current_firmware_version);

    //it's only happend when the update progress successfully ends
    if (isUpgradeInProgress && data?.kiboshDevice?.upgrade_status === "up_to_date") {
      message.success(`Firmware Upgraded Successfully`);
    }

    setIsUpgradeInProgress(data?.kiboshDevice?.upgrade_status === "upgrade_in_progress")
    setLatestVersion(data?.latestFirmwareVersion);
  }

  const getAccountKiboshDeviceStatus = async (showLoader: boolean = true) => {
    setLoading(true && showLoader);
    const response = await apiCall(DashApi.getKiboshDeviceStatus(accountKiboshDevice?.kiboshDeviceReference!, abortController.signal));
    setLoading(false && showLoader);

    return response.success ? response.data?.value : null;
  };

  const onUpgradeClick = () => {
    setNeedsClearSettings(false);
    setIsUpgradeConfirmationPopupOpen(true);
  };

  const onResetFactoryClick = () => {
    setNeedsClearSettings(true);
    setIsUpgradeConfirmationPopupOpen(true);
  }

  const handleUpgradeRequest = async () => {
    setLoading(true);
    const response = await apiCall(DashApi.updateKiboshDeviceStatus(accountKiboshDevice?.kiboshDeviceReference!, needsClearSettings, abortController.signal));
    setLoading(false);

    if (response.success) {
      message.success(`Device Firmware upgraded started successfully.`);
      setIsUpgradeConfirmationPopupOpen(false);
      setIsUpgradeInProgress(true);
      // getAccountKiboshDeviceStatusAsync();
    } else
      message.error(response.error?.value);
  };

  const onRebootClick = async () => {
    setLoading(true);
    const response = await apiCall(DashApi.rebootKiboshDeviceStatus(accountKiboshDevice?.kiboshDeviceReference!, abortController.signal));
    setLoading(false);

    if (response.success) {
      message.success(`Device restarted successfully.`);
      setIsUpgradeConfirmationPopupOpen(false);
      getAccountKiboshDeviceStatusAsync();
    } else
      message.error(response.error?.value);
  };

  return (
    <>
      {loading &&
        <Loader />
      }
      <view>
        <view data-scroll="">
          {isUpgradeInProgress && <Loader />}
          <group data-space='15'>
            <group data-direction="column" data-gap='10'>
              <group data-direction="column" data-gap='10' data-length='400'>
                <group data-gap='10' data-wrap="no" data-border='' data-radius='5' data-background="main-lighter" data-align='center' data-space='10' style={{ backgroundColor: currentVersion !== latestVersion ? "#fde7e4" : null }}>
                  <text>Current Version</text>
                  <separator data-horizontal="dotted"></separator>
                  <text>{currentVersion}</text>
                </group>
                <group data-gap='10' data-wrap="no" data-border='' data-radius='5' data-background="main-lighter" data-align='center' data-space='10'>
                  <text>Latest Version</text>
                  <separator data-horizontal="dotted"></separator>
                  <text>{latestVersion}</text>
                </group>
              </group>
              {isUpgradeInProgress &&
                <group>
                  <group data-justify="center"><text>Upgrade In Progress...</text></group>
                  <group data-justify="center"><text data-text-size="12">(This should take a few minutes)</text></group>
                </group>
              }
              {/* <group data-direction="column" data-gap='10'>
                  <Checkbox
                      disabled={currentVersion === latestVersion}
                      label="Clear Settings"
                      dataLength="auto"
                      checked={needsClearSettings}
                      onChange={onClearSettingsCheckboxChange} />
                  { !!currentVersion && currentVersion === latestVersion &&<group data-align='center' data-gap='5'><icon data-color='main'>info</icon><text data-color='main' data-weight='600'>Your Firmware is up to date.</text></group>}
                </group> */}
            </group>
          </group>
        </view>
        <group data-space="10" data-gap="10">
          <Button disabled={currentVersion === latestVersion || isUpgradeInProgress} data-length="forcefit" primary onClick={onUpgradeClick}>
            <text>Upgrade Firmware to Latest Version</text>
          </Button>
          <Button disabled={isUpgradeInProgress} data-length="forcefit" outline style={{ backgroundColor: "#f26f5f", color: "#fff" }} onClick={onResetFactoryClick}>
            <text>Reset to Factory Clearing All Settings</text>
          </Button>
          <Button disabled={isUpgradeInProgress} data-position="right" data-length="forcefit" outline onClick={onRebootClick}>
            <text>Restart Kibosh Router</text>
          </Button>
          <Button data-length="forcefit" outline onClick={() => setShowHelpModal(true)}>
            <text>Help</text>
          </Button>
          <Button data-length="forcefit" outline onClick={getAccountKiboshDeviceStatusAsync}>
            <text>Refresh</text>
          </Button>
        </group>
      </view>
      {isUpgradeConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          message={`Do you want to upgrade this device ${needsClearSettings ? "with" : "without"} clear the settings?`}
          positiveButtonText="Upgrade"
          positiveCallback={handleUpgradeRequest}
          negativeCallback={() => {
            setIsUpgradeConfirmationPopupOpen(false);
          }}
        />
      )}
      {showHelpModal &&
        <Popup
          title="Kibosh Router Firmware Upgrade Options"
          adaptive
          onCancel={() => setShowHelpModal(false)}
          onClose={() => setShowHelpModal(false)}
          fixSize="large"
          noCommandbar={true}
        >
          <group data-space="20" data-gap="30">
            <group data-gap="20" data-border="" data-space="20" data-radius="10">
              <text data-weight="700" data-wrap="wrap">Upgrade Firmware to Latest Version</text>
              <group data-space-horizontal="10" data-direction="column" data-gap="5">
                <text data-wrap="wrap">1.  Upgrade your Kibosh’s firmware to most current release</text>
                <text data-wrap="wrap">2.  Existing settings will NOT be deleted</text>
              </group>
              <text data-wrap="wrap">Always use this upgrade option unless you’re having problems.</text>
            </group>

            <group data-gap="20" data-direction="column" data-border="" data-space="20" data-radius="10">
              <text data-weight="700" data-wrap="wrap">Reset to Factory Clearing All Settings</text>
              <group data-gap="20" data-direction="column">
                <group data-direction="column" data-space-horizontal="10" data-gap="5">
                  <text data-wrap="wrap">1.  Upgrade your Kibosh’s firmware to most current release</text>
                  <text data-wrap="wrap">2.  Existing settings WILL be deleted: schedules, filters, blocks, WiFi Settings, Port Forwards, etc.</text>
                  <text data-wrap="wrap">3. Does NOT delete:</text>
                </group>
                <group data-direction="column" data-space-horizontal="20" data-gap="5">
                  <text data-wrap="wrap">a.  Profiles (just the shell)</text>
                  <text data-wrap="wrap">b.  Device Specific Settings (static IP, custom naming)</text>
                  <text data-wrap="wrap">c.  Existing History (turns Logging off - you will need to turn back on)</text>
                </group>
                <text data-wrap="wrap">Use this upgrade option if you’re having issues.</text>
              </group>

            </group>


            <group data-gap="20" data-direction="column" data-border="" data-space="20" data-radius="10">
              <text data-weight="700" data-color="secondary" data-wrap="wrap">Disaster Recovery Options</text>
              <group data-gap="20" data-direction="column" >
                <group data-direction="column"  data-gap="5">
                  <text data-wrap="wrap">Must be on Kibosh WiFi / LAN.</text>
                  <text data-wrap="wrap">Go to 192.168.10.1 and login using your Kibosh.com password.</text>
                </group>

                <text data-weight="700" data-wrap="wrap">Reset to Factory Defaults</text>

                <group data-space-horizontal="10" data-direction="column" data-gap="5">
                  <text data-wrap="wrap">1.  Upgrade your Kibosh’s firmware to most current release</text>
                  <text data-wrap="wrap">2.  Existing settings WILL be deleted: schedules, filters, blocks, WiFi Settings, Port Forwards, etc.</text>
                  <text data-wrap="wrap">3.  Does NOT delete:</text>
                </group>
                <group data-space-horizontal="20" data-direction="column" data-gap="5">
                  <text data-wrap="wrap">a.  Profiles (just the shell)</text>
                  <text data-wrap="wrap">b.  Device Specific Settings (static IP, custom naming)</text>
                  <text data-wrap="wrap">c.  Existing History (turns Logging off - you will need to turn back on)</text>
                </group>
              </group>
            </group>



            <group data-gap="20" data-direction="column" data-border="" data-space="20" data-radius="10">
              <text data-weight="700" data-color="secondary" data-wrap="wrap">Reset to Factory Defaults and Reset All Data on Server</text>
              <group  data-gap="20" data-direction="column">
                <group data-space-horizontal="10" data-direction="column" data-gap="5">
                  <text data-wrap="wrap">1.  Upgrade your Kibosh’s firmware to most current release</text>
                  <text data-wrap="wrap">2.  Wipes ALL DATA and Resets to Factory</text>
                </group>
                <text data-wrap="wrap">** Only use this option if all others fail to resolve your issue(s).</text>
              </group>
            </group>
          </group >
        </Popup >
      }
    </>
  );
}
