// 3rd-party modules
import useStateRef from "react-usestateref";
import { isMobile } from "react-device-detect";
import { Key, useEffect, useState } from "react";
import { TablePaginationConfig } from "antd";

// project modules
import ListView, { LazyLoadPropTypes, ListViewProps } from "../listView";
import Loader from "../../loader";
import { ListViewType } from "../useListHook";
import { PaginationToolbar } from "../paginationToolbar";

// models
import { FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../../models/dataSourceRequest";

// defines
import Config from '../../../../config';
export declare type DataChangeType = 'lazy' | 'page' | 'reload';
//@ts-ignore
declare type RecordType = Record<Key, any>;

type Props = {
  dataPagination?: boolean; // true: uses getPageAsync to retrieve page data when user clicks on pagination
  filters?: FilterDescriptor[];
  dataTemplate?: string;
  getPageAsync?: (filters?: FilterDescriptor[], sorts?: SortDescriptor[], pagination?: PaginationDescriptor) => Promise<RecordType[]>; // async function to retrieve data
  keyField: any;
  lazyLoadPages?: boolean; // true: uses getPageAsync to retrieve page data one at a time until no data comes back
  loading?: boolean;
  pageSize?: number;
  reload?: boolean;
  renderContainer?: boolean;
  showTotal?: boolean;
  sortOrders?: SortDescriptor[];
  totalRecords?: number; // together with dataPagination provide per page data retrieval functionality
  onDataChange?: (type: DataChangeType, changedRecords: RecordType[]) => void; // emits new retrieved page data in lazy page loading or per page data retrieval
  onItemClick?: (item: Record<string, any>) => void;
  onLazyLoadFinish?: () => void; // emits when lazy loading finished
} & ListViewProps;

export default function DataListView({ dataPagination, dataSource, filters, dataTemplate = '260', keyField, pageSize = Config.defaultTablePageSize, getPageAsync, itemComponent, lazyLoadPages, lazyLoadProps, loading, listProps = { 'data-gap': '10' }, reload, renderContainer = true, scrollerProps, showTotal = false, sortOrders, totalRecords = dataSource?.length || 0, view, onDataChange, onItemClick, onLazyLoadFinish }: Props) {
  const [currentPageNumber, setCurrentPageNumber, currentPageNumberRef] = useStateRef(0);
  const [currentPageSize, setCurrentPageSize, currentPageSizeRef] = useStateRef(pageSize);
  const [data, setData, dataRef] = useStateRef<RecordType[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    defaultPageSize: Config.defaultTablePageSize,
    pageSize: Config.defaultTablePageSize,
  });

  const getPage = (pageNumber: number, pageSize: number, keepPreviousState: boolean = false) => {
    getPageAsync && getPageAsync(filters, sortOrders, { pageNumber, pageSize: pageSize || currentPageSize })
      .then(pageData => {
        if (keepPreviousState)
          setData(prevData => [...prevData, ...pageData]);
        else
          setData(() => pageData);

        if (onDataChange) onDataChange('page', pageData);
      });
  }

  const getPageLazy = () => {
    // the third parameter changed from 'true' to !!currentPageNumberRef.current to not keep Previous State if we are in first page.
    getPage(currentPageNumberRef.current + 1, currentPageSizeRef.current, !!currentPageNumberRef.current);
    setCurrentPageNumber(prevPageNumber => prevPageNumber + 1);
  };

  const getDataSourceLazy = async (startPage: number, pageSize: number) => {
    let pageData: RecordType[] = [];

    if (getPageAsync) {
      do {
        pageData = await getPageAsync(filters, sortOrders, { pageNumber: startPage, pageSize: pageSize || currentPageSize });
        startPage++;

        setData(prevData => [...prevData, ...pageData]);

        if (onDataChange) onDataChange('lazy', pageData);
      } while (pageData.length > 0 && dataRef.current.length < totalRecords)

      if (onLazyLoadFinish) onLazyLoadFinish();
    }
  }

  useEffect(() => {
    if (dataSource)
      setData(dataSource);
    else if (getPageAsync) {
      if (dataPagination)
        getPage(1, currentPageSizeRef.current);
      else if (lazyLoadPages) {
        getPageLazy();

        /*
        if (!isMobile)
          getDataSourceLazy(1, currentPageSizeRef.current);
        else
          getPageLazy();
        */
      }
    }
  }, []);

  useEffect(() => {
    if (reload) {
      setData(() => []);

      if (dataSource)
        setData(dataSource);
      else if (getPageAsync) {
        if (dataPagination)
          getPage(currentPageNumberRef.current, currentPageSizeRef.current);
        else if (lazyLoadPages) {
          getDataSourceLazy(1, currentPageSize);
        }
      }
    }
  }, [reload]);

  const onPaginationChange = (pagination: TablePaginationConfig) => {
    if (pagination.pageSize || Config.defaultTablePageSize > currentPageSize)
      pagination.current = 1;

    getPage(pagination.current || 1, pagination.pageSize || Config.defaultTablePageSize);
    setCurrentPageSize(() => pagination.pageSize || Config.defaultTablePageSize);
    setCurrentPageNumber(() => pagination.current || 1);
  };

  const ListViewEx = () => {
    return (
    <>
      {loading && <Loader />}
      <ListView
        dataSource={data}
        view={ListViewType.Block}
        keyField={keyField}
        data-template={dataTemplate}
        lazyLoadProps={lazyLoadPages && isMobile && lazyLoadProps || {
          loadMoreData: getPageLazy,
          total: totalRecords - ((currentPageNumberRef.current - 1) * currentPageSizeRef.current)
        }}
        onItemClick={onItemClick}
        listProps={listProps}
        itemComponent={itemComponent}
      />
    </>);
  };

  return (
    <>
      {renderContainer ?
      <group
      //data-sticky="top"
      //data-scroll=""
      data-width="auto"
      //data-space="40"

      data-gap="30"
      >
        <ListViewEx />
      </group> :
      <ListViewEx />}
      {dataPagination && <PaginationToolbar pagination={pagination} setPagination={setPagination} onChange={onPaginationChange} total={dataSource?.length || totalRecords} showTotal={showTotal} />}
    </>
  );
}
