import classNames from 'classnames';
import { HTMLAttributes, ReactNode } from 'react';

interface TextProps extends HTMLAttributes<any> {
  isName?: boolean;
  accent?: boolean;
  children?: string | ReactNode;
  className?: string;
  contenteditable?: boolean;
  dataHeading?: boolean;
  dataShrink?: string;
  dataTextSize?: string;
  dataWeight?: string;
  digit?: boolean;
  error?: boolean;
  ellipsis?: boolean;
  highlight?: boolean;
  large?: boolean;
  light?: boolean;
  medium?: boolean;
  middle?: boolean;
  private?: boolean;
  space?: boolean;
  dataWrap?: string;
  wrapLine?: boolean;
  translateArgs?: Record<string, string | number>;
}

export const Text: React.FC<TextProps> = ({
  isName = false,
  accent = false,
  children = '',
  className = '',
  contenteditable = false,
  dataHeading,
  dataShrink,
  dataTextSize,
  dataWeight = '',
  digit = false,
  error = false,
  ellipsis = false,
  highlight = false,
  large = false,
  light = false,
  medium = false,
  middle = false,
  space = false,
  dataWrap,
  wrapLine = false,
  translateArgs,
  ...rest
}) => {
  const props: any = {};

  accent && (props['data-accent'] = '');
  dataHeading && (props['data-heading'] = '');
  dataTextSize && (props['data-text-size'] = dataTextSize);
  dataShrink && (props['data-shrink'] = dataShrink);
  dataWeight && (props['data-weight'] = dataWeight);
  digit && (props.digit = digit);
  error && (props.error = error);
  highlight && (props.highlight = highlight);
  large && (props.large = large);
  medium && (props.medium = medium);
  middle && (props.middle = middle);
  light && (props['data-light'] = '');
  ellipsis && (props.ellipsis = '');
  space && (props.space = '');
  dataWrap && (props['data-wrap'] = dataWrap);
  wrapLine && (props['wrap-line'] = wrapLine);

  return (
    <text className={classNames(className)} {...props} {...rest}>
      {children}
    </text>
  );
};
