// 3rd-party modules
import { message } from 'antd';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";

// project modules
import AttachmentItem from '../../attachments/AttachmentItem';
import Input from "../../shared/inputs/input";
import Popup from '../../shared/popup/popup';
import Loader from "../../shared/loader";
import yup from "../../../plugins/yup";
import { apiCall } from "../../../helpers/apiHelper";
import Button from '../../shared/button';

// apis
import * as DashApi from '../../../apis/dashApi';

// models
import { AccountDevice } from '../../../models/accountDevice';
import { accountDeviceGroupUpdateViewModel } from '../../../models/types/accountDeviceGroup';
import { AccountDeviceGroup } from '../../../models/accountDeviceGroup';
import { ApiResponse } from "../../../models/response";
import { Attachment } from '../../../models/attachment';

type Props = {
  closeOnSave?: boolean;
  modalTitle?: string;
  open: boolean;
  accountDevice?: AccountDevice;
  accountDeviceGroup?: AccountDeviceGroup;
  kiboshDeviceReference: string;
  onClose?: () => void;
  onSave?: (accountDeviceGroup: any) => void;
};

export default function AccountDeviceGroupCreationModal({ closeOnSave = false, modalTitle = "", open, accountDeviceGroup, accountDevice, kiboshDeviceReference, onClose, onSave }: Props) {
  const schema = yup.object().shape({
    name: yup.string().label("Device Name").max(256).required(),
    icon: yup.string().nullable()
  });
  const { control, handleSubmit, setValue, setFocus } = useForm<accountDeviceGroupUpdateViewModel | any>({
    defaultValues: { ...accountDeviceGroup },
    resolver: yupResolver(schema),
  });
  const [attachment, setAttachment] = useState<Attachment>(new Attachment());
  const [loading, setLoading] = useState(false);
  const imageRef = useRef<HTMLInputElement>(null);
  const abortController = new AbortController();

  useEffect(() => {
    if (open) setFocus("name");
  }, [open]);

  useEffect(() => {
    const attachment = new Attachment();
    attachment.fileBase64 = accountDeviceGroup?.icon
    setAttachment(attachment);
  }, [accountDeviceGroup])

  const onAttachmentItemChange = (attachment: Attachment) => {
    //TODO: Make sure attachment.file.name has png extension, else clear icon
    setAttachment(attachment)
    setValue("icon", attachment.fileBase64);
  }

  const onCancel = () => {
    abortController.abort();

    if (onClose) onClose();
  };

  const onSubmit: SubmitHandler<accountDeviceGroupUpdateViewModel> = async (formData: accountDeviceGroupUpdateViewModel) => {
    let response: ApiResponse;

    setLoading(true);

    formData.kiboshDeviceReference = kiboshDeviceReference;

    if (!accountDeviceGroup?.id)
      response = await apiCall(DashApi.insertDeviceGroup(formData, accountDevice?.id, abortController.signal));
    else
      response = await apiCall(DashApi.updateDeviceGroup(accountDeviceGroup.id, formData, abortController.signal));

    if (response.success) {
      message.success(`Profile ${!accountDeviceGroup?.id ? 'added' : 'updated'} successfully.`);

      if (onSave) onSave(formData);

      if (closeOnSave) onCancel();
    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  const onEditImageClick = () => {
    if (imageRef?.current) {
      imageRef.current.click();
    }
  }

  return (
    <Popup
      adaptive
      title={ modalTitle || !!accountDeviceGroup?.id ? `Profile Name: ${accountDeviceGroup?.name}` : `Create new Profile${!!accountDevice?.id ? " based on this device settings" : ""}`}
      onCancel={onCancel}
      onClose={onCancel}
      onSave={handleSubmit(onSubmit)}
      fixSize="small"
    >
      {loading && <Loader />}
      <group data-gap="20" data-direction="column" data-contain="">
        <input name="icon" type='hidden' />
        <AttachmentItem
          ref={imageRef}
          item={attachment}
          allowedExtensions=".jpg"
          allowedSize={52428800} // 50MB
          placeholder='User Profile'
          showPreview={true}
          showDoodle={true}
          textForPreview={accountDeviceGroup?.name}
          onChange={onAttachmentItemChange}
          onError={(err) => message.error(err.message)}
        />
        <group data-space="15">
          <Input
            control={control}
            name="name"
            label="Profile Name"
            dataLength="auto"
            size="large"

          />
          <group data-space-top="10">
            <Button outline onClick={onEditImageClick}>
              <text>Edit Image</text>
            </Button>

          </group>
        </group>
      </group>
    </Popup>
  );
}
