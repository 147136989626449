// project modules
import { getBaseUrl, sendRequest } from '../helpers/apiHelper';

// models
import { AccountDevice } from '../models/accountDevice';
import { accountDeviceInsertViewModel, accountDeviceUpdateViewModel } from '../models/types/accountDevice';
import { DataSourceRequest } from '../models/dataSourceRequest';

export const getAccountDevice = async (deviceId: number, request?: DataSourceRequest, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountDevice/${deviceId}`, 'post', request, null, abortSignal);
};

export const getAccountDevices = async (accountId: number, request?: DataSourceRequest, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountDevice/account/${accountId}/List`, 'post', request, null, abortSignal);
};

export const insertAccountDevice = async (accountDevice: accountDeviceInsertViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountDevice`, 'post', accountDevice, null, abortSignal);
};

export const updateAccountDevice = async (accountDevice: accountDeviceUpdateViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountDevice`, 'put', accountDevice, null, abortSignal);
};

export const deleteAccountDevice = async (accountDevice: AccountDevice, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountDevice`, 'delete', accountDevice, null, abortSignal);
};

