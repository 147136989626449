// 3rd-party modules
import moment from 'moment-timezone';
import { Button, ConfigProvider, DatePicker, Input, InputRef, Space, Table } from 'antd';
import type { ColumnType, TableProps } from 'antd/lib/table';
import { ColumnFilterItem, FilterConfirmProps, FilterValue, SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';

// project modules
import InputCheckbox from '../../inputs/checkbox';
import { PaginationToolbar } from '../paginationToolbar';
import { ListViewType, useList } from '../useListHook';

// models
import { DataType } from '../../../../models/types/common';
import { ESortOrder, FilterDescriptor, PaginationDescriptor, SortDescriptor } from '../../../../models/dataSourceRequest';

// defines
import Config from '../../../../config';

const customizeRenderEmpty = (noDataFoundMessage?: string) => (
  <group data-space="40"
         data-align="center"
         data-direction="column"
         data-gap="5">
    <icon>folder</icon>
    <text>{ noDataFoundMessage || 'Data Not Found' }</text>
  </group>
);

type DataIndex = keyof DataType;

export interface TableColumn<RecordType> extends ColumnType<RecordType> {
  dataType?: DataType;
  editable?: boolean; // bool | number | date | datetime | string
  ellipsis?: boolean;
  filterable?: boolean | ColumnFilterItem[];
  searchable?: boolean;
  summaryText?: string;
  children?: TableColumn<RecordType>;
  renderSummary?: (text?: any) => string;
  clearFilters?: () => void;
}

export declare type DataChangeType = 'lazy' | 'page' | 'reload';

interface Props<RecordType> extends TableProps<RecordType> {
  clearFilters?: boolean;
  columns: TableColumn<RecordType>[];
  customPagination?: boolean;
  customPageSize?: number;
  dataPagination?: boolean; // true: uses getPageAsync to retrieve page data when user clicks on pagination
  defaultFilters?: FilterDescriptor[];
  defaultSortOrders?: SortDescriptor[];
  getPageAsync?: (filters?: FilterDescriptor[], sorts?: SortDescriptor[], pagination?: PaginationDescriptor) => Promise<RecordType[]>; // async function to retrieve data
  lazyLoadPages?: boolean; // true: uses getPageAsync to retrieve page data one at a time until no data comes back
  noDataFoundMessage?: string;
  reload?: boolean;
  showTotal?: boolean;
  totalRecords?: number; // together with dataPagination provide per page data retrieval functionality
  onDataChange?: (type: DataChangeType, changedRecords: RecordType[]) => void; // emits new retrieved page data in lazy page loading or per page data retrieval
  onLazyLoadFinish?: () => void; // emits when lazy loading finished
};

export default function DataTable<RecordType extends object = any>({ clearFilters, columns, customPagination = false, customPageSize = 0, dataPagination, dataSource, defaultFilters = [], defaultSortOrders = [], getPageAsync, lazyLoadPages, pagination, reload, showTotal, totalRecords, noDataFoundMessage, onDataChange, onLazyLoadFinish, /* onPageChange, */ ...props }: Props<RecordType>) {
  const [data, setData] = useState<RecordType[]>([]);
  const [summaryList, setSummaryList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(customPageSize || Config.defaultTablePageSize);
  const [tableColumns, setTableColumns] = useState<TableColumn<RecordType>[]>([]);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>({
    ...pagination,
    defaultPageSize: customPageSize || Config.defaultTablePageSize,
    pageSize: customPageSize || Config.defaultTablePageSize,
    total: dataPagination ? totalRecords : dataSource?.length,
    showTotal: showTotal ? (total) => `Total rows: ${total}` : undefined
  });
  const filterList = useRef<FilterDescriptor[]>(defaultFilters);
  const sortList = useRef<SortDescriptor[]>(defaultSortOrders);
  const searchInput = useRef<InputRef>(null);

  if ((lazyLoadPages || dataPagination) && dataSource)
    throw Error("Lazy page loading or per page data retrieval can't be used with data-source.");

  if ((lazyLoadPages || dataPagination) && !getPageAsync)
    throw Error("getPageAsync is required for lazy page loading or per page data retrieval.");

  if (dataPagination && totalRecords === undefined)
    throw Error("totalRecords is required for per page data retrieval.");

  const getPage = (pageNumber: number, pageSize: number, filters?: FilterDescriptor[], sorts?: SortDescriptor[]) => {
    getPageAsync && getPageAsync(filters, sorts, { pageNumber, pageSize })
      .then(pageData => {
        setData(() => pageData);

        if (onDataChange) onDataChange('page', pageData);
      });
  }

  /*
  pagination = {
    ...pagination,
    defaultPageSize: Config.defaultTablePageSize,
    pageSize: Config.defaultTablePageSize,
    total: dataPagination ? totalRecords : dataSource?.length,
    showTotal: showTotal ? (total) => `Total rows: ${total}` : undefined
  };
  */

  const { pagingProps, sort, setSort } = useList({
    viewTypes: [ListViewType.Table],
  });

  /*
  const handlePageChange = (pageNumber: number, pageSize: number) => {
    if (lazyLoadPages && pageNumber <= Math.floor(data.length / pageSize)) return undefined;

    if (dataPagination && getPageAsync) {
      getPageAsync(pageNumber, pageSize, filterList.current, sortList.current)
        .then(pageData => {
          setData(() => pageData);

          if (onDataChange) onDataChange('page', pageData);
        });
    } else
      return undefined;
  };
  */

  const getDataSourceLazy = async (startPage: number) => {
    let pageData: RecordType[] = [];

    if (getPageAsync) {
      do {
        pageData = await getPageAsync(filterList.current, sortList.current, { pageNumber: startPage, pageSize });
        startPage++;

        setData(prevData => [...prevData, ...pageData]);

        if (onDataChange) onDataChange('lazy', pageData);
      } while (pageData.length > 0)

      if (onLazyLoadFinish) onLazyLoadFinish();
    }
  }

  useEffect(() => {
    if ((lazyLoadPages || dataPagination) && getPageAsync) {
      getPageAsync(defaultFilters, defaultSortOrders, { pageNumber: 1, pageSize: customPageSize || Config.defaultTablePageSize })
        .then((pageData) => {
          setData(prevData => [...prevData, ...pageData]);

          if (onDataChange) onDataChange('page', pageData);

          if (lazyLoadPages && pageData.length === pageSize) getDataSourceLazy(2);
        });
    }
  }, []);

  useEffect(() => {
    let summaryList: any[] = []
    const summaryColumns = columns.filter(x => x.summaryText);

    summaryColumns?.forEach(summaryColumn => {
      if (summaryColumn.dataType === "number") {
        const summary = data.map((a: any) => { return a[summaryColumn.dataIndex! as keyof any]})?.reduce((a: any, b: any) => { return a + b; }, 0);

        summaryList.push({
          text: summaryColumn.summaryText,
          value: summaryColumn.renderSummary ? summaryColumn.renderSummary(summary) : summary
        }
      )
      }
    });

    setSummaryList(summaryList);
  }, [data]);

  useEffect(() => {
    if (reload) {
      // when default-filters are set at the begining and same filter changes later we get duplicate filters
      const filters = mergeFilters(defaultFilters, filterList.current);

      // setData(prevData => []);

      /*
      getPageAsync && getPageAsync(filters, defaultSortOrders, { pageNumber: 1, pageSize: Config.defaultTablePageSize })
        .then((pageData) => {
          setData(prevData => pageData);
        });
      */

      if (!lazyLoadPages && dataPagination && getPageAsync) {
        getPageAsync(filters, defaultSortOrders, { pageNumber: 1, pageSize: customPageSize || Config.defaultTablePageSize })
          .then((pageData) => {
            setData(pageData);

            if (onDataChange) onDataChange('reload', pageData);
          });
      } else if ((lazyLoadPages || dataPagination) && getPageAsync) {
        getPageAsync(filters, defaultSortOrders, { pageNumber: 1, pageSize: customPageSize || Config.defaultTablePageSize })
          .then((pageData) => {
            setData(prevData => [...prevData, ...pageData]);

            if (onDataChange) onDataChange('reload', pageData);

            if (lazyLoadPages && pageData.length === pageSize) getDataSourceLazy(2);
          });
      }
    }
  }, [reload]);

  useEffect(() => {
    if (clearFilters) {
      for (const column of columns) {
        column.clearFilters && column.clearFilters();
      }
    }
  }, [clearFilters]);

  useEffect(() => {
    setTableColumns(handleColumns(columns));
  }, [columns]);

  const handleColumns = (columns: TableColumn<any>[]): TableColumn<any>[] => {
    for (const column of columns) {
      switch(column.dataType) {
        case 'bool':
          if (column.sorter === undefined && column.dataIndex)
            column.sorter = (a, b) => (a[column.dataIndex! as keyof any] === b[column.dataIndex! as keyof any]) ? 0 : !a[column.dataIndex! as keyof any] ? -1 : 1;

          if (!column.render)
            column.render = (text) => (<group data-justify="center"><InputCheckbox checked={text === true} disabled={true} /></group>)

          break;
        case 'number':
          if (column.sorter === undefined && column.dataIndex)
            column.sorter = (a, b) => a[column.dataIndex! as keyof any] - b[column.dataIndex! as keyof any];

          break;
        case 'date':
          if (column.sorter === undefined && column.dataIndex)
            column.sorter = (a, b) => {
              if (a[column.dataIndex! as keyof any] && b[column.dataIndex! as keyof any])
                return moment.utc(new Date(a[column.dataIndex! as keyof any])).diff(moment.utc(new Date(b[column.dataIndex! as keyof any])));
              else
                return 1;
            };

          if (!column.render)
            column.render = (text) => moment.utc(new Date(text)).local().format("MM/DD/YYYY");

          break;
        case 'datetime':
          if (column.sorter === undefined && column.dataIndex)
            column.sorter = (a, b) => {
              if (a[column.dataIndex! as keyof any] && b[column.dataIndex! as keyof any])
                return moment.utc(new Date(a[column.dataIndex! as keyof any])).diff(moment.utc(new Date(b[column.dataIndex! as keyof any])));
              else
                return 1;
            };

          if (!column.render)
            column.render = (text) => moment.utc(new Date(text)).local().format("MM/DD/YYYY HH:mm:ss");

          break;
        case 'string':
        default:
          if (column.sorter === undefined && column.dataIndex)
            column.sorter = (a, b) => {
              if (a[column.dataIndex! as keyof any])
                return a[column.dataIndex! as keyof any].toString().localeCompare(b[column.dataIndex! as keyof any]);
            };

          break;
      }

      if (column.filterable && !column.filterDropdown) {
        column.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
          const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
            /*
            if (dataPagination) {
              if (!filterList.current.find(f => f.fieldName === dataIndex)) {
                filterList.current.push({
                  fieldName: dataIndex.toString(),
                  fieldValue: selectedKeys[0],
                  compareMode: 'containsAny'
                });
              }

              getPage(currentPage, pageSize, filterList.current, sortList.current);
            }
            */

            // setSearchText(selectedKeys[0]);
            // setSearchedColumn(dataIndex.toString());
            confirm({ closeDropdown: true });
          };

          const handleReset = (clearFilters: () => void, dataIndex: DataIndex) => {
            filterList.current = filterList.current.filter(f => f.fieldName !== dataIndex);
            filterList.current = [...defaultFilters, ...filterList.current];

            clearFilters();
            getPage(currentPage, pageSize, filterList.current, sortList.current);
          };

          switch (column.dataType) {
            case 'date':
            case 'datetime':
              return (
                <div style={{ padding: 8 }}>
                  <DatePicker placeholder={`Search ${column.title}`}
                              style={{ marginBottom: 8, display: 'block' }}
                              onChange={(date: any, dateString: string | string[]) => setSelectedKeys(date ? [moment(date).format('YYYY-MM-DD')] : [])}
                  />
                  <Space>
                    <Button icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                            type="primary"
                            onClick={() => handleSearch(selectedKeys as string[], confirm, column.dataIndex as DataIndex)}>
                      Search
                    </Button>
                    <Button size="small"
                            style={{ width: 90 }}
                            onClick={() => clearFilters && handleReset(clearFilters, column.dataIndex as DataIndex)}>
                      Reset
                    </Button>
                  </Space>
                </div>
              );
            case 'string':
            default:
              return (
                <div style={{ padding: 8 }}>
                  <Input placeholder={`Search ${column.title}`}
                        ref={searchInput}
                        style={{ marginBottom: 8, display: 'block' }}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={(e) => { e.stopPropagation(); handleSearch(selectedKeys as string[], confirm, column.dataIndex as DataIndex)} }
                  />
                  <Space>
                    <Button icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                            type="primary"
                            onClick={() => handleSearch(selectedKeys as string[], confirm, column.dataIndex as DataIndex)}>
                      Search
                    </Button>
                    <Button size="small"
                            style={{ width: 90 }}
                            onClick={() => clearFilters && handleReset(clearFilters, column.dataIndex as DataIndex)}>
                      Reset
                    </Button>
                  </Space>
                </div>
              );
          }

          column.clearFilters = clearFilters;
        };
      }

      if (!column.filterIcon) {
        column.filterIcon = (filtered: boolean) => (
          <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        );
      }

      if (!column.onFilter) {
        if (!dataPagination)
          column.onFilter = (value, record) =>
            record[column.dataIndex as keyof DataIndex].toString().toLowerCase().includes((value as string).toLowerCase());
      }

      if (!column.onFilterDropdownOpenChange) {
        column.onFilterDropdownOpenChange = visible => {
          if (visible)
            setTimeout(() => searchInput.current?.select(), 100);
        }
      }
    }

    return columns;
  }

  const onChange = async (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<RecordType> | SorterResult<RecordType>[], extra: TableCurrentDataSource<RecordType>) => {
    setCurrentPage(pagination.current || 1);
    setPageSize(pagination.pageSize || customPageSize || Config.defaultTablePageSize);

    for (let filter in filters) {
      const filterValue = filters[filter];

      if (filterValue) {
        for (let i = 0; i < filterValue.length; i++) { // filterValue is in array format
          filterList.current = filterList.current.filter(f => f.fieldName !== filter);

          filterList.current.push({
            fieldName: filter,
            fieldValue: filterValue[i],
            compareMode: 'containsAny' // case-insensitive string comparison
          });
        }
      } else
        filterList.current = filterList.current.filter(f => f.fieldName !== filter);
    }

    // TODO: Add support for multiple sorts
    if (!Array.isArray(sorter) && sorter.field && sorter.order) {
      sortList.current = [{
        fieldName: sorter.field as string,
        sortOrder: sorter.order === 'ascend' ? ESortOrder.asc : ESortOrder.desc
      }];

      getPage(pagination.current || 1, pagination.pageSize || customPageSize || Config.defaultTablePageSize, filterList.current, [{
        fieldName: sorter.field as string,
        sortOrder: sorter.order === 'ascend' ? ESortOrder.asc : ESortOrder.desc
      }]);
    } else
      getPage(pagination.current || 1, pagination.pageSize || customPageSize || Config.defaultTablePageSize, filterList.current, sortList.current);
  };

  const mergeFilters = (baseFilters: FilterDescriptor[], compareFilters: FilterDescriptor[]) => {
    const filters = [...baseFilters];

    compareFilters.forEach((compareFilter) => (filters.some((filter) => compareFilter.fieldName === filter.fieldName)) ? null : filters.push(compareFilter))

    return filters;
  }

  return (
    <>
      <ConfigProvider
        renderEmpty={() => {
          return customizeRenderEmpty(noDataFoundMessage);
        }}
      >
        <Table
          {...props}
          columns={tableColumns}
          dataSource={dataSource || data}
          tableLayout="auto"
          // pagination={pagination ? tablePagination : false}
          pagination={tablePagination || false}
          onChange={onChange}
          ellipsis={true}
          scroll={{
            y: "100%",
            // x: "max-content",
          }}
        />
        {customPagination && (!!dataSource?.length || !!data?.length) && (
          <PaginationToolbar {...pagingProps} onChange={(pagination: TablePaginationConfig) => setTablePagination(pagination)} pagination={tablePagination} total={dataSource?.length || data?.length} summaryList={summaryList} />
        )}
      </ConfigProvider>
    </>
  );
}
