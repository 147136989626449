// project modules
import { getBaseUrl, sendRequest } from '../helpers/apiHelper';

// models
import { CommonValue } from '../models/commonValue';

export const getAccountTypes = async (abortSignal?: AbortSignal) => {
  return await getCommonValues('account-type', abortSignal);
};

export const getApplicationCategories = async (abortSignal?: AbortSignal) => {
  return await getCommonValues('application-category', abortSignal);
};

export const getDeviceTypes = async (abortSignal?: AbortSignal) => {
  return await getCommonValues('kibosh-device-type', abortSignal);
};

export const getClientDeviceTypes = async (abortSignal?: AbortSignal) => {
  return await getCommonValues('client-device-type', abortSignal);
};

export const getPostCategories = async (abortSignal?: AbortSignal) => {
  return await getCommonValues('post-category', abortSignal);
};

export const getPostLanguages = async (abortSignal?: AbortSignal) => {
  return await getCommonValues('post-language', abortSignal);
};

export const getPostStatuses = async (abortSignal?: AbortSignal) => {
  return await getCommonValues('post-status', abortSignal);
};

export const getPostTypes = async (abortSignal?: AbortSignal) => {
  return await getCommonValues('post-type', abortSignal);
};

export const getTicketCategories = async (abortSignal?: AbortSignal) => {
  return await getCommonValues('support-ticket-category', abortSignal);
};

export const getTicketPriorities = async (abortSignal?: AbortSignal) => {
  return await getCommonValues('support-ticket-priority', abortSignal);
};

export const getTicketStatuses = async (abortSignal?: AbortSignal) => {
  return await getCommonValues('support-ticket-status', abortSignal);
};

export const getCommonValues = async (commonCode: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/commonValue/commonCode/${commonCode}`, 'get', null, null, abortSignal);
};

export const createCommonValue = async (commonValue: CommonValue, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/commonValue`, 'post', commonValue, null, abortSignal);
};

export const updateCommonValue = async (commonValue: CommonValue, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/commonValue`, 'put', commonValue, null, abortSignal);
};

export const deleteCommonValue = async (commonValue: CommonValue, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/commonValue`, 'delete', commonValue, null, abortSignal);
};
