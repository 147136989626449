// 3rd-party modules
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// project modules
import Select from '../shared/inputs/select';
import Popup from '../shared/popup/popup';
import Loader from '../shared/loader';
import Button from '../shared/button';
import { apiCall } from '../../helpers/apiHelper';
import { RootState } from '../../stores';
import { useAppDispatch } from '../../hooks/storeHooks';
import { setKiboshDevice } from '../../stores/userStore';

// apis
import * as AccountKiboshDeviceApi from '../../apis/accountKiboshDeviceApi';

// models
import { Account } from '../../models/account';
import { AccountKiboshDevice } from '../../models/accountKiboshDevice';
import { DataSourceRequest } from '../../models/dataSourceRequest';

type Props = {
  open: boolean;
  account: Account;
  modalTitle?: string;
  onClose?: () => void;
  onSave?: () => void;
};

export default function KiboshDevicePickerModal({ open, account, modalTitle = "", onClose, onSave }: Props) {
  const kiboshDevice = useSelector((state: RootState) => state.user.kiboshDevice) || new AccountKiboshDevice();
  const dispatch = useAppDispatch();

  const [accountKiboshDevices, setAccountKiboshDevices] = useState<AccountKiboshDevice[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentKiboshDeviceReference, setCurrentKiboshDeviceReference] = useState<string>(kiboshDevice.kiboshDeviceReference || "");

  const abortController = new AbortController();

  useEffect(() => {
    if (account.accountId) {
      getAccountKiboshDevicesAsync()
    }
  }, [account])

  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onSubmit= async () => {
    const data = accountKiboshDevices.find(x => x.kiboshDeviceReference === currentKiboshDeviceReference);
    dispatch(setKiboshDevice(data || new AccountKiboshDevice()));
    if (onSave) {
      onSave()
    }
  };

  const getAccountKiboshDevicesAsync = async () => {
    setAccountKiboshDevices(await getAccountKiboshDevices({}, abortController.signal));
  }

  const getAccountKiboshDevices = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
    setLoading(true);
    const response = await apiCall(AccountKiboshDeviceApi.getAccountKiboshDevices(account?.accountId!, request, abortSignal));
    setLoading(false);

    return response.success ? AccountKiboshDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  return (
    <Popup
      title={ modalTitle || "Select Kibosh device"}
      onCancel={onCancel}
      onClose={onCancel}
      onSave={onSubmit}
      fixSize="small"
    >
      {loading && <Loader />}
      <group data-space="15" data-gap="10" data-direction="column">
      <Select
        autoComplete=""
        label="Kibosh Device"
        labelPosition="left"
        dataLength="320"
        defaultValue={currentKiboshDeviceReference}
        onChange={(e: any) => setCurrentKiboshDeviceReference(e)}
        loading={loading}
        allowSearch={true}
        options={
          accountKiboshDevices?.map((item) => {
            return {
              text: `${item.kiboshDeviceName!} (${item.kiboshDeviceReference!})`,
              value: item.kiboshDeviceReference!,
            };
          }) || []
        }
        button={
          <Button
            material
            icon="refresh"
            onClick={getAccountKiboshDevicesAsync}
          />
        }
      />
      </group>
    </Popup>
  );
}
