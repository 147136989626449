// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToInstance } from 'class-transformer';

export class SupportTicket {
  @Expose()
  public supportTicketId?: number;
  @Expose()
  public contactName?: string;
  @Expose()
  public contactEmail?: string;
  @Expose()
  public ticketCategoryId?: string;
  @Expose()
  public ticketCategory?: string;
  @Expose()
  public accountId?: string;
  @Expose()
  public accountName?: string;
  @Expose()
  public accountProductId?: string;
  @Expose()
  public clientDeviceId?: string;
  @Expose()
  public orderId?: string;
  @Expose()
  public status?: string;
  @Expose()
  public priority?: string;
  @Expose()
  public subject?: string;
  @Expose()
  public detail?: string;
  @Expose()
  public profile?: string;
  @Expose()
  public assigneeUserId?: string;
  @Expose()
  public assigneeFullName?: string;
  @Expose()
  public createdAt?: Date;
  @Expose()
  public updatedAt?: Date;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(SupportTicket, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(SupportTicket, camelcaseKeys(data, { deep: true }) as SupportTicket[], { excludeExtraneousValues: true });
  }
}
