// 3rd-party modules
import { useEffect, useState } from 'react';

// project modules
import AccountTabs from './AccountTabs';

// models
import { Account } from '../../models/account';
import Popup from '../shared/popup/popup';

// defines
type Props = {
  closeOnSave?: boolean;
  modalTitle?: string;
  open: boolean;
  account: Account;
  preSelectedTab?: string;
  showTabs?: boolean;
  onClose?: () => void;
  onSave?: (account: Account) => void;
};

export default function AccountModal({ closeOnSave = false, modalTitle = "", open, account, preSelectedTab, showTabs = true, onClose, onSave }: Props) {
  const [currentAccount, setCurrentAccount] = useState<Account>(account);
  const abortController = new AbortController();

  useEffect(()=> {
    if (open) {
      setCurrentAccount(account);
    }
  },[open]);

  const onModalClose = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onModalSave = () => {
    if (onSave) {
      onSave(account);
    }

    if (closeOnSave) {
      open = false;

      onModalClose();
    }
  };

  return (
    <Popup
      title={modalTitle || !currentAccount.accountId ? "New Account" : `Account Name: ${currentAccount.firstName} ${currentAccount.lastName}`}
      adaptive
      onCancel={onModalClose}
      onClose={onModalClose}
      noCommandbar={true}
      fixSize="x-large"
    >
      <AccountTabs
        refresh={open}
        account={currentAccount}
        preSelectedTab={preSelectedTab}
        showTabs={showTabs}
        onClose={onModalClose}
        onSave={onModalSave} />
    </Popup>
  );
}
