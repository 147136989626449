// 3rd-party modules
import { useEffect, useState } from "react";

// project modules
import AccountKiboshDeviceModal from "./AccountKiboshDeviceModal";
import ListView from "../../shared/list/listView";
import Loader from "../../shared/loader";
import Ripple from "../../shared/additionalComponents/Ripple";
import Marquee from "../../shared/additionalComponents/Marquee";
import Popup from "../../shared/popup/popup";
import { ListViewType } from "../../shared/list/useListHook";
import { Text } from "../../shared/text";

// apis

// models
import { Account } from "../../../models/account";
import { AccountKiboshDevice } from "../../../models/accountKiboshDevice";

type Props = {
  account: Account;
  accountKiboshDevice: AccountKiboshDevice;
  deviceReference?: string;
  closeOnSave?: boolean;
  modalTitle?: string;
  open: boolean;
  size?: string;
  onClose?: () => void;
  onSave?: () => void;
};

export default function AccountKiboshDeviceMenuModal({
  account,
  accountKiboshDevice,
  deviceReference = undefined,
  closeOnSave = false,
  modalTitle = "",
  size = "larger",
  open,
  onClose,
  onSave,
}: Props) {
  const [currentAccountKiboshDevice, setCurrentAccountKiboshDevice] =
    useState<AccountKiboshDevice>(accountKiboshDevice);
  const [visibleListItems, setVisibleListItems] = useState<any[]>([]);
  const [visibleSettingsListItems, setVisibleSettingsListItems] = useState<
    any[]
  >([]);
  const [preSelectedTab, setPreSelectedTab] = useState<string>("");
  const [showAccountKiboshDeviceModal, setShowAccountKiboshDeviceModal] =
    useState(false);

  const [loading, setLoading] = useState<boolean>(false);

  const listItems: any[] = [
    {
      id: "details",
      name: "Data",
      icon: "info",
      isActive: true,
      mode: "no-vpn",
    },
    {
      id: "devices",
      name: "Devices",
      icon: "devices",
      isActive: true,
      mode: "no-vpn",
    },
    {
      id: "device-groups",
      name: "Profiles",
      icon: "group",
      isActive: true,
      mode: "no-vpn",
    },
    {
      id: "vpns",
      name: "VPNs",
      icon: "vpn_lock",
      isActive: true,
      mode: "no-vpn",
    },
    {
      id: "blocked-sites",
      name: "Filters",
      icon: "app_blocking",
      isActive: true,
      mode: "all",
    },
    {
      id: "activity-logs",
      name: "History",
      icon: "history",
      isActive: true,
      mode: "all",
    },
    {
      id: "internet-schedules",
      name: "Scheduler",
      icon: "event_available",
      isActive: true,
      mode: "all",
    },
    {
      id: "bandwidth-logs",
      name: "Bandwidth",
      icon: "settings_ethernet",
      isActive: true,
      mode: "all",
    },
    // {
    //   id: 'settings',
    //   name: 'Settings',
    //   icon: "settings",
    //   isActive: true
    // }
  ];

  const settingsListItems: any[] = [
    {
      id: "settings-WiFi-settings",
      name: "WiFi Settings",
      icon: "network_manage",
      isActive: true,
      mode: "no-vpn",
    },
    {
      id: "settings-port-forwarding",
      name: "Port Forwarding",
      icon: "arrows_outward",
      isActive: true,
      mode: "no-vpn",
    },
    {
      id: "settings-firmware-upgrade",
      name: "Firmware Upgrade",
      icon: "upgrade",
      isActive: true,
      mode: "no-vpn",
    },
    {
      id: "settings-filtration",
      name: "Raw Internet",
      icon: "filter_alt",
      isActive: true,
      mode: "no-vpn",
    },
  ];

  const abortController = new AbortController();

  useEffect(() => {
    if (open) {
      if (
        currentAccountKiboshDevice.kiboshDeviceType?.toLowerCase() === "vpn"
      ) {
        setVisibleListItems(listItems.filter((x) => x.mode !== "no-vpn"));
        setVisibleSettingsListItems(
          settingsListItems.filter((x) => x.mode !== "no-vpn")
        );
      } else {
        setVisibleListItems(listItems.filter((x) => x.mode !== "vpn-only"));
        setVisibleSettingsListItems(
          settingsListItems.filter((x) => x.mode !== "vpn-only")
        );
      }
    }
  }, [open]);

  const onAccountKiboshDeviceSave = (e?: AccountKiboshDevice) => {
    if (e) {
      setLoading(true);
      setCurrentAccountKiboshDevice(e);
      setLoading(false);

      if (onSave) onSave();
    }
  };

  const onCancel = () => {
    abortController.abort();

    if (onClose) onClose();
  };

  const onSelectedTab = (selectedTab: any) => {
    if (selectedTab) {
      setPreSelectedTab(selectedTab);
      setShowAccountKiboshDeviceModal(true);
    }
  };

  const ListItem: React.FC<{ data: any }> = ({ data }) => (
    <Ripple>
      <group
        data-align="center"
        data-space="5"
        data-gap="5"
        data-wrap="no"
        // data-radius="5"
        data-contain=""
        data-cursor="pointer"
        data-disabled={data.isActive ? undefined : "grayscale"}
      >
        <group
          data-ratio="1:1"
          data-length="50"
          data-shrink="no"
          data-index="1"
          data-radius="5"
          data-color="main"
        >
          <icon data-icon-size="48" data-position="center">
            {data.icon}
          </icon>
        </group>
        <group
          data-direction="column"
          data-contain=""
          data-space="5"
          data-index="1"
        >
          <Text data-weight="700" data-ellipsis="">
            {data.name}
          </Text>
          {!!data.description && (
            <Marquee>
              <Text data-ellipsis="" data-light="">
                {data.description}
              </Text>
            </Marquee>
          )}
        </group>
        {/* <separator data-vertical=""></separator> */}
        <group
          data-align="center"
          data-wrap="no"
          data-position="right"
          data-width="auto"
          data-index="1"
        >
          <group data-space="10">
            <icon data-opacity="30">chevron_right</icon>
          </group>
        </group>
      </group>
    </Ripple>
  );

  return (
    <Popup
      adaptive
      title={
        modalTitle ||
        `${
          !accountKiboshDevice.accountKiboshDeviceId
            ? "New Kibosh Device"
            : `Kibosh Device Name: ${accountKiboshDevice.kiboshDeviceName}`
        }`
      }
      onCancel={onCancel}
      onClose={onCancel}
      noCommandbar={true}
      fixSize="medium"
    >
      {loading && <Loader />}
      {!!visibleListItems.length && (
        <>
          <group data-space="10" data-color="main" data-space-horizontal="20">
            <text data-weight="700">Dashboard</text>
          </group>
          <group data-space="15">
            <group data-contain="" data-radius="10" data-elevation="2">
              <ListView
                data-template="400"
                view={ListViewType.List}
                dataSource={visibleListItems}
                onItemClick={(item: any) =>
                  item.isActive ? onSelectedTab(item.id) : null
                }
                keyField="id"
                listProps={{ "data-gap": "1" }}
                itemComponent={ListItem}
              />
            </group>
          </group>
        </>
      )}
      {!!visibleSettingsListItems.length && (
        <>
          <group data-space="10" data-color="main" data-space-horizontal="20">
            <text data-weight="700">Settings</text>
          </group>
          <group data-space="15">
            <group data-contain="" data-radius="10" data-elevation="2">
              <ListView
                data-template="300"
                view={ListViewType.List}
                dataSource={visibleSettingsListItems}
                onItemClick={(item: any) =>
                  item.isActive ? onSelectedTab(item.id) : null
                }
                keyField="id"
                listProps={{ "data-gap": "1" }}
                itemComponent={ListItem}
              />
            </group>
          </group>
        </>
      )}

      {!!showAccountKiboshDeviceModal && (
        <AccountKiboshDeviceModal
          modalTitle={
            (modalTitle ||
              `${
                !accountKiboshDevice.accountKiboshDeviceId
                  ? "New Kibosh Device"
                  : `Kibosh Device Name: ${accountKiboshDevice.kiboshDeviceName}`
              }`) +
            " - " +
            [...listItems, ...settingsListItems].find(
              (x) => x.id === preSelectedTab
            ).name
          }
          open={showAccountKiboshDeviceModal}
          showTabs={false}
          closeOnSave={true}
          account={account!}
          size={size}
          accountKiboshDevice={currentAccountKiboshDevice}
          deviceReference={deviceReference}
          preSelectedTab={preSelectedTab}
          onClose={() => setShowAccountKiboshDeviceModal(false)}
          onSave={(e) => onAccountKiboshDeviceSave(e)}
        />
      )}
    </Popup>
  );
}
