import classNames from "classnames";
import React, {
  PropsWithChildren,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import Draggable from "react-draggable";
/* import { addPopup, removePopup } from '../../../store/componentsSlice'; */
import { useAppDispatch } from "../../../hooks/storeHooks";
import Button from "../button";
import { Text } from "../text";
import { addPopup, removePopup } from "../../../stores/componentsSlice";

export const popupRoot: Element = document.getElementById(
  "popup-root"
) as Element;

const Popup: React.FC<
  PropsWithChildren<{
    fullscreen?: boolean;
    noHeader?: boolean;
    onClose: () => void;
    fixSize?: string;
    windowContProps?: any;

    //onClickOutside: () => void;
    closeOnOutsideClick?: boolean;
    refOpener?: RefObject<HTMLElement>;
    title?: string;
    coloredTitle?: boolean;
  }>
> = ({
  fullscreen = false,
  noHeader = false,
  onClose,
  //onClickOutside,
  //closeOnOutsideClick = true,
  //refOpener,
  windowContProps,
  children,
  title = "",
  fixSize,
  coloredTitle = false,
}) => {
  const dispatch = useAppDispatch();
  // const [isMount, setIsMount] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const elementRef = useRef<HTMLDivElement>(null);
  //if (closeOnOutsideClick) {
  //useOnClickOutside(containerRef, closeOnOutsideClick ? onClose : undefined, refOpener); // some silly & temporary solution
  //}

  useEffect(() => {
    // setIsMount(true);
    dispatch(addPopup());

    return () => {
      dispatch(removePopup());
      // setIsMount(false);
    };
  }, []); // eslint-disable-line

  let innerContent = (
    <div ref={elementRef}>
      {!noHeader && (
        <toolbar {...(coloredTitle ? { "data-accent": "" } : null)}>
          <group data-wrap="no" data-align="center">
            <Text data-weight="700" data-space="10" data-ellipsis="">
              {title}
            </Text>
            <Button
              mini
              rounded
              material
              {...(fullscreen ? null : { position: "right" })}
              className="close"
              icon={fullscreen ? "chevron_left" : "close"}
              onClick={onClose}
            />
          </group>

          {/*<text data-weight="700" space="">*/}
          {/*    {t(title)}*/}
          {/*</text>*/}
        </toolbar>
      )}
      <div>
        <div
          id="windowContent"
          className="window_cont"
          {...(windowContProps ? windowContProps : null)}
          fixed={fixSize}
        >
          {/* {isMount && children} */}
          {children}
        </div>
      </div>
    </div>
  );
  if (!fullscreen) {
    innerContent = (
      <Draggable
        nodeRef={elementRef}
        cancel=".window_cont,.close"
        bounds="parent"
      >
        {innerContent}
      </Draggable>
    );
  }

  return createPortal(
    <div
      ref={containerRef}
      className={classNames("popup", "open", { fullscreen })}
    >
      {innerContent}
    </div>,
    popupRoot
  );
};

export default Popup;
