export default function FuturesPage() {
  return (
    <>
      <view data-align="center" data-jusify="center" data-border="none">
        <group
          data-width="auto"
          data-direction="column"
          data-align="center"
          data-jusify="center"
        >
          <icon data-icon-size="medium">home</icon>
          <text>Futures Page</text>
        </group>
      </view>
    </>
  );
}
