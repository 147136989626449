import { Table as AntTable, TableProps as AntTableProps, ConfigProvider } from 'antd';
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { ListViewType, useList } from '../useListHook';
import { PaginationToolbar } from '../paginationToolbar';

export interface ColumnType {
  field?: string;
  type?: string;
  title?: any;
}

const customizeRenderEmpty = (noDataFoundMessage?: string) => (
  <group
    data-space="40"
    data-align="center"
    data-direction="column"
    data-gap="5"
  >
    <icon>folder</icon>
    <text>{noDataFoundMessage || 'Data Not Found'}</text>
  </group>
);

export interface TableProps extends Omit<AntTableProps<Record<string, any>>, 'pagination'> {
  dataSource: any[];
  keyField?: string;
  isLoading?: boolean;
  isFetching?: boolean;
  pagination?: false | TablePaginationConfig;
  customPagination?: boolean;
  setPagination?: any;
  setSorter?: any;
  total?: number;
  columns?: ColumnType[];
  columnCountConfig?: boolean;
  showAllColumns?: boolean;
  rowClassName?: any;
  noDataFoundMessage?: string;
}

const Table: React.FC<TableProps> = ({
  dataSource = [],
  keyField,
  isLoading,
  isFetching,
  pagination = false,
  customPagination = false,
  setSorter,
  columns: columnConfig,
  rowClassName,
  noDataFoundMessage,
  ...rest
}) => {
  let columns = columnConfig;

  let items = dataSource;
  if (keyField) {
    items = dataSource.map((i) => ({ ...i, key: i[keyField] }));
  }
  const { pagingProps, sort, setSort } = useList({
    viewTypes: [ListViewType.Table],
  });
  const {
    pagination: { current, pageSize },
  } = pagingProps;
  return (
    <>
      <ConfigProvider renderEmpty={() => { return customizeRenderEmpty(noDataFoundMessage) }}>
        <AntTable
          columns={columns}
          dataSource={
            customPagination
              ? [...items]
                .sort((a, b) => {
                  const field: string = (sort as any).field;
                  if (field) {
                    const coef = (sort as any).order === "DESC" ? -1 : 1;
                    return coef * (a > b ? -1 : 1);
                  }
                  return 0;
                })
                .slice((current! - 1) * pageSize!, current! * pageSize!)
              : items}
          loading={isLoading || isFetching}
          {...(rowClassName ? { rowClassName } : null)}
          pagination={pagination === false ? { hideOnSinglePage: true, pageSize: items.length } : pagination}
          onChange={(tablePagination, filter, sorter) => {
            const sort = sorter as any;
            setSorter && setSorter((sort?.column ? { ...sorter, field: sort?.column?.sortField || sort.field } : {}) as SorterResult<any>);
          }}

          showSorterTooltip={false}
          scroll={{ x: '100%' }}
          {...rest}
        />
        {(customPagination && !!items.length) && <PaginationToolbar
          {...pagingProps}
          total={items.length}
        />}
      </ConfigProvider>
    </>
  );
};

export default Table;
