// 3rd-party modules
import { message } from "antd";
import { useEffect, useState } from "react";

// project modules
import AttachmentItem from "./AttachmentItem";
import Button from "../shared/button";
import Loader from "../shared/loader";
import { apiCall } from "../../helpers/apiHelper";
import { objectToFormData } from "../../helpers/objectHelper";

// project apis
import * as AttachmentApi from "../../apis/attachmentApi";

// models
import { Attachment } from "../../models/attachment";

type Props = {
  entityCode?: string;
  entityId?: number;
  autosave?: boolean
  holdTillSaveMainEntity?: boolean;
  onAttachmentsLengthChange?: (length: number) => void;
  onUploadEnd?: () => void;
  onUploadStart?: () => void;
};

export default function AttachmentsPicker({
  entityCode,
  entityId,
  autosave = true,
  holdTillSaveMainEntity = true,
  onAttachmentsLengthChange,
  onUploadEnd,
  onUploadStart,
}: Props) {
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [disableUpload, setDisableUpload] = useState(true);
  const [loading, setLoading] = useState(false);

  const abortController = new AbortController();

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (onAttachmentsLengthChange) {
      onAttachmentsLengthChange(attachments.length);
    }
  }, [attachments]);

  useEffect(() => {
    if (autosave && !holdTillSaveMainEntity) {
      onSubmitAttachmentUpload();
    }
  }, [holdTillSaveMainEntity]);

  const onAddAttachment = () => {
    const arr = [...attachments];
    arr.push(new Attachment());
    setAttachments(arr);
  };

  const onCancel = () => {
    abortController.abort();

    setAttachments([]);
  };

  const onAttachmentUpload = (doc: Attachment, index: number) => {
    const arr = [...attachments];
    arr[index] = doc;
    setAttachments(arr);
    setDisableUpload(false);

    if (autosave && entityCode && entityId && !disableUpload && !holdTillSaveMainEntity) { // to save on change on update
      attachmentUploader(doc);
    }
  };

  const onRemoveAttachment = (index: number) => {
    const arr = [...attachments].filter((attachment, i) => i !== index);
    const doc = [...attachments].filter((attachment, i) => i === index);

    if (autosave && !holdTillSaveMainEntity && doc.length) { // to save on change on update
      attachmentRemover(doc[0]);
    }

    setAttachments(arr);
  };

  const onSubmitAttachmentUpload = async () => {
    if (!entityCode || !entityId || disableUpload || holdTillSaveMainEntity) {
      return;
    }
    if (onUploadStart) {
      onUploadStart();
    }
    Promise.all(
      [...attachments]
        .filter((attachment) => attachment.originalFileName)
        .map((doc) => attachmentUploader(doc))
    )
      .then((resAry) => {
        onCancel()
        if (onUploadEnd) {
          onUploadEnd();
        }
      }) // resAry order is same as pmsAry order
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const attachmentUploader = async (attachment: Attachment) => {
    attachment.entityCode = entityCode;
    attachment.entityId = entityId;

    var formData = objectToFormData(attachment);
    const attachmentResponse = await apiCall(
      AttachmentApi.uploadFile(formData, abortController.signal)
    );

    if (!attachmentResponse.success)
      message.error(`File '${attachment.originalFileName}' upload failed.`);
  };

  const attachmentRemover = async (attachment: Attachment) => {
    const attachmentResponse = await apiCall(
      AttachmentApi.deleteAttachment(attachment.attachmentId!, abortController.signal)
    );

    if (!attachmentResponse.success)
      message.error(`File '${attachment.originalFileName}' remove failed.`);
  };

  return (
    <>
      <group>
        <Button icon="add" text="Attach File" primary onClick={onAddAttachment}></Button>
        {/* <Button
          disabled={disableUpload}
          onClick={onSubmitAttachmentUpload}>
          Upload
        </Button> */}
      </group>
      {loading && <Loader/>}
      <group data-direction="column" data-gap="10" data-column-template="4" data-type="column" data-width='auto'>
      {attachments.map((attachment, i) => (
        <group data-margin-vertical="5">
          <group data-gap="5" key={i.toString()}>
            <AttachmentItem
              item={attachment}
              allowedSize={52428800} // 50MB
              onChange={(e: Attachment) => onAttachmentUpload(e, i)}
              onError={(err) => message.error(err.message)}
            />
            <Button  micro icon="Delete"  onClick={() => onRemoveAttachment(i)}></Button>
          </group>
        </group>
      ))}
      </group>
      {!autosave && !!attachments.length &&
        <group>
          <Button
            disabled={disableUpload}
            onClick={onSubmitAttachmentUpload}>
            Upload
          </Button>
        </group>
      }
    </>
  );
}
